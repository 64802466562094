import React, { Component } from 'react';
import MainStructure from 'components/structure/MainStructure';
import Cover from 'components/ui/Cover';
import Product from 'components/Product';
import { getProductsByType } from 'api/product';
import { productTypes, contents } from 'lib/product';
import { getPageByType } from 'api/page';

class ProductContainer extends Component {
  state = {
    products: {
      monomers: {
        0: [],
      },
      special_monomers: {
        0: [],
      },
      photo_initilator: {
        0: [],
      },
      npe: {
        0: [],
      },
      ope: {
        0: [],
      },
      nfe: {
        0: [],
      },
      lae: {
        0: [],
      },
      emulsifying: {
        0: [],
      },
      defoaming: {
        0: [],
      },
      anti: {
        0: [],
      },
      polishing: {
        0: [],
      },
      plasticizer: {
        0: [],
      },
      coating_resins: {
        0: [],
      },
      epoxy_resins: {
        0: [],
      },
      uv_curing_resins: {
        0: [],
      },
    },
    counts: {
      monomers: {
        count: 0,
      },
      special_monomers: {
        count: 0,
      },
      photo_initilator: {
        count: 0,
      },
      npe: {
        count: 0,
      },
      ope: {
        count: 0,
      },
      nfe: {
        count: 0,
      },
      lae: {
        count: 0,
      },
      emulsifying: {
        count: 0,
      },
      defoaming: {
        count: 0,
      },
      anti: {
        count: 0,
      },
      polishing: {
        count: 0,
      },
      plasticizer: {
        count: 0,
      },
      coating_resins: {
        count: 0,
      },
      epoxy_resins: {
        count: 0,
      },
      uv_curing_resins: {
        count: 0,
      },
    },
    paginations: {
      monomers: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      special_monomers: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      photo_initilator: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      npe: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      ope: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      nfe: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      lae: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      emulsifying: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      defoaming: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      anti: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      polishing: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      plasticizer: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      coating_resins: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      epoxy_resins: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      uv_curing_resins: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
    },
    type: 'monomers',
    contentType: 'monomers',
    page: {
      title: '',
      en_title: '',
      image: '',
    },
  };

  componentDidMount() {
    this.handlePage();
    productTypes.map((type) => {
      this.handleProductList(type);
    });
  }

  handlePage = async () => {
    const result = await getPageByType('business_introduce');
    if (result && result.status === 200) {
      this.setState({
        ...this.state,
        page: result.data,
      });
    }
  };

  handleProductList = async (type) => {
    const result = await getProductsByType(type);
    if (result && result.status === 200) {
      let productList = {};
      let list = [];

      result.data.map((el, i) => {
        list.push(el);

        if (i !== 0 && (i + 1) % this.state.paginations[type].pagination.showData === 0) {
          let index = (i + 1) / this.state.paginations[type].pagination.showData;
          productList[index - 1] = list;
          list = [];
        }

        if (
          result.data.length < this.state.paginations[type].pagination.showData &&
          result.data.length - 1 === i
        ) {
          productList[0] = list;
          list = [];
        }

        if (
          result.data.length > this.state.paginations[type].pagination.showData &&
          result.data.length - 1 === i
        ) {
          productList[
            Math.ceil(result.data.length / this.state.paginations[type].pagination.showData) - 1
          ] = list;
          list = [];
        }
      });

      if (result.data.length === 0) {
        productList[0] = [];
      }

      this.setState({
        ...this.state,
        products: {
          ...this.state.products,
          [type]: productList,
        },
        counts: {
          ...this.state.counts,
          [type]: {
            count: result.data.length,
          },
        },
        paginations: {
          ...this.state.paginations,
          [type]: {
            ...this.state.paginations[type],
            pagination: {
              ...this.state.paginations[type].pagination,
              lastPage: Math.ceil(
                result.data.length / this.state.paginations[type].pagination.showData,
              ),
            },
          },
        },
      });
    }
  };

  handlePagination = (currentPage, contentType) => {
    const { type } = this.state;
    this.setState({
      ...this.state,
      paginations: {
        ...this.state.paginations,
        [contentType]: {
          ...this.state.paginations[contentType],
          pagination: {
            ...this.state.paginations[contentType].pagination,
            currentPage,
          },
        },
      },
    });
  };

  handleType = (type, contentType) => {
    this.setState({
      ...this.state,
      type: type,
      contentType: contentType ? contentType : type,
    });
  };

  render() {
    const { page } = this.state;

    return (
      <MainStructure>
        <Cover
          title={(page && page.title) || 'BUSINESS'}
          en_title={(page && page.en_title) || 'BUSINESS'}
          background={
            page.image ? `${process.env.REACT_APP_S3}${page.image}` : '/images/buiness-bg.png'
          }
        />
        <Product {...this.state} {...this} />
      </MainStructure>
    );
  }
}

export default ProductContainer;
