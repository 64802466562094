import storage from 'lib/storage';
import axios from 'axios';

const check = {
  token: async () => {
    axios.defaults.withCredentials = true;
    const token = storage.get('token');
    if (!token || token == null) return (window.location.href = '/admin');

    try {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      const result = await axios.get(`${process.env.REACT_APP_API}/check`, {
        headers: { Authorization: 'Bearer ' + token },
      });
      if (result.status === 200) {
        return result;
      } else {
        storage.remove('token');
        return (window.location.href = '/admin');
      }
    } catch (e) {
      storage.remove('token');
      return (window.location.href = '/admin');
    }
  },
};

export default check;
