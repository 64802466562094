import React, { Component } from 'react';
import MainStructure from 'components/structure/MainStructure';
import Cover from 'components/ui/Cover';
import Inquiry from 'components/Inquiry';
import { createInquiry } from 'api/inquiry';
import { withRouter } from 'react-router-dom';
import { getStaticByType } from 'api/static';
import { getPageByType } from 'api/page';
import { connect } from 'react-redux';
import { setLang } from 'data/actions';

class InquiryContainer extends Component {
  state = {
    name: '',
    phone_1: '',
    phone_2: '',
    phone_3: '',
    email_id: '',
    email_domain: '',
    company: '',
    position: '',
    content: '',
    agree: false,
    pc_content: '',
    mo_content: '',
    en_pc_content: '',
    en_mo_content: '',
    page: {
      title: '',
      en_title: '',
      image: '',
    },
  };

  componentDidMount() {
    this.handleStaic();
    this.handlePage();
  }

  handlePage = async () => {
    const result = await getPageByType('business_inquiry');
    if (result && result.status === 200) {
      this.setState({
        ...this.state,
        page: result.data,
      });
    }
  };

  handleStaic = async () => {
    const result = await getStaticByType('agree');
    if (result && result.status === 200) {
      if (result.data.length > 0) {
        this.setState({
          ...this.state,
          pc_content: result.data[0].pc_content,
          mo_content: result.data[0].mo_content,
          en_pc_content: result.data[0].en_pc_content,
          en_mo_content: result.data[0].en_mo_content,
        });
      }
    }
  };

  handleAgree = () => {
    this.setState({
      ...this.state,
      agree: !this.state.agree,
    });
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  handleInquiry = async () => {
    const {
      name,
      phone_1,
      phone_2,
      phone_3,
      email_id,
      email_domain,
      company,
      position,
      content,
      agree,
    } = this.state;

    const { lang } = this.props;

    const emailRule = /[a-zA-Z0-9_.-]+@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    const contactRule = /(\d{4}|\d{3}|\d{2})[-](\d{4}|\d{3})[-]\d{4}$/g;

    const phone = `${phone_1}-${phone_2}-${phone_3}`;
    const email = `${email_id}@${email_domain}`;

    if (!agree)
      return alert(
        lang === 'ko'
          ? '문의하기를 위해 개인정보취급 방침에 동의해주시면 감사하겠습니다:)'
          : 'If you agree to the privacy policy for inquiries, we would appreciate it:)',
      );
    if (!name.trim())
      return alert(lang === 'ko' ? '이름을 입력해주세요:)' : 'Please input your name:)');
    if (!phone_1.trim() || !phone_2.trim() || !phone_3.trim())
      return alert(
        lang === 'ko' ? '연락처를 입력해주세요:)' : 'Please input your contact information:)',
      );
    if (!contactRule.test(phone))
      return alert(
        lang === 'ko'
          ? '연락처 형식에 맞춰 작성 해주세요:)'
          : 'Please fill out according to the contact format :)',
      );
    if (!email_id.trim() || !email_domain.trim())
      return alert(lang === 'ko' ? '이메일을 입력해주세요:)' : 'Please input your email:)');
    if (!emailRule.test(email))
      return alert(
        lang === 'ko'
          ? '이메일 형식에 맞춰 작성 해주세요:)'
          : 'Please fill out according to the email format :)',
      );
    if (!content.trim())
      return alert(
        lang === 'ko' ? '문의내용을 입력해주세요:)' : 'Please input your inquiry details :)',
      );

    const result = await createInquiry({
      name,
      company,
      position,
      phone,
      email,
      content,
    });

    if (result && result.status === 200) {
      alert(
        lang === 'ko'
          ? '문의가 접수되었습니다. 빠른 시일내에 연락드리도록 하겠습니다:)'
          : 'Your inquiry has been received. We will get back to you as soon as possible :)',
      );
      this.setState({
        ...this.state,
        name: '',
        phone_1: '',
        phone_2: '',
        phone_3: '',
        email_id: '',
        email_domain: '',
        company: '',
        position: '',
        content: '',
        agree: false,
      });
      this.props.history.push('/business/product');
    }
  };

  render() {
    const { page } = this.state;

    return (
      <MainStructure>
        <Cover
          title={(page && page.title) || 'INQUIRY'}
          en_title={(page && page.en_title) || 'INQUIRY'}
          background={
            page.image ? `${process.env.REACT_APP_S3}${page.image}` : '/images/buiness-bg.png'
          }
        />
        <Inquiry {...this.state} {...this} />
      </MainStructure>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.config.lang,
  }),
  { setLang },
)(withRouter(InquiryContainer));
