import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import './Inquiry.css';
import { connect } from 'react-redux';
import { setLang } from 'data/actions';

class Inquiry extends Component {
  render() {
    const {
      name,
      phone_1,
      phone_2,
      phone_3,
      email_id,
      email_domain,
      company,
      position,
      content,
      agree,
      pc_content,
      mo_content,
      en_pc_content,
      en_mo_content,
      handleChange,
      handleAgree,
      handleInquiry,
      lang,
    } = this.props;

    return (
      <>
        <div className="nd-product">
          <div className="nd-product-container">
            <div className="nd-product-title-box">
              <Fade bottom>
                <h2 className="nd-product-title">
                  {lang === 'ko' ? '제품 및 개발 문의' : 'Product and development inquiries'}
                </h2>
              </Fade>
            </div>
            <div className="nd-consultant-box">
              <Fade bottom>
                <div className="nd-consultant-title-box">
                  <h2 className="nd-consultant-title">
                    {lang === 'ko' ? '상담자 정보' : 'Information'}
                  </h2>
                  <div className="nd-consultant-text-box">
                    <b className="nd-consultant-text-star">*</b>
                    <p className="nd-consultant-text">
                      {lang === 'ko'
                        ? '표시된 항목을 정확하게 기입해 주세요.'
                        : 'Please fill in the displayed items correctly.'}
                    </p>
                  </div>
                </div>
              </Fade>
            </div>
            <Fade bottom>
              <div className="nd-consultant-form">
                <div className="nd-consultant-form-container">
                  <div className="nd-consultant-form-box">
                    <div className="nd-consultant-form-text-box">
                      <b className="nd-consultant-text-star">*</b>
                      <p className="nd-consultant-form-text">{lang === 'ko' ? '이름' : 'Name'}</p>
                    </div>
                    <div className="nd-consultant-form-input-box">
                      <input
                        className="nd-consultant-form-input"
                        type="text"
                        name="name"
                        placeholder={lang === 'ko' ? '홍길동' : 'name'}
                        value={name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="nd-consultant-form-box">
                    <div className="nd-consultant-form-text-box">
                      <b className="nd-consultant-text-star">*</b>
                      <p className="nd-consultant-form-text">
                        {lang === 'ko' ? '연락처' : 'Phone'}
                      </p>
                    </div>
                    <div className="nd-consultant-form-input-box">
                      <input
                        className="nd-consultant-form-tel-input"
                        type="text"
                        placeholder="010"
                        name="phone_1"
                        value={phone_1}
                        onChange={handleChange}
                      />
                      <span className="nd-consultant-span">-</span>
                      <input
                        className="nd-consultant-form-tel-input"
                        type="text"
                        placeholder="0000"
                        name="phone_2"
                        value={phone_2}
                        onChange={handleChange}
                      />
                      <span className="nd-consultant-span">-</span>
                      <input
                        className="nd-consultant-form-tel-input"
                        type="text"
                        placeholder="0000"
                        name="phone_3"
                        value={phone_3}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="nd-consultant-form-box">
                    <div className="nd-consultant-form-text-box">
                      <b className="nd-consultant-text-star">*</b>
                      <p className="nd-consultant-form-text">
                        {lang === 'ko' ? '이메일' : 'E-mail'}
                      </p>
                    </div>
                    <div className="nd-consultant-form-input-box">
                      <input
                        className="nd-consultant-form-email-input"
                        type="text"
                        placeholder="KCMC"
                        name="email_id"
                        value={email_id}
                        onChange={handleChange}
                      />
                      <p className="nd-consultant-form-email">@</p>
                      <input
                        className="nd-consultant-form-email-input"
                        type="text"
                        placeholder="gmail.com"
                        name="email_domain"
                        value={email_domain}
                        onChange={handleChange}
                      />
                      <span className="nd-consultant-form-email"></span>
                      {/* <select className="nd-consultant-form-email-select" name="" id="">
                        <option value="">직접입력</option>
                        <option value="">naver.com</option>
                        <option value="">daum.net</option>
                      </select> */}
                    </div>
                  </div>
                  <div className="nd-consultant-form-box">
                    <div className="nd-consultant-form-company-box">
                      <p className="nd-consultant-form-text">
                        {lang === 'ko' ? '회사명' : 'Company'}
                      </p>
                    </div>
                    <div className="nd-consultant-form-input-box">
                      <input
                        className="nd-consultant-form-company-input"
                        type="text"
                        placeholder="KCMC"
                        name="company"
                        value={company}
                        onChange={handleChange}
                      />
                      <span className="nd-consultant-form-email"></span>
                      <input
                        className="nd-consultant-form-position-input"
                        type="text"
                        placeholder={lang === 'ko' ? '직책' : 'Position'}
                        name="position"
                        value={position}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="nd-consultant-form-box">
                    <div className="nd-consultant-form-textarea-text-box">
                      <b className="nd-consultant-text-star">*</b>
                      <p className="nd-consultant-form-text">
                        {lang === 'ko' ? '문의내용' : 'Content'}
                      </p>
                    </div>
                    <div className="nd-consultant-form-textarea-box">
                      <textarea
                        className="nd-consultant-form-textarea"
                        placeholder={lang === 'ko' ? '문의드립니다.' : 'Request'}
                        rows="20"
                        name="content"
                        value={content}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
            <div className="nd-consultant-box">
              <div className="nd-consultant-title-box">
                <Fade bottom>
                  <h2 className="nd-consultant-title">
                    {lang === 'ko'
                      ? '개인정보수집 및 이용동의'
                      : 'Personal Information Collection and Usage Agreement'}
                  </h2>
                </Fade>
              </div>
            </div>
            <Fade bottom>
              <div className="nd-user-textarea-box">
                <br />
                <div className="nd-user-textarea pc-only">
                  {lang === 'ko' ? pc_content : en_pc_content}
                </div>
                <div className="nd-user-textarea mo-only">
                  {lang === 'ko' ? mo_content : en_mo_content}
                </div>
                <br />
                <br />
              </div>
            </Fade>
            <Fade bottom>
              <div className="nd-user-agree-box">
                <input
                  className="nd-user-checkbox"
                  id="agree"
                  type="checkbox"
                  onClick={handleAgree}
                />
                <label className="nd-user-agree" htmlFor="agree">
                  {lang === 'ko'
                    ? '개인정보취급방침에 동의합니다.'
                    : 'I agree to the privacy policy.'}
                </label>
              </div>
            </Fade>
            <Fade bottom>
              <div className="nd-inquiry-button-box">
                <span className="nd-inquiry-button pointer" onClick={handleInquiry}>
                  {lang === 'ko' ? '문의하기' : 'Submit'}
                </span>
              </div>
            </Fade>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.config.lang,
  }),
  { setLang },
)(Inquiry);
