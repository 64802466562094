import React from 'react';
import AdminLayout from 'components/admin/layout/AdminLayout';
import ProductUpdateContainer from 'containers/admin/ProductUpdateContainer';

const ProductUpdate = () => {
  return (
    <AdminLayout>
      <ProductUpdateContainer />
    </AdminLayout>
  );
};

export default ProductUpdate;
