import React, { Component } from 'react';
import AuthContainer from 'containers/AuthContainer';
import InquiryList from 'components/admin/inquiries/InquiryList';
import { getInquiryList } from 'api/inquiry';

class InquiryListContainer extends Component {
  state = {
    inquiries: {
      0: [],
    },
    count: 0,
    pagination: {
      perPage: 5,
      showData: 10,
      currentPage: 1,
      lastPage: 0,
    },
  };

  componentDidMount() {
    this.handleInquiryList();
  }

  handleInquiryList = async () => {
    const result = await getInquiryList();
    if (result && result.status === 200) {
      let inquiryList = {};
      let list = [];
      result.data.map((el, i) => {
        list.push(el);

        if (i !== 0 && (i + 1) % this.state.pagination.showData === 0) {
          let index = (i + 1) / this.state.pagination.showData;
          inquiryList[index - 1] = list;
          list = [];
        }

        if (result.data.length < this.state.pagination.showData && result.data.length - 1 === i) {
          inquiryList[0] = list;
          list = [];
        }

        if (result.data.length > this.state.pagination.showData && result.data.length - 1 === i) {
          inquiryList[Math.ceil(result.data.length / this.state.pagination.showData) - 1] = list;
          list = [];
        }
      });

      if (result.data.length === 0) {
        inquiryList[0] = [];
      }

      this.setState({
        ...this.state,
        inquiries: inquiryList,
        count: result.data.length,
        pagination: {
          ...this.state.pagination,
          lastPage: Math.ceil(result.data.length / this.state.pagination.showData),
        },
      });
    }
  };

  handlePagination = (currentPage) => {
    this.setState({
      ...this.state,
      pagination: {
        ...this.state.pagination,
        currentPage,
      },
    });
  };

  render() {
    const { inquiries, pagination, count } = this.state;

    return (
      <InquiryList
        inquiries={inquiries[pagination.currentPage - 1]}
        pagination={pagination}
        count={count}
        {...this}
      />
    );
  }
}

export default AuthContainer()(InquiryListContainer);
