import React, { Component } from 'react';
import Login from 'components/admin/Login';
import { login } from 'api/auth';
import storage from 'lib/storage';
import check from 'lib/check';
import { withRouter } from 'react-router-dom';

class LoginContainer extends Component {
  state = {
    email: '',
    password: '',
  };

  componentWillMount() {
    const token = storage.get('token');
    storage.remove('token');
  }

  async componentDidMount() {
    const token = storage.get('token');
    storage.remove('token');
    document.getElementById('kakaoChat').style.display = 'none';
    // if (!token || token == null) return;

    // const result = await check.token();

    // if (result && result.status === 200) {
    //   this.props.history.push('/admin/managers');
    //   storage.set('token', token);
    // } else {
    //   storage.remove('token');
    // }
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  handleLogin = async () => {
    const { email, password } = this.state;
    storage.remove('token');
    const res = await login({
      email,
      password,
    });

    if (res && res.status === 200) {
      if (res && res.data && res.data.token) {
        await storage.set('token', res.data.token);
        setTimeout(() => {
          this.props.history.push('/admin/managers');
        }, 700);
      } else {
        alert('정보가 일치하지 않습니다!');
      }
    } else {
      storage.remove('token');
    }
  };

  render() {
    const { email, password } = this.state;
    const { handleChange, handleLogin } = this;

    return (
      <Login
        email={email}
        password={password}
        handleChange={handleChange}
        handleLogin={handleLogin}
      />
    );
  }
}

export default withRouter(LoginContainer);
