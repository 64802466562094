import React, { Component } from 'react';
import ProductContainer from 'containers/ProductContainer';

class Product extends Component {
  render() {
    return <ProductContainer />;
  }
}

export default Product;
