import axios from 'axios';
import storage from 'lib/storage';

const token = storage.get('token');

export const getManagerList = async () => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_API}/managers/`, {
      headers: { Authorization: 'Bearer ' + token },
    });
    return result;
  } catch (e) {
    // storage.remove('token');
    // window.location.href = '/admin';
  }
};

export const getManager = async (id) => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_API}/managers/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    });

    return result;
  } catch (e) {}
};

export const createManager = async (manager) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/managers/`,
      {
        ...manager,
      },
      {
        headers: { Authorization: 'Bearer ' + token },
      },
    );

    return result;
  } catch (e) {}
};

export const updateManager = async (id, manager) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/managers/${id}`,
      {
        ...manager,
      },
      {
        headers: { Authorization: 'Bearer ' + token },
      },
    );

    return result;
  } catch (e) {}
};

export const resetManager = async (id, password) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/managers/password/${id}`,
      {
        password,
      },
      {
        headers: { Authorization: 'Bearer ' + token },
      },
    );

    return result;
  } catch (e) {}
};

export const deleteManager = async (id) => {
  try {
    const result = await axios.delete(`${process.env.REACT_APP_API}/managers/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    });

    return result;
  } catch (e) {}
};
