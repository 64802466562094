import React, { Component } from 'react';
import './Footer.css';
import { withRouter } from 'react-router-dom';
import { getContactList } from 'api/contact';
import { connect } from 'react-redux';
import { setLang } from 'data/actions';

class Footer extends Component {
  state = {
    contact: {
      company: 'KCMC',
      address: '서울 영등포구 국제금융로8길 27-9 동북빌딩 601호(여의도동, 동북빌딩)',
      en_company: 'KCMC',
      en_address:
        '601, Dongbuk Building, 27-9, Gukjegeumyung-ro 8-gil, Yeongdeungpo-gu, Seoul, Republic of Korea',
      contact_number: '82-2-784-0794~5',
      email: 'KCKIMMC@KCMONOMER.COM',
      map:
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.503919366966!2d126.92676501575197!3d37.51961687980665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f1589fd3069%3A0x418450b2feac4a9d!2z64-Z67aB67mM65SpIOq0gOumrOyCrOustOyGjA!5e0!3m2!1sko!2skr!4v1613655414918!5m2!1sko!2skr',
    },
  };

  componentDidMount() {
    this.handleContact();
  }

  handleContact = async () => {
    const result = await getContactList();
    if (result && result === 200) {
      if (result.data && result.data[0]) {
        this.setState({
          ...this.state,
          contact: {
            ...this.state.contact,
            company: result.data[0].company,
            address: result.data[0].address,
            en_company: result.data[0].en_company,
            en_address: result.data[0].en_address,
            contact_number: result.data[0].contact_number,
            email: result.data[0].email,
            map: result.data[0].map,
          },
        });
      }
    }
  };

  handlePage = (e, page) => {
    this.props.history.push(page);
  };

  render() {
    const { contact } = this.state;
    const { handlePage } = this;
    const { lang } = this.props;

    return (
      <footer className="nd-footer">
        <div className="nd-footer-container">
          <div className="nd-footer-top-box">
            <div className="nd-footer-top-title-box">
              <p className="nd-footer-top-title pointer" onClick={(e) => handlePage(e, '/privacy')}>
                {lang === 'ko' ? '개인정보처리방침' : 'PRIVACY'}
              </p>
            </div>
            <div className="nd-footer-top-text-box">
              <p className="nd-footer-top-text pointer" onClick={(e) => handlePage(e, '/email')}>
                {lang === 'ko' ? '이메일무단수집거부' : 'EMAIL'}
              </p>
            </div>
            <div className="nd-footer-top-text-box">
              <p className="nd-footer-top-text pointer" onClick={(e) => handlePage(e, '/about')}>
                {lang === 'ko' ? '회사소개' : 'ABOUT'}
              </p>
            </div>
          </div>
          <div className="footer-bottom-box">
            <div>
              <p className="nd-footer-bottom-text">
                {lang === 'ko' ? contact.address : contact.en_address}
              </p>
            </div>
            <div className="nd-mobile-footer-text-box">
              <p className="nd-footer-bottom-text">{contact.contact_number}</p>
            </div>
            <div>
              <p className="nd-footer-bottom-text">COPYRIGHT Ⓒ ALL RESERVED KCMC</p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.config.lang,
  }),
  { setLang },
)(withRouter(Footer));
