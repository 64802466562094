import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from 'data/configureStore';
import { Provider } from 'react-redux';
import Root from './Root';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'styles/admin/common.css';
import 'styles/admin/all-skins.min.css';
import 'styles/admin/AdminLTE.min.css';
import 'styles/admin/bootstrap.min.css';
import 'styles/admin/font-awesome.min.css';
import 'styles/fonts.css';
import 'styles/reset.css';
import 'styles/common.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import * as serviceWorker from './serviceWorker';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
