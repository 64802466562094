import axios from 'axios';
import storage from 'lib/storage';

const token = storage.get('token');

export const getProductList = async () => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_API}/products/`, {});

    return result;
  } catch (e) {}
};

export const getProductsByType = async (type) => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_API}/products/type/${type}`, {});

    return result;
  } catch (e) {}
};

export const getProductActive = async () => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_API}/products/active`, {});

    return result;
  } catch (e) {}
};

export const getProduct = async (id) => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_API}/products/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    });

    return result;
  } catch (e) {}
};

export const createProduct = async (product) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/products/`,
      {
        ...product,
      },
      {
        headers: { Authorization: 'Bearer ' + token },
      },
    );

    return result;
  } catch (e) {}
};

export const updateProduct = async (id, product) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/products/${id}`,
      {
        ...product,
      },
      {
        headers: { Authorization: 'Bearer ' + token },
      },
    );

    return result;
  } catch (e) {}
};

export const deleteProduct = async (id) => {
  try {
    const result = await axios.delete(`${process.env.REACT_APP_API}/products/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    });

    return result;
  } catch (e) {}
};
