import React, { Component } from 'react';
import AuthContainer from 'containers/AuthContainer';
import InfoUpdate from 'components/admin/infos/InfoUpdate';
import { getContactList, createContact, updateContact } from 'api/contact';
import { getStaticList, createStatic, updateStatic } from 'api/static';
import { uploadInfoImage } from 'api/upload';

class InfoUpdateContainer extends Component {
  state = {
    contact: {
      id: 0,
      company: '',
      address: '',
      en_company: '',
      en_address: '',
      contact_number: '',
      email: '',
      map: '',
    },
    agree: {
      id: 0,
      pc_content: '',
      mo_content: '',
      en_pc_content: '',
      en_mo_content: '',
    },
    privacy: {
      id: 0,
      pc_content: '',
      mo_content: '',
      en_pc_content: '',
      en_mo_content: '',
    },
    email: {
      id: 0,
      pc_content: '',
      mo_content: '',
      en_pc_content: '',
      en_mo_content: '',
    },
    type: 'contact',
  };

  componentDidMount() {
    this.handleContactList();
    this.handleStaticList();
    // window.addEventListener('message', this.handleIframeTask);
  }

  componentWillUnmount() {
    // window.removeEventListener('message', this.handleIframeTask);
  }

  handleIframeTask = async (e) => {
    const { type } = this.state;
    var editor = document.getElementById('info-editor');

    if (e.data === 'contentUpload') {
      var editorIframe = editor.contentDocument || editor.contentWindow.document;
      var contentFile = editorIframe.querySelector(
        '.form-group.note-form-group.note-group-select-from-files input',
      );

      try {
        const result = await uploadInfoImage(contentFile.files[0]);

        editor.contentWindow.postMessage('url: ' + process.env.REACT_APP_S3 + result.data.key, '*');
      } catch (e) {
        editor.contentWindow.postMessage('fail', '*');
        alert('업로드 실패');
      }
      // note-dialog-image-file-16039374768321
      // this.setState({
      //   activeStep: 3,
      // });
      // document.getElementById('iframe').contentWindow.postMessage(item, '*');
    }

    if (e.data === 'initFinished') {
      editor.contentWindow.postMessage('edit!13@&42#' + this.state[type].content, '*');
    }

    if (e.data === 'change') {
      // note-editable
      var editorIframe = editor.contentDocument || editor.contentWindow.document;
      var contentHtml = editorIframe.querySelector('.note-editable');
      this.setState({
        ...this.state,
        [type]: {
          ...this.state[type],
          content: contentHtml.innerHTML,
        },
      });
    }
  };

  handleCreateContact = async () => {
    const {
      company,
      address,
      en_company,
      en_address,
      contact_number,
      email,
      map,
    } = this.state.contact;

    const result = await createContact({
      company,
      address,
      en_company,
      en_address,
      contact_number,
      email,
      map,
    });
    if (result && result.status === 200) {
      alert('등록 성공:)');
      this.handleContactList();
    }
  };

  handleUpdateContact = async () => {
    const {
      id,
      company,
      address,
      en_company,
      en_address,
      contact_number,
      email,
      map,
    } = this.state.contact;

    const result = await updateContact(id, {
      company,
      address,
      en_company,
      en_address,
      contact_number,
      email,
      map,
    });
    if (result && result.status === 200) {
      alert('수정 성공:)');
      this.handleContactList();
    }
  };

  handleCreateStatic = async () => {
    const { type } = this.state;

    const result = await createStatic({
      type,
      pc_content: this.state[type].pc_content,
      mo_content: this.state[type].mo_content,
      en_pc_content: this.state[type].en_pc_content,
      en_mo_content: this.state[type].en_mo_content,
    });
    if (result && result.status === 200) {
      alert('등록 성공:)');
      this.handleStaticList();
    }
  };

  handleUpdateStatic = async () => {
    const { type } = this.state;

    const result = await updateStatic(this.state[type].id, {
      type,
      pc_content: this.state[type].pc_content,
      mo_content: this.state[type].mo_content,
      en_pc_content: this.state[type].en_pc_content,
      en_mo_content: this.state[type].en_mo_content,
    });

    if (result && result.status === 200) {
      alert('수정 성공:)');
      this.handleStaticList();
    }
  };

  handleContactList = async () => {
    const result = await getContactList();
    if (result && result.status === 200) {
      if (result.data.length > 0) {
        this.setState({
          ...this.state,
          contact: {
            ...this.state.contact,
            id: result.data[0].id || 0,
            company: result.data[0].company || '',
            address: result.data[0].address || '',
            en_company: result.data[0].en_company || '',
            en_address: result.data[0].en_address || '',
            contact_number: result.data[0].contact_number || '',
            email: result.data[0].email || '',
            map: result.data[0].map || '',
          },
        });
      }
    }
  };

  handleStaticList = async () => {
    const result = await getStaticList();
    if (result && result.status === 200) {
      let tempEmail = {
        id: 0,
        pc_content: '',
        mo_content: '',
        en_pc_content: '',
        en_mo_content: '',
      };
      let tempPrivacy = {
        id: 0,
        pc_content: '',
        mo_content: '',
        en_pc_content: '',
        en_mo_content: '',
      };
      let tempAgree = {
        id: 0,
        pc_content: '',
        mo_content: '',
        en_pc_content: '',
        en_mo_content: '',
      };

      result.data.map((item, index) => {
        if (item.type === 'email') {
          tempEmail.id = item.id;
          tempEmail.pc_content = item.pc_content;
          tempEmail.mo_content = item.mo_content;
          tempEmail.en_pc_content = item.en_pc_content;
          tempEmail.en_mo_content = item.en_mo_content;
        }

        if (item.type === 'privacy') {
          tempPrivacy.id = item.id;
          tempPrivacy.pc_content = item.pc_content;
          tempPrivacy.mo_content = item.mo_content;
          tempPrivacy.en_pc_content = item.en_pc_content;
          tempPrivacy.en_mo_content = item.en_mo_content;
        }

        if (item.type === 'agree') {
          tempAgree.id = item.id;
          tempAgree.pc_content = item.pc_content;
          tempAgree.mo_content = item.mo_content;
          tempAgree.en_pc_content = item.en_pc_content;
          tempAgree.en_mo_content = item.en_mo_content;
        }
      });

      this.setState({
        ...this.state,
        email: tempEmail,
        privacy: tempPrivacy,
        agree: tempAgree,
      });
    }
  };

  handleChange = (e) => {
    const { type } = this.state;

    this.setState({
      ...this.state,
      [type]: {
        ...this.state[type],
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSave = () => {
    const { type } = this.state;

    if (this.state[type].id !== 0) {
      if (type === 'contact') {
        this.handleUpdateContact();
      } else {
        this.handleUpdateStatic();
      }
    } else {
      if (type === 'contact') {
        this.handleCreateContact();
      } else {
        this.handleCreateStatic();
      }
    }
  };

  handleType = (type) => {
    this.setState(
      {
        ...this.state,
        type,
      },
      () => {
        if (type !== 'contact') {
          // var editor = document.getElementById('info-editor');
          // editor.contentWindow.postMessage('edit!13@&42#' + this.state[type].content, '*');
        }
      },
    );
  };

  render() {
    return <InfoUpdate {...this.state} {...this} />;
  }
}

export default AuthContainer()(InfoUpdateContainer);
