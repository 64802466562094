import axios from 'axios';
import storage from 'lib/storage';

const token = storage.get('token');

export const uploadPopupImage = async (file) => {
  let formData = new FormData();
  // let imagefile = document.querySelector('#file');
  formData.append('popup', file);
  try {
    const result = await axios.post(`${process.env.REACT_APP_API}/upload/popup`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    return result;
  } catch (e) {}
};

export const uploadPageImage = async (file) => {
  let formData = new FormData();
  // let imagefile = document.querySelector('#file');
  formData.append('page', file);
  try {
    const result = await axios.post(`${process.env.REACT_APP_API}/upload/page`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    return result;
  } catch (e) {}
};

export const uploadSlideImage = async (file) => {
  let formData = new FormData();
  // let imagefile = document.querySelector('#file');
  formData.append('slide', file);
  try {
    const result = await axios.post(`${process.env.REACT_APP_API}/upload/slide`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    return result;
  } catch (e) {}
};

export const uploadInfoImage = async (file) => {
  let formData = new FormData();
  // let imagefile = document.querySelector('#file');
  formData.append('info', file);
  try {
    const result = await axios.post(`${process.env.REACT_APP_API}/upload/info`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    return result;
  } catch (e) {}
};
