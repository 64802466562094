import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './PopupUpdate.css';

class PopupUpdate extends Component {
  render() {
    const {
      description,
      pc_image,
      mo_image,
      en_pc_image,
      en_mo_image,
      is_active,
      isLoading,
      handleChange,
      handlePopupImage,
      resetImage,
      handleSave,
      id,
    } = this.props;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>메인 페이지 팝업 관리</h1>
        </section>
        <section className="content">
          <div className="box box-primary">
            <div className="box-body">
              <div
                className="form-group col-lg-12 col-md-12"
                style={{
                  border: '1px solid #808080',
                  marginTop: '20px',
                  padding: '20px',
                  borderRadius: '4px',
                }}
              >
                <table className="table table- light">
                  <thead>
                    <tr>
                      <th colSpan="4">팝업 정보</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th style={{ width: '20%' }}>
                        Ko PC 이미지
                        <br />
                        (430 × 640)
                      </th>
                      <td>
                        {!isLoading.pc_image && !!pc_image && (
                          <div className="flex" style={{ flexDirection: 'column' }}>
                            <>
                              <button
                                type="button"
                                className="btn btn-warning"
                                onClick={(e) => resetImage('pc_image')}
                              >
                                제거
                              </button>
                              <br />
                              <img
                                src={process.env.REACT_APP_S3 + pc_image}
                                style={{ maxWidth: '20%', marginTop: '20px' }}
                                alt={description}
                              />
                            </>
                          </div>
                        )}
                        {isLoading.pc_image && <div className="loader"></div>}
                        {!isLoading.pc_image && !pc_image && (
                          <label className="btn btn-primary btn-file">
                            파일 선택{' '}
                            <input
                              type="file"
                              style={{ display: 'none' }}
                              name="pc_image"
                              onChange={(e) => handlePopupImage(e, 'pc_image')}
                            />
                          </label>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>
                        Ko MOBILE 이미지
                        <br />
                        (320 × 540)
                      </th>
                      <td>
                        {!isLoading.mo_image && !!mo_image && (
                          <div className="flex" style={{ flexDirection: 'column' }}>
                            <>
                              <button
                                type="button"
                                className="btn btn-warning"
                                onClick={(e) => resetImage('mo_image')}
                              >
                                제거
                              </button>
                              <br />
                              <img
                                src={process.env.REACT_APP_S3 + mo_image}
                                style={{ maxWidth: '20%', marginTop: '20px' }}
                                alt={description}
                              />
                            </>
                          </div>
                        )}
                        {isLoading.mo_image && <div className="loader"></div>}
                        {!isLoading.mo_image && !mo_image && (
                          <label className="btn btn-primary btn-file">
                            파일 선택{' '}
                            <input
                              type="file"
                              style={{ display: 'none' }}
                              name="mo_image"
                              onChange={(e) => handlePopupImage(e, 'mo_image')}
                            />
                          </label>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>
                        English PC 이미지
                        <br />
                        (430 × 640)
                      </th>
                      <td>
                        {!isLoading.en_pc_image && !!en_pc_image && (
                          <div className="flex" style={{ flexDirection: 'column' }}>
                            <>
                              <button
                                type="button"
                                className="btn btn-warning"
                                onClick={(e) => resetImage('en_pc_image')}
                              >
                                제거
                              </button>
                              <br />
                              <img
                                src={process.env.REACT_APP_S3 + en_pc_image}
                                style={{ maxWidth: '20%', marginTop: '20px' }}
                                alt={description}
                              />
                            </>
                          </div>
                        )}
                        {isLoading.en_pc_image && <div className="loader"></div>}
                        {!isLoading.en_pc_image && !en_pc_image && (
                          <label className="btn btn-primary btn-file">
                            파일 선택{' '}
                            <input
                              type="file"
                              style={{ display: 'none' }}
                              name="en_pc_image"
                              onChange={(e) => handlePopupImage(e, 'en_pc_image')}
                            />
                          </label>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>
                        English MOBILE 이미지
                        <br />
                        (320 × 540)
                      </th>
                      <td>
                        {!isLoading.en_mo_image && !!en_mo_image && (
                          <div className="flex" style={{ flexDirection: 'column' }}>
                            <>
                              <button
                                type="button"
                                className="btn btn-warning"
                                onClick={(e) => resetImage('en_mo_image')}
                              >
                                제거
                              </button>
                              <br />
                              <img
                                src={process.env.REACT_APP_S3 + en_mo_image}
                                style={{ maxWidth: '20%', marginTop: '20px' }}
                                alt={description}
                              />
                            </>
                          </div>
                        )}
                        {isLoading.en_mo_image && <div className="loader"></div>}
                        {!isLoading.en_mo_image && !en_mo_image && (
                          <label className="btn btn-primary btn-file">
                            파일 선택{' '}
                            <input
                              type="file"
                              style={{ display: 'none' }}
                              name="en_mo_image"
                              onChange={(e) => handlePopupImage(e, 'en_mo_image')}
                            />
                          </label>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>대체텍스트</th>
                      <td colSpan="3">
                        <input
                          type="text"
                          className="form-control"
                          name="description"
                          value={description}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>활성화 여부 *</th>
                      <td colSpan="3">
                        <select
                          className="example-radio-group form-control"
                          name="is_active"
                          onChange={handleChange}
                          value={is_active}
                        >
                          <option value={1}>활성화</option>
                          <option value={0}>비활성화</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="box-footer">
              <div className="pull-right">
                <button
                  className="btn btn-default"
                  style={{ marginRight: '5px' }}
                  onClick={() => this.props.history.push('/admin/popups')}
                >
                  취소
                </button>
                <button className="btn btn-primary" onClick={handleSave}>
                  {id === 0 ? '등록' : '수정'}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(PopupUpdate);
