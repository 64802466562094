import React from 'react';
import AdminLayout from 'components/admin/layout/AdminLayout';
import PageUpdateContainer from 'containers/admin/PageUpdateContainer';

const PageUpdate = () => {
  return (
    <AdminLayout>
      <PageUpdateContainer />
    </AdminLayout>
  );
};

export default PageUpdate;
