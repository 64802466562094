import React, { Component } from 'react';
import Slider from 'react-slick';
import SliderItem from 'components/ui/SliderItem';
import './SliderList.css';

class SliderList extends Component {
  handleNext = () => {
    this.slider.slickNext();
  };

  handlePrevious = () => {
    this.slider.slickPrev();
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      // autoplaySpeed: 1500,
      arrows: false,
    };

    const { handleNext, handlePrevious } = this;
    const { slides } = this.props;

    return (
      <>
        <Slider {...settings} ref={(c) => (this.slider = c)}>
          {slides &&
            slides.map((el, i) => (
              <SliderItem
                key={el.id}
                data={el}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
              />
            ))}
        </Slider>
      </>
    );
  }
}

export default SliderList;
