import React, { Component } from 'react';
import './Map.css';

class Map extends Component {
  render() {
    const {
      map = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.503919366966!2d126.92676501575197!3d37.51961687980665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f1589fd3069%3A0x418450b2feac4a9d!2z64-Z67aB67mM65SpIOq0gOumrOyCrOustOyGjA!5e0!3m2!1sko!2skr!4v1613655414918!5m2!1sko!2skr',
    } = this.props;

    return (
      <div className="nd-main-map">
        <iframe
          src={map}
          // width="600"
          // height="450"
          frameBorder="0"
          style={{ border: 0, marginTop: '-150px' }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
    );
  }
}

export default Map;
