import React, { Component } from 'react';
import './Product.css';
import Fade from 'react-reveal/Fade';
import { withRouter } from 'react-router-dom';
import Pagination from 'components/ui/Pagination';
import { connect } from 'react-redux';
import { setLang } from 'data/actions';

class Product extends Component {
  state = {
    menu1: {
      chemical: true,
      resin: false,
    },
    menu2: {
      chemical: {
        0: true,
        1: false,
        2: false,
        3: false,
        4: false,
      },
      resin: {
        0: false,
        1: false,
        2: false,
      },
    },
    menu3: {
      0: true,
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
    },
  };

  handleMenu1 = (e, menu) => {
    let menu1 = {
      chemical: false,
      resin: false,
    };

    let menu2 = {
      chemical: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
      resin: {
        0: false,
        1: false,
        2: false,
      },
    };

    menu1[menu] = true;
    menu2[menu][0] = true;

    this.setState({
      ...this.state,
      menu1,
      menu2,
    });
  };

  handleMenu2 = (e, menu) => {
    let menu2 = {
      chemical: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
      resin: {
        0: false,
        1: false,
        2: false,
      },
    };

    menu2[this.state.menu1.chemical ? 'chemical' : 'resin'][menu] = true;

    this.setState({
      ...this.state,
      menu2,
    });
  };

  handleMenu3 = (e, menu) => {
    const { menu3 } = this.state;

    let menuTemp = {
      ...this.state.menu3,
    };

    menuTemp[menu] = !this.state.menu3[menu];

    this.setState({
      ...this.state,
      menu3: menuTemp,
    });
  };

  render() {
    const { menu1, menu2, menu3 } = this.state;
    const { handleMenu1, handleMenu2, handleMenu3 } = this;
    const {
      products,
      counts,
      type,
      contentType,
      paginations,
      handleType,
      handlePagination,
      className,
      lang,
    } = this.props;

    return (
      <>
        <div className={'nd-introduce ' + className}>
          <div className="nd-introduce-container">
            <div className="nd-introduce-title-box">
              <Fade bottom>
                <h2 className="nd-introduce-title">{lang === 'ko' ? '제품소개' : 'PRODUCT'}</h2>
              </Fade>
            </div>
            <Fade bottom>
              <div className="nd-introduce-product-box">
                <div
                  data-tab-title="#nd-chemical"
                  className={
                    'nd-introduce-product-title-box pointer ' + (menu1.chemical ? 'active' : '')
                  }
                  onClick={(e) => {
                    handleMenu1(e, 'chemical');
                    handleType('monomers');
                  }}
                >
                  <h2 className="nd-introduce-product-title">Chemical raw material</h2>
                </div>
                <div
                  data-tab-title="#nd-resin"
                  className={
                    'nd-introduce-product-title-box pointer ' + (menu1.resin ? 'active' : '')
                  }
                  onClick={(e) => {
                    handleMenu1(e, 'resin');
                    handleType('coating_resins');
                  }}
                >
                  <h2 className="nd-introduce-product-title">Resin business</h2>
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <nav
                id="nd-chemical"
                className={
                  'nd-introuce-product-menus ' +
                  (Object.keys(menu2.chemical).some((el) => menu2.chemical[el]) ? 'active' : '')
                }
              >
                <span
                  data-tab-target="#nd-monomers"
                  className={
                    'nd-introuce-product-menu pointer ' + (menu2.chemical[0] ? 'active' : '')
                  }
                  onClick={(e) => {
                    handleMenu2(e, 0);
                    handleType('monomers');
                  }}
                >
                  {'MONOMERS & OTHER'}
                </span>
                <span
                  data-tab-target="#nd-special"
                  className={
                    'nd-introuce-product-menu pointer ' + (menu2.chemical[1] ? 'active' : '')
                  }
                  onClick={(e) => {
                    handleMenu2(e, 1);
                    handleType('special_monomers');
                  }}
                >
                  SPECIAL MONOMERS
                </span>
                <span
                  data-tab-target="#nd-photo"
                  className={
                    'nd-introuce-product-menu pointer ' + (menu2.chemical[2] ? 'active' : '')
                  }
                  onClick={(e) => {
                    handleMenu2(e, 2);
                    handleType('photo_initilator');
                  }}
                >
                  PHOTO INITIATOR
                </span>
                <span
                  data-tab-target="#nd-surfactants"
                  className={
                    'nd-introuce-product-menu pointer ' + (menu2.chemical[3] ? 'active' : '')
                  }
                  onClick={(e) => {
                    handleMenu2(e, 3);
                    handleType('surfactants');
                  }}
                >
                  SURFACTANTS
                </span>
                <span
                  data-tab-target="#nd-plasticizer"
                  className={
                    'nd-introuce-product-menu pointer ' + (menu2.chemical[4] ? 'active' : '')
                  }
                  onClick={(e) => {
                    handleMenu2(e, 4);
                    handleType('plasticizer');
                  }}
                >
                  PLASTICIZER
                </span>
              </nav>
              <nav
                data-tab-content
                id="nd-resin"
                className={
                  'nd-introuce-product-menus ' +
                  (Object.keys(menu2.resin).some((el) => menu2.resin[el]) ? 'active' : '')
                }
              >
                <span
                  data-tab-target="#nd-coating"
                  className={'nd-introuce-product-menu pointer ' + (menu2.resin[0] ? 'active' : '')}
                  onClick={(e) => {
                    handleMenu2(e, 0);
                    handleType('coating_resins');
                  }}
                >
                  COATING RESINS
                </span>
                <span
                  data-tab-target="#nd-epoxy"
                  className={'nd-introuce-product-menu pointer ' + (menu2.resin[1] ? 'active' : '')}
                  onClick={(e) => {
                    handleMenu2(e, 1);
                    handleType('epoxy_resins');
                  }}
                >
                  EPOXY RESINS
                </span>
                <span
                  data-tab-target="#nd-curing"
                  className={'nd-introuce-product-menu pointer ' + (menu2.resin[2] ? 'active' : '')}
                  onClick={(e) => {
                    handleMenu2(e, 2);
                    handleType('uv_curing_resins');
                  }}
                >
                  UV CURING RESIN
                </span>
              </nav>
              <div className="nd-introduce-product-view-box">
                <div className="nd-introduce-product-view-number-box">
                  {type !== 'uv_curing_resins' && (
                    <h2 className="nd-introduce-product-view-number">
                      {lang === 'ko' ? '전체 ' : 'All '}
                      <b className="nd-introduce-product-view-number-red">
                        {type === 'surfactants'
                          ? counts['npe'].count +
                            counts['ope'].count +
                            counts['nfe'].count +
                            counts['lae'].count +
                            counts['emulsifying'].count +
                            counts['defoaming'].count +
                            counts['anti'].count +
                            counts['polishing'].count
                          : counts[type].count}
                      </b>
                      {lang === 'ko' ? '건' : ''}
                    </h2>
                  )}
                </div>
                <div className="nd-introduce-product-view-menus">
                  {/* <div className="nd-introduce-product-view-menu-box">
                  <h2 className="nd-introduce-product-view-menu">제품 전체보기</h2>
                </div> */}
                  <div
                    className={'nd-introduce-product-view-menu-box pointer '}
                    onClick={(e) => this.props.history.push('/business/inquiry')}
                  >
                    <h2 className="nd-introduce-product-view-menu">
                      {lang === 'ko' ? '제품문의' : 'INQUIRY'}
                    </h2>
                  </div>
                </div>
              </div>
            </Fade>
            <main className="nd-section">
              {/* <!-- MONOMERS & OTHERS --> */}
              <Fade bottom>
                <div
                  data-tab-content
                  id="nd-monomers"
                  className={'nd-introduce-product-item ' + (menu2.chemical[0] ? 'active' : '')}
                >
                  <div className="nd-introduce-product-item-box">
                    <table className="nd-introduce-product-item-list-box">
                      <thead className="nd-introduce-product-item-menu">
                        <tr className="nd-introduce-product-item-menu-box">
                          <th className="nd-introduce-product-item-menu-title">No</th>
                          <th className="nd-introduce-product-item-menu-title">ITEM</th>
                          <th className="nd-introduce-product-item-menu-title">CHEMICAL NAME</th>
                          <th className="nd-introduce-product-item-menu-title">CAS NO</th>
                          <th className="nd-introduce-product-item-menu-title">PACKAGE</th>
                          <th className="nd-introduce-product-item-menu-title">ORIGIN</th>
                          <th className="nd-introduce-product-item-menu-title">SOURCE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.monomers &&
                          products.monomers[paginations.monomers.pagination.currentPage - 1] &&
                          products.monomers[paginations.monomers.pagination.currentPage - 1].map(
                            (el) => {
                              return (
                                <tr key={el.id} className="nd-introduce-product-item-menu-box">
                                  <td
                                    className="nd-introduce-product-item-menu-text"
                                    style={{ width: '50px' }}
                                  >
                                    {el.column1}
                                  </td>
                                  <td
                                    className="nd-introduce-product-item-menu-text"
                                    style={{ width: '100px' }}
                                  >
                                    {el.column2}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column3}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column4}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column5}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column6}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column7}
                                  </td>
                                </tr>
                              );
                            },
                          )}
                        {products.monomers && products.monomers[0].length === 0 && (
                          <tr key={'no'}>
                            <td
                              colSpan="20"
                              className="nd-introduce-product-item-menu-text text-center no-data"
                            >
                              {lang === 'ko'
                                ? '등록된 제품이 없습니다.'
                                : 'There is no registered product.'}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {counts.monomers.count !== 0 && (
                      <Pagination
                        className="introduce-product"
                        pagination={paginations.monomers.pagination}
                        handlePagination={handlePagination}
                        type="monomers"
                      />
                    )}
                  </div>
                </div>
              </Fade>

              {/* <!-- SPECIAL MONOMERS --> */}
              <Fade bottom>
                <div
                  data-tab-content
                  id="nd-special"
                  className={'nd-introduce-product-item ' + (menu2.chemical[1] ? 'active' : '')}
                >
                  <div className="nd-introduce-product-item-box">
                    <table className="nd-introduce-product-item-list-box">
                      <thead className="nd-introduce-product-item-menu">
                        <tr className="nd-introduce-product-item-menu-box">
                          <th className="nd-introduce-product-item-menu-title">No</th>
                          <th className="nd-introduce-product-item-menu-title">ITEM</th>
                          <th className="nd-introduce-product-item-menu-title">CHEMICAL NAME</th>
                          <th className="nd-introduce-product-item-menu-title">CAS NO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.special_monomers &&
                          products.special_monomers[
                            paginations.special_monomers.pagination.currentPage - 1
                          ] &&
                          products.special_monomers[
                            paginations.special_monomers.pagination.currentPage - 1
                          ].map((el) => {
                            return (
                              <tr key={el.id} className="nd-introduce-product-item-menu-box">
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column1}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column2}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column3}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column4}
                                </td>
                              </tr>
                            );
                          })}
                        {products.special_monomers && products.special_monomers[0].length === 0 && (
                          <tr key={'no'}>
                            <td
                              colSpan="20"
                              className="nd-introduce-product-item-menu-text text-center no-data"
                            >
                              {lang === 'ko'
                                ? '등록된 제품이 없습니다.'
                                : 'There is no registered product.'}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {counts.special_monomers.count !== 0 && (
                      <Pagination
                        className="introduce-product"
                        pagination={paginations.special_monomers.pagination}
                        handlePagination={handlePagination}
                        type="special_monomers"
                      />
                    )}
                  </div>
                </div>
              </Fade>

              {/* <!-- PHOTO INITIATOR --> */}
              <Fade bottom>
                <div
                  data-tab-content
                  id="nd-photo"
                  className={'nd-introduce-product-item ' + (menu2.chemical[2] ? 'active' : '')}
                >
                  <div className="nd-introduce-product-item-box">
                    <table className="nd-introduce-product-item-list-box">
                      <thead className="nd-introduce-product-item-menu">
                        <tr className="nd-introduce-product-item-menu-box">
                          <th className="nd-introduce-product-item-menu-title nd-introduce-photo-item-menu-title">
                            No
                          </th>
                          <th className="nd-introduce-product-item-menu-title nd-introduce-photo-item-menu-title">
                            JRcure NO.
                          </th>
                          <th className="nd-introduce-product-item-menu-title nd-introduce-photo-item-menu-title">
                            General NO.
                          </th>
                          <th className="nd-introduce-product-item-menu-title nd-introduce-photo-item-menu-title">
                            Chemical Name
                          </th>
                          <th className="nd-introduce-product-item-menu-title nd-introduce-photo-item-menu-title">
                            CAS NO
                          </th>
                          <th className="nd-introduce-product-item-menu-title nd-introduce-photo-item-menu-title">
                            Appearance
                          </th>
                          <th className="nd-introduce-product-item-menu-title nd-introduce-photo-item-menu-title">
                            Melting Point [℃]
                          </th>
                          <th className="nd-introduce-product-item-menu-title nd-introduce-photo-item-menu-title">
                            Absorption Peak [nm]
                          </th>
                          <th className="nd-introduce-product-item-menu-title nd-introduce-photo-item-menu-title">
                            Package
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.photo_initilator &&
                          products.photo_initilator[
                            paginations.photo_initilator.pagination.currentPage - 1
                          ] &&
                          products.photo_initilator[
                            paginations.photo_initilator.pagination.currentPage - 1
                          ].map((el) => {
                            return (
                              <tr key={el.id} className="nd-introduce-product-item-menu-box">
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column1}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column2}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column3}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column4}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column5}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column6}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column7}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column8}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column9}
                                </td>
                              </tr>
                            );
                          })}
                        {products.photo_initilator && products.photo_initilator[0].length === 0 && (
                          <tr key={'no'}>
                            <td
                              colSpan="20"
                              className="nd-introduce-product-item-menu-text text-center no-data"
                            >
                              {lang === 'ko'
                                ? '등록된 제품이 없습니다.'
                                : 'There is no registered product.'}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {counts.photo_initilator.count !== 0 && (
                      <Pagination
                        className="introduce-product"
                        pagination={paginations.photo_initilator.pagination}
                        handlePagination={handlePagination}
                        type="photo_initilator"
                      />
                    )}
                  </div>
                </div>
              </Fade>

              {/* <!-- SURFACTANTS --> */}
              <div
                data-tab-content
                id="nd-surfactants"
                className="nd-introduce-product-list"
                className={'nd-introduce-product-item ' + (menu2.chemical[3] ? 'active' : '')}
              >
                <Fade bottom>
                  <div className="nd-surfactants-item">
                    <div className="nd-introduce-product-list-box">
                      <div className="nd-introduce-product-list-title-box">
                        <h2 className="nd-introduce-product-list-title">
                          MONOPOL NPE SERIES POLYOXYETHYLENE NONYL PHENYL ETHER DERIVATIVES
                        </h2>
                      </div>
                      <div
                        className="nd-introduce-product-list-minus-bar-box"
                        onClick={(e) => handleMenu3(e, 0)}
                      >
                        {menu3[0] ? (
                          <img
                            className="nd-introduce-product-list-minus-bar"
                            src="/images/minus-bar.png"
                            alt=""
                          />
                        ) : (
                          <img
                            className="nd-introduce-product-list-minus-bar"
                            src="/images/plus-bar.png"
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        'nd-introduce-product-surfactants-item ' + (menu3[0] ? 'active' : '')
                      }
                    >
                      <div className="nd-introduce-product-item-box">
                        <table className="nd-introduce-product-item-list-box">
                          <thead className="nd-introduce-product-item-menu">
                            <tr className="nd-introduce-product-item-menu-box">
                              <th className="nd-introduce-product-item-menu-title">No</th>
                              <th className="nd-introduce-product-item-menu-title">Product name</th>
                              <th className="nd-introduce-product-item-menu-title">EO mol</th>
                              <th className="nd-introduce-product-item-menu-title">
                                Appearance
                                <br />
                                (at 25℃)
                              </th>
                              <th className="nd-introduce-product-item-menu-title">HLB</th>
                              <th className="nd-introduce-product-item-menu-title">
                                Cloud point(℃)
                              </th>
                              <th className="nd-introduce-product-item-menu-title">pH</th>
                              <th className="nd-introduce-product-item-menu-title">
                                Density(g/cm²)
                              </th>
                              <th className="nd-introduce-product-item-menu-title">Application</th>
                            </tr>
                          </thead>
                          <tbody>
                            {products.npe &&
                              products.npe[paginations.npe.pagination.currentPage - 1] &&
                              products.npe[paginations.npe.pagination.currentPage - 1].map((el) => {
                                return (
                                  <tr key={el.id} className="nd-introduce-product-item-menu-box">
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column1}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column2}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column3}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column4}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column5}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column6}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column7}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column8}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column9}
                                    </td>
                                  </tr>
                                );
                              })}
                            {products.npe && products.npe[0].length === 0 && (
                              <tr key={'no'}>
                                <td
                                  colSpan="20"
                                  className="nd-introduce-product-item-menu-text text-center no-data"
                                >
                                  {lang === 'ko'
                                    ? '등록된 제품이 없습니다.'
                                    : 'There is no registered product.'}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {counts.npe.count !== 0 && (
                          <Pagination
                            className="introduce-product"
                            pagination={paginations.npe.pagination}
                            handlePagination={handlePagination}
                            type="npe"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Fade>

                <Fade bottom>
                  <div className="nd-surfactants-item">
                    <div className="nd-introduce-product-list-box">
                      <div className="nd-introduce-product-list-title-box">
                        <h2 className="nd-introduce-product-list-title">
                          MONOPOL OPE SERIES POLYOXYETHYLENE OCTYL PHENYL ETHER DERIVATIVES
                        </h2>
                      </div>
                      <div
                        className="nd-introduce-product-list-minus-bar-box"
                        onClick={(e) => handleMenu3(e, 1)}
                      >
                        {menu3[1] ? (
                          <img
                            className="nd-introduce-product-list-minus-bar"
                            src="/images/minus-bar.png"
                            alt=""
                          />
                        ) : (
                          <img
                            className="nd-introduce-product-list-minus-bar"
                            src="/images/plus-bar.png"
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        'nd-introduce-product-surfactants-item ' + (menu3[1] ? 'active' : '')
                      }
                    >
                      <div className="nd-introduce-product-item-box">
                        <table className="nd-introduce-product-item-list-box">
                          <thead className="nd-introduce-product-item-menu">
                            <tr className="nd-introduce-product-item-menu-box">
                              <th className="nd-introduce-product-item-menu-title">No</th>
                              <th className="nd-introduce-product-item-menu-title">Product name</th>
                              <th className="nd-introduce-product-item-menu-title">EO mol</th>
                              <th className="nd-introduce-product-item-menu-title">
                                Appearance
                                <br />
                                (at 25℃)
                              </th>
                              <th className="nd-introduce-product-item-menu-title">HLB</th>
                              <th className="nd-introduce-product-item-menu-title">
                                Cloud point(℃)
                              </th>
                              <th className="nd-introduce-product-item-menu-title">pH</th>
                              <th className="nd-introduce-product-item-menu-title">
                                Density(g/cm²)
                              </th>
                              <th className="nd-introduce-product-item-menu-title">Application</th>
                            </tr>
                          </thead>
                          <tbody>
                            {products.ope &&
                              products.ope[paginations.ope.pagination.currentPage - 1] &&
                              products.ope[paginations.ope.pagination.currentPage - 1].map((el) => {
                                return (
                                  <tr key={el.id} className="nd-introduce-product-item-menu-box">
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column1}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column2}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column3}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column4}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column5}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column6}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column7}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column8}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column9}
                                    </td>
                                  </tr>
                                );
                              })}
                            {products.ope && products.ope[0].length === 0 && (
                              <tr key={'no'}>
                                <td
                                  colSpan="20"
                                  className="nd-introduce-product-item-menu-text text-center no-data"
                                >
                                  {lang === 'ko'
                                    ? '등록된 제품이 없습니다.'
                                    : 'There is no registered product.'}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {counts.ope.count !== 0 && (
                          <Pagination
                            className="introduce-product"
                            pagination={paginations.ope.pagination}
                            handlePagination={handlePagination}
                            type="ope"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Fade>

                <Fade bottom>
                  <div className="nd-surfactants-item">
                    <div className="nd-introduce-product-list-box">
                      <div className="nd-introduce-product-list-title-box">
                        <h2 className="nd-introduce-product-list-title">
                          MONOPOL NFE SERIES POLYOXYETHYLENE ALKYL ETHER DERIVATIVES(REPLACE NP
                          PRODUCT)
                        </h2>
                      </div>
                      <div
                        className="nd-introduce-product-list-minus-bar-box"
                        onClick={(e) => handleMenu3(e, 2)}
                      >
                        {menu3[2] ? (
                          <img
                            className="nd-introduce-product-list-minus-bar"
                            src="/images/minus-bar.png"
                            alt=""
                          />
                        ) : (
                          <img
                            className="nd-introduce-product-list-minus-bar"
                            src="/images/plus-bar.png"
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        'nd-introduce-product-surfactants-item ' + (menu3[2] ? 'active' : '')
                      }
                    >
                      <div className="nd-introduce-product-item-box">
                        <table className="nd-introduce-product-item-list-box">
                          <thead className="nd-introduce-product-item-menu">
                            <tr className="nd-introduce-product-item-menu-box">
                              <th className="nd-introduce-product-item-menu-title">No</th>
                              <th className="nd-introduce-product-item-menu-title">Product name</th>
                              <th className="nd-introduce-product-item-menu-title">EO mol</th>
                              <th className="nd-introduce-product-item-menu-title">
                                Appearance
                                <br />
                                (at 25℃)
                              </th>
                              <th className="nd-introduce-product-item-menu-title">HLB</th>
                              <th className="nd-introduce-product-item-menu-title">
                                Cloud point(℃)
                              </th>
                              <th className="nd-introduce-product-item-menu-title">pH</th>
                              <th className="nd-introduce-product-item-menu-title">
                                Density(g/cm²)
                              </th>
                              <th className="nd-introduce-product-item-menu-title">Application</th>
                            </tr>
                          </thead>
                          <tbody>
                            {products.nfe &&
                              products.nfe[paginations.nfe.pagination.currentPage - 1] &&
                              products.nfe[paginations.nfe.pagination.currentPage - 1].map((el) => {
                                return (
                                  <tr key={el.id} className="nd-introduce-product-item-menu-box">
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column1}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column2}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column3}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column4}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column5}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column6}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column7}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column8}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column9}
                                    </td>
                                  </tr>
                                );
                              })}
                            {products.nfe && products.nfe[0].length === 0 && (
                              <tr key={'no'}>
                                <td
                                  colSpan="20"
                                  className="nd-introduce-product-item-menu-text text-center no-data"
                                >
                                  {lang === 'ko'
                                    ? '등록된 제품이 없습니다.'
                                    : 'There is no registered product.'}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {counts.nfe.count !== 0 && (
                          <Pagination
                            className="introduce-product"
                            pagination={paginations.nfe.pagination}
                            handlePagination={handlePagination}
                            type="nfe"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Fade>

                <Fade bottom>
                  <div className="nd-surfactants-item">
                    <div className="nd-introduce-product-list-box">
                      <div className="nd-introduce-product-list-title-box">
                        <h2 className="nd-introduce-product-list-title">
                          MONOPOL LAE SERIES POLYOXYETHYLENE LAURYL ETHER DERIVATIVES
                        </h2>
                      </div>
                      <div
                        className="nd-introduce-product-list-minus-bar-box"
                        onClick={(e) => handleMenu3(e, 3)}
                      >
                        {menu3[3] ? (
                          <img
                            className="nd-introduce-product-list-minus-bar"
                            src="/images/minus-bar.png"
                            alt=""
                          />
                        ) : (
                          <img
                            className="nd-introduce-product-list-minus-bar"
                            src="/images/plus-bar.png"
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        'nd-introduce-product-surfactants-item ' + (menu3[3] ? 'active' : '')
                      }
                    >
                      <div className="nd-introduce-product-item-box">
                        <table className="nd-introduce-product-item-list-box">
                          <thead className="nd-introduce-product-item-menu">
                            <tr className="nd-introduce-product-item-menu-box">
                              <th className="nd-introduce-product-item-menu-title">No</th>
                              <th className="nd-introduce-product-item-menu-title">Product name</th>
                              <th className="nd-introduce-product-item-menu-title">EO mol</th>
                              <th className="nd-introduce-product-item-menu-title">
                                Appearance
                                <br />
                                (at 25℃)
                              </th>
                              <th className="nd-introduce-product-item-menu-title">HLB</th>
                              <th className="nd-introduce-product-item-menu-title">
                                Cloud point(℃)
                              </th>
                              <th className="nd-introduce-product-item-menu-title">pH</th>
                              <th className="nd-introduce-product-item-menu-title">
                                Density(g/cm²)
                              </th>
                              <th className="nd-introduce-product-item-menu-title">Application</th>
                            </tr>
                          </thead>
                          <tbody>
                            {products.lae &&
                              products.lae[paginations.lae.pagination.currentPage - 1] &&
                              products.lae[paginations.lae.pagination.currentPage - 1].map((el) => {
                                return (
                                  <tr key={el.id} className="nd-introduce-product-item-menu-box">
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column1}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column2}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column3}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column4}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column5}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column6}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column7}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column8}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column9}
                                    </td>
                                  </tr>
                                );
                              })}
                            {products.lae && products.lae[0].length === 0 && (
                              <tr key={'no'}>
                                <td
                                  colSpan="20"
                                  className="nd-introduce-product-item-menu-text text-center no-data"
                                >
                                  {lang === 'ko'
                                    ? '등록된 제품이 없습니다.'
                                    : 'There is no registered product.'}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {counts.lae.count !== 0 && (
                          <Pagination
                            className="introduce-product"
                            pagination={paginations.lae.pagination}
                            handlePagination={handlePagination}
                            type="lae"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Fade>

                <Fade bottom>
                  <div className="nd-surfactants-item">
                    <div className="nd-introduce-product-list-box">
                      <div className="nd-introduce-product-list-title-box">
                        <h2 className="nd-introduce-product-list-title">EMULSIFYING AGENT</h2>
                      </div>
                      <div
                        className="nd-introduce-product-list-minus-bar-box"
                        onClick={(e) => handleMenu3(e, 4)}
                      >
                        {menu3[4] ? (
                          <img
                            className="nd-introduce-product-list-minus-bar"
                            src="/images/minus-bar.png"
                            alt=""
                          />
                        ) : (
                          <img
                            className="nd-introduce-product-list-minus-bar"
                            src="/images/plus-bar.png"
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        'nd-introduce-product-surfactants-item ' + (menu3[4] ? 'active' : '')
                      }
                    >
                      <div className="nd-introduce-product-item-box">
                        <table className="nd-introduce-product-item-list-box">
                          <thead className="nd-introduce-product-item-menu">
                            <tr className="nd-introduce-product-item-menu-box">
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                No
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Product name
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Components
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Appearance
                                <br />
                                (at 25℃)
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Ionic
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                pH
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Active content
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Application
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Packing Unit
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {products.emulsifying &&
                              products.emulsifying[
                                paginations.emulsifying.pagination.currentPage - 1
                              ] &&
                              products.emulsifying[
                                paginations.emulsifying.pagination.currentPage - 1
                              ].map((el) => {
                                return (
                                  <tr key={el.id} className="nd-introduce-product-item-menu-box">
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column1}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column2}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column3}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column4}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column5}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column6}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column7}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column8}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column9}
                                    </td>
                                  </tr>
                                );
                              })}
                            {products.emulsifying && products.emulsifying[0].length === 0 && (
                              <tr key={'no'}>
                                <td
                                  colSpan="20"
                                  className="nd-introduce-product-item-menu-text text-center no-data"
                                >
                                  {lang === 'ko'
                                    ? '등록된 제품이 없습니다.'
                                    : 'There is no registered product.'}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {counts.emulsifying.count !== 0 && (
                          <Pagination
                            className="introduce-product"
                            pagination={paginations.emulsifying.pagination}
                            handlePagination={handlePagination}
                            type="emulsifying"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Fade>

                <Fade bottom>
                  <div className="nd-surfactants-item">
                    <div className="nd-introduce-product-list-box">
                      <div className="nd-introduce-product-list-title-box">
                        <h2 className="nd-introduce-product-list-title">DEFOAMING AGENT</h2>
                      </div>
                      <div
                        className="nd-introduce-product-list-minus-bar-box"
                        onClick={(e) => handleMenu3(e, 5)}
                      >
                        {menu3[5] ? (
                          <img
                            className="nd-introduce-product-list-minus-bar"
                            src="/images/minus-bar.png"
                            alt=""
                          />
                        ) : (
                          <img
                            className="nd-introduce-product-list-minus-bar"
                            src="/images/plus-bar.png"
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        'nd-introduce-product-surfactants-item ' + (menu3[5] ? 'active' : '')
                      }
                    >
                      <div className="nd-introduce-product-item-box">
                        <table className="nd-introduce-product-item-list-box">
                          <thead className="nd-introduce-product-item-menu">
                            <tr className="nd-introduce-product-item-menu-box">
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                No
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Product name
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Components
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Appearance
                                <br />
                                (at 25℃)
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Ionic
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                pH
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Active content
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Application
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Packing Unit
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {products.defoaming &&
                              products.defoaming[
                                paginations.defoaming.pagination.currentPage - 1
                              ] &&
                              products.defoaming[
                                paginations.defoaming.pagination.currentPage - 1
                              ].map((el) => {
                                return (
                                  <tr key={el.id} className="nd-introduce-product-item-menu-box">
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column1}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column2}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column3}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column4}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column5}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column6}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column7}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column8}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column9}
                                    </td>
                                  </tr>
                                );
                              })}
                            {products.defoaming && products.defoaming[0].length === 0 && (
                              <tr key={'no'}>
                                <td
                                  colSpan="20"
                                  className="nd-introduce-product-item-menu-text text-center no-data"
                                >
                                  {lang === 'ko'
                                    ? '등록된 제품이 없습니다.'
                                    : 'There is no registered product.'}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {counts.defoaming.count !== 0 && (
                          <Pagination
                            className="introduce-product"
                            pagination={paginations.defoaming.pagination}
                            handlePagination={handlePagination}
                            type="defoaming"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Fade>

                <Fade bottom>
                  <div className="nd-surfactants-item">
                    <div className="nd-introduce-product-list-box">
                      <div className="nd-introduce-product-list-title-box">
                        <h2 className="nd-introduce-product-list-title">ANTI-CAKING AGENT</h2>
                      </div>
                      <div
                        className="nd-introduce-product-list-minus-bar-box"
                        onClick={(e) => handleMenu3(e, 6)}
                      >
                        {menu3[6] ? (
                          <img
                            className="nd-introduce-product-list-minus-bar"
                            src="/images/minus-bar.png"
                            alt=""
                          />
                        ) : (
                          <img
                            className="nd-introduce-product-list-minus-bar"
                            src="/images/plus-bar.png"
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        'nd-introduce-product-surfactants-item ' + (menu3[6] ? 'active' : '')
                      }
                    >
                      <div className="nd-introduce-product-item-box">
                        <table className="nd-introduce-product-item-list-box">
                          <thead className="nd-introduce-product-item-menu">
                            <tr className="nd-introduce-product-item-menu-box">
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                No
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Product name
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Components
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Appearance
                                <br />
                                (at 25℃)
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Ionic
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                pH
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Active content
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Application
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Packing Unit
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {products.anti &&
                              products.anti[paginations.anti.pagination.currentPage - 1] &&
                              products.anti[paginations.anti.pagination.currentPage - 1].map(
                                (el) => {
                                  return (
                                    <tr key={el.id} className="nd-introduce-product-item-menu-box">
                                      <td className="nd-introduce-product-item-menu-text">
                                        {el.column1}
                                      </td>
                                      <td className="nd-introduce-product-item-menu-text">
                                        {el.column2}
                                      </td>
                                      <td className="nd-introduce-product-item-menu-text">
                                        {el.column3}
                                      </td>
                                      <td className="nd-introduce-product-item-menu-text">
                                        {el.column4}
                                      </td>
                                      <td className="nd-introduce-product-item-menu-text">
                                        {el.column5}
                                      </td>
                                      <td className="nd-introduce-product-item-menu-text">
                                        {el.column6}
                                      </td>
                                      <td className="nd-introduce-product-item-menu-text">
                                        {el.column7}
                                      </td>
                                      <td className="nd-introduce-product-item-menu-text">
                                        {el.column8}
                                      </td>
                                      <td className="nd-introduce-product-item-menu-text">
                                        {el.column9}
                                      </td>
                                    </tr>
                                  );
                                },
                              )}
                            {products.anti && products.anti[0].length === 0 && (
                              <tr key={'no'}>
                                <td
                                  colSpan="20"
                                  className="nd-introduce-product-item-menu-text text-center no-data"
                                >
                                  {lang === 'ko'
                                    ? '등록된 제품이 없습니다.'
                                    : 'There is no registered product.'}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {counts.anti.count !== 0 && (
                          <Pagination
                            className="introduce-product"
                            pagination={paginations.anti.pagination}
                            handlePagination={handlePagination}
                            type="anti"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Fade>

                <Fade bottom>
                  <div className="nd-surfactants-item">
                    <div className="nd-introduce-product-list-box">
                      <div className="nd-introduce-product-list-title-box">
                        <h2 className="nd-introduce-product-list-title">POLISHING AGENT</h2>
                      </div>
                      <div
                        className="nd-introduce-product-list-minus-bar-box"
                        onClick={(e) => handleMenu3(e, 7)}
                      >
                        {menu3[7] ? (
                          <img
                            className="nd-introduce-product-list-minus-bar"
                            src="/images/minus-bar.png"
                            alt=""
                          />
                        ) : (
                          <img
                            className="nd-introduce-product-list-minus-bar"
                            src="/images/plus-bar.png"
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        'nd-introduce-product-surfactants-item ' + (menu3[7] ? 'active' : '')
                      }
                    >
                      <div className="nd-introduce-product-item-box">
                        <table className="nd-introduce-product-item-list-box">
                          <thead className="nd-introduce-product-item-menu">
                            <tr className="nd-introduce-product-item-menu-box">
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                No
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Product name
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Components
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Appearance
                                <br />
                                (at 25℃)
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Ionic
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                pH
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Active content
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Application
                              </th>
                              <th className="nd-introduce-product-item-menu-title nd-introduce-agent-item-menu-title">
                                Packing Unit
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {products.polishing &&
                              products.polishing[
                                paginations.polishing.pagination.currentPage - 1
                              ] &&
                              products.polishing[
                                paginations.polishing.pagination.currentPage - 1
                              ].map((el) => {
                                return (
                                  <tr key={el.id} className="nd-introduce-product-item-menu-box">
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column1}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column2}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column3}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column4}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column5}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column6}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column7}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column8}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column9}
                                    </td>
                                  </tr>
                                );
                              })}
                            {products.polishing && products.polishing[0].length === 0 && (
                              <tr key={'no'}>
                                <td
                                  colSpan="20"
                                  className="nd-introduce-product-item-menu-text text-center no-data"
                                >
                                  {lang === 'ko'
                                    ? '등록된 제품이 없습니다.'
                                    : 'There is no registered product.'}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {counts.polishing.count !== 0 && (
                          <Pagination
                            className="introduce-product"
                            pagination={paginations.polishing.pagination}
                            handlePagination={handlePagination}
                            type="polishing"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>

              {/* <!-- PLASTICIZER --> */}
              <Fade bottom>
                <div
                  data-tab-content
                  id="nd-plasticizer"
                  className={'nd-introduce-product-item ' + (menu2.chemical[4] ? 'active' : '')}
                >
                  <div className="nd-introduce-product-item-box">
                    <table className="nd-introduce-product-item-list-box">
                      <thead className="nd-introduce-product-item-menu">
                        <tr className="nd-introduce-product-item-menu-box">
                          <th className="nd-introduce-product-item-menu-title">No</th>
                          <th className="nd-introduce-product-item-menu-title">ITEM</th>
                          <th className="nd-introduce-product-item-menu-title">FEATURE</th>
                          <th className="nd-introduce-product-item-menu-title">APPLICATION</th>
                          <th className="nd-introduce-product-item-menu-title">PACKAGE</th>
                          <th className="nd-introduce-product-item-menu-title">ORIGIN</th>
                          <th className="nd-introduce-product-item-menu-title">SOURCE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.plasticizer &&
                          products.plasticizer[
                            paginations.plasticizer.pagination.currentPage - 1
                          ] &&
                          products.plasticizer[
                            paginations.plasticizer.pagination.currentPage - 1
                          ].map((el) => {
                            return (
                              <tr key={el.id} className="nd-introduce-product-item-menu-box">
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column1}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column2}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column3}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column4}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column5}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column6}
                                </td>
                                <td className="nd-introduce-product-item-menu-text">
                                  {el.column7}
                                </td>
                              </tr>
                            );
                          })}
                        {products.plasticizer && products.plasticizer[0].length === 0 && (
                          <tr key={'no'}>
                            <td
                              colSpan="20"
                              className="nd-introduce-product-item-menu-text text-center no-data"
                            >
                              {lang === 'ko'
                                ? '등록된 제품이 없습니다.'
                                : 'There is no registered product.'}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {counts.plasticizer.count !== 0 && (
                      <Pagination
                        className="introduce-product"
                        pagination={paginations.plasticizer.pagination}
                        handlePagination={handlePagination}
                        type="plasticizer"
                      />
                    )}
                  </div>
                </div>
              </Fade>

              {/* <!-- COATING RESINS --> */}
              <Fade bottom>
                <div
                  data-tab-content
                  id="nd-coating"
                  className={'nd-introduce-resin-item ' + (menu2.resin[0] ? 'active' : '')}
                >
                  <div className="nd-coating-title-box">
                    <h2 className="nd-coating-title">
                      {lang === 'ko'
                        ? 'BA Seiries는 저수축제, 표면 코팅제, 인조대리석, 아크릴 코팅, 아크릴 접착제, 도료, 잉크, 페인트 등의 용도로 사용되며 USER의 요구 사항에 따라 Bead 제품의 분자량, Tg, 산가 조절이 가능합니다.'
                        : 'BA Seiries are used for low shrinkage agents, surface coating agents, artificial marble, acrylic coatings, acrylic adhesives, paints, inks, paints, etc. The molecular weight, Tg, and acid value of the beads can be adjusted according to the requirements of the user.'}
                    </h2>
                  </div>
                  <div className="nd-introduce-product-list">
                    <div
                      className={'nd-introduce-product-item ' + (menu2.resin[0] ? 'active' : '')}
                    >
                      <div className="nd-introduce-product-item-box">
                        <table className="nd-introduce-product-item-list-box">
                          <thead className="nd-introduce-product-item-menu">
                            <tr className="nd-introduce-product-item-menu-box">
                              <th className="nd-introduce-product-item-menu-title" rowSpan="3">
                                No
                              </th>
                              <th className="nd-introduce-product-item-menu-title" rowSpan="3">
                                Grade
                              </th>
                              <th
                                className="nd-introduce-product-item-menu-title"
                                colSpan="3"
                                rowSpan="2"
                              >
                                Typical Properties
                              </th>
                              <th className="nd-introduce-product-item-menu-title" colSpan="6">
                                Viscosity in Various Solvents
                              </th>
                            </tr>
                            <tr className="nd-introduce-product-item-menu-box">
                              <th className="nd-introduce-product-item-menu-title" colSpan="2">
                                Ester
                              </th>
                              <th className="nd-introduce-product-item-menu-title" colSpan="2">
                                Aromatics
                              </th>
                              <th className="nd-introduce-product-item-menu-title" colSpan="2">
                                Ketnone
                              </th>
                            </tr>
                            <tr className="nd-introduce-product-item-menu-box">
                              <th className="nd-introduce-product-item-menu-title">Tg</th>
                              <th className="nd-introduce-product-item-menu-title">Mw</th>
                              <th className="nd-introduce-product-item-menu-title">AV</th>
                              <th className="nd-introduce-product-item-menu-title">
                                Methyl Acetate
                              </th>
                              <th className="nd-introduce-product-item-menu-title">
                                Ethyl Acetate
                              </th>
                              <th className="nd-introduce-product-item-menu-title">Toulen</th>
                              <th className="nd-introduce-product-item-menu-title">Xylene</th>
                              <th className="nd-introduce-product-item-menu-title">Acetone</th>
                              <th className="nd-introduce-product-item-menu-title">MEK</th>
                            </tr>
                          </thead>
                          <tbody>
                            {products.coating_resins &&
                              products.coating_resins[
                                paginations.coating_resins.pagination.currentPage - 1
                              ] &&
                              products.coating_resins[
                                paginations.coating_resins.pagination.currentPage - 1
                              ].map((el) => {
                                return (
                                  <tr key={el.id} className="nd-introduce-product-item-menu-box">
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column1}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column2}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column3}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column4}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column5}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column6}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column7}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column8}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column9}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column10}
                                    </td>
                                    <td className="nd-introduce-product-item-menu-text">
                                      {el.column11}
                                    </td>
                                  </tr>
                                );
                              })}
                            {products.coating_resins && products.coating_resins[0].length === 0 && (
                              <tr key={'no'}>
                                <td
                                  colSpan="20"
                                  className="nd-introduce-product-item-menu-text text-center no-data"
                                >
                                  {lang === 'ko'
                                    ? '등록된 제품이 없습니다.'
                                    : 'There is no registered product.'}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {counts.coating_resins.count !== 0 && (
                          <Pagination
                            className="introduce-product"
                            pagination={paginations.coating_resins.pagination}
                            handlePagination={handlePagination}
                            type="coating_resins"
                          />
                        )}
                      </div>
                      <div className="nd-coating-text-box">
                        <p className="nd-coating-text">Note</p>
                        <p className="nd-coating-text">Tg : Glass transition temperature(℃)</p>
                        <p className="nd-coating-text">Mw : Molecular weight(g/mol)</p>
                        <p className="nd-coating-text">AV: Acid value(mg KOH/g)</p>
                        <p className="nd-coating-text">
                          Values are Brook field viscosity(cP), at 23℃ of a 40% solid soution,
                          excpet as noted
                        </p>
                        <p className="nd-coating-text">Superscript indicates % solid s.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>

              {/* <!-- EPOXY RESINS --> */}
              <Fade bottom>
                <div
                  data-tab-content
                  id="epoxy"
                  className={'nd-introduce-resin-item ' + (menu2.resin[1] ? 'active' : '')}
                >
                  <div className={'nd-introduce-product-item ' + (menu2.resin[1] ? 'active' : '')}>
                    <div className="nd-introduce-product-item-box">
                      <table className="nd-introduce-product-item-list-box">
                        <thead className="nd-introduce-product-item-menu">
                          <tr className="nd-introduce-product-item-menu-box">
                            <th className="nd-introduce-product-item-menu-title nd-introduce-epoxy-item-menu-title">
                              No
                            </th>
                            <th className="nd-introduce-product-item-menu-title nd-introduce-epoxy-item-menu-title">
                              Name
                            </th>
                            <th className="nd-introduce-product-item-menu-title nd-introduce-epoxy-item-menu-title">
                              Appearance
                            </th>
                            <th className="nd-introduce-product-item-menu-title nd-introduce-epoxy-item-menu-title">
                              EEW
                            </th>
                            <th className="nd-introduce-product-item-menu-title nd-introduce-epoxy-item-menu-title">
                              Hy-CL(ppm)
                            </th>
                            <th className="nd-introduce-product-item-menu-title nd-introduce-epoxy-item-menu-title">
                              T-CL(ppm)
                            </th>
                            <th className="nd-introduce-product-item-menu-title nd-introduce-epoxy-item-menu-title">
                              Softening point(℃)
                            </th>
                            <th className="nd-introduce-product-item-menu-title nd-introduce-epoxy-item-menu-title">
                              Viscosity
                              <br />
                              (cps at 25℃)
                            </th>
                            <th className="nd-introduce-product-item-menu-title nd-introduce-epoxy-item-menu-title">
                              Features
                            </th>
                            <th className="nd-introduce-product-item-menu-title nd-introduce-epoxy-item-menu-title">
                              Application
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {products.epoxy_resins &&
                            products.epoxy_resins[
                              paginations.epoxy_resins.pagination.currentPage - 1
                            ] &&
                            products.epoxy_resins[
                              paginations.epoxy_resins.pagination.currentPage - 1
                            ].map((el) => {
                              return (
                                <tr key={el.id} className="nd-introduce-product-item-menu-box">
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column1}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column2}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column3}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column4}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column5}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column6}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column7}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column8}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column9}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column10}
                                  </td>
                                </tr>
                              );
                            })}
                          {products.epoxy_resins && products.epoxy_resins[0].length === 0 && (
                            <tr key={'no'}>
                              <td
                                colSpan="20"
                                className="nd-introduce-product-item-menu-text text-center no-data"
                              >
                                {lang === 'ko'
                                  ? '등록된 제품이 없습니다.'
                                  : 'There is no registered product.'}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {counts.epoxy_resins.count !== 0 && (
                        <Pagination
                          className="introduce-product"
                          pagination={paginations.epoxy_resins.pagination}
                          handlePagination={handlePagination}
                          type="epoxy_resins"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Fade>

              {/* <!-- UV CURING RESIN --> */}
              <Fade bottom>
                <div
                  data-tab-content
                  id="curing"
                  className={'nd-introduce-resin-item ' + (menu2.resin[2] ? 'active' : '')}
                >
                  <div
                    className={
                      'nd-introduce-product-item border-none ' + (menu2.resin[2] ? 'active' : '')
                    }
                  >
                    <div className="nd-introduce-product-item-box">
                      <div
                        className="product-viewer-pc"
                        dangerouslySetInnerHTML={{
                          __html:
                            lang === 'ko'
                              ? products?.uv_curing_resins[0][0]?.column1
                              : products?.uv_curing_resins[0][0]?.column3,
                        }}
                      ></div>
                      <div
                        className="product-viewer-mo"
                        dangerouslySetInnerHTML={{
                          __html:
                            lang === 'ko'
                              ? products?.uv_curing_resins[0][0]?.column2
                              : products?.uv_curing_resins[0][0]?.column4,
                        }}
                      ></div>
                      {products.uv_curing_resins && products.uv_curing_resins[0].length === 0 && (
                        <tr key={'no'}>
                          <td
                            colSpan="20"
                            className="nd-introduce-product-item-menu-text text-center no-data"
                          >
                            {lang === 'ko'
                              ? '등록된 제품이 없습니다.'
                              : 'There is no registered product.'}
                          </td>
                        </tr>
                      )}
                    </div>
                    {/* <div className="nd-introduce-product-item-box">
                      <table className="nd-introduce-product-item-list-box">
                        <thead className="nd-introduce-product-item-menu">
                          <tr className="nd-introduce-product-item-menu-box">
                            <th className="nd-introduce-product-item-menu-title">No</th>
                            <th className="nd-introduce-product-item-menu-title">Product</th>
                            <th className="nd-introduce-product-item-menu-title">Features</th>
                            <th className="nd-introduce-product-item-menu-title">Application</th>
                          </tr>
                        </thead>
                        <tbody>
                          {products.uv_curing_resins &&
                            products.uv_curing_resins[
                              paginations.uv_curing_resins.pagination.currentPage - 1
                            ] &&
                            products.uv_curing_resins[
                              paginations.uv_curing_resins.pagination.currentPage - 1
                            ].map((el) => {
                              return (
                                <tr key={el.id} className="nd-introduce-product-item-menu-box">
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column1}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column2}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column3}
                                  </td>
                                  <td className="nd-introduce-product-item-menu-text">
                                    {el.column4}
                                  </td>
                                </tr>
                              );
                            })}
                          {products.uv_curing_resins && products.uv_curing_resins[0].length === 0 && (
                            <tr key={'no'}>
                              <td
                                colSpan="20"
                                className="nd-introduce-product-item-menu-text text-center no-data"
                              >
                                {lang === 'ko'
                                ? '등록된 제품이 없습니다.'
                                : 'There is no registered product.'}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {counts.uv_curing_resins.count !== 0 && (
                        <Pagination
                          className="introduce-product"
                          pagination={paginations.uv_curing_resins.pagination}
                          handlePagination={handlePagination}
                        />
                      )}
                    </div> */}
                  </div>
                </div>
              </Fade>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.config.lang,
  }),
  { setLang },
)(withRouter(Product));
