import React, { Component } from 'react';
import './AuthError.css';

class AuthError extends Component {
  render() {
    const { children } = this.props;
    return <div className="authError-wrapper">{children}</div>;
  }
}

export default AuthError;
