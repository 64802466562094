import React, { Component } from 'react';
import AdminFooter from 'components/admin/layout/AdminFooter';

class AdminFooterContainer extends Component {
  render() {
    return <AdminFooter />;
  }
}

export default AdminFooterContainer;
