import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './ProductUpdate.css';

class ProductUpdate extends Component {
  handleTableContent = () => {
    const { contents, contentType, handleChange } = this.props;

    let components = [];

    contents &&
      contentType &&
      contents[contentType].map((el, i) => {
        components.push(
          <tr key={i}>
            <th style={{ width: '20%' }}>{el}</th>
            <td colSpan="3">
              <input
                type="text"
                className="form-control"
                name={'column' + (i + 1)}
                value={this.props['column' + (i + 1)]}
                onChange={handleChange}
              />
            </td>
          </tr>,
        );
      });

    return components;
  };

  // handleTableContent = () => {};

  render() {
    const {
      id,
      type,
      column1,
      column2,
      column3,
      column4,
      column5,
      column6,
      column7,
      column8,
      column9,
      column10,
      column11,
      column12,
      product_order,
      is_active,
      handleChange,
      handleSave,
      handleDelete,
      productTypes,
      contents,
      handleType,
    } = this.props;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>프러덕트 관리</h1>
        </section>
        <section className="content">
          <div className="box box-primary">
            <div className="box-body">
              <div
                className="form-group col-lg-12 col-md-12"
                style={{
                  border: '1px solid #808080',
                  marginTop: '20px',
                  padding: '20px',
                  borderRadius: '4px',
                }}
              >
                <table className="table table- light">
                  <thead>
                    <tr>
                      <th colSpan="4">제품 정보</th>
                    </tr>
                  </thead>
                  <tbody>
                    {id === 0 && (
                      <tr>
                        <th style={{ width: '20%' }}>카테고리 *</th>
                        <td colSpan="3">
                          <select name="type" id="" value={type} onChange={handleType}>
                            {productTypes &&
                              productTypes.map((el, i) => {
                                if (el === 'uv_curing_resins') return;
                                return (
                                  <option key={i} value={el}>
                                    {el}
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                      </tr>
                    )}
                    {id !== 0 && (
                      <tr>
                        <th style={{ width: '20%' }}>카테고리 *</th>
                        <td colSpan="3">
                          <p>{type}</p>
                        </td>
                      </tr>
                    )}
                    {this.handleTableContent()}
                    <tr>
                      <th style={{ width: '20%' }}>제품 순서 *</th>
                      <td colSpan="3">
                        <input
                          type="number"
                          className="form-control"
                          name={'product_order'}
                          value={product_order}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <th style={{ width: '20%' }}>아이디 *</th>
                      <td colSpan="3">
                        {id === 0 ? (
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            value={email}
                            onChange={handleChange}
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            value={email}
                            readOnly
                          />
                        )}
                      </td>
                    </tr>
                    {id === 0 ? (
                      <>
                        <tr>
                          <th style={{ width: '20%' }}>패스워드 *</th>
                          <td colSpan="3">
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              value={password}
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: '20%' }}>패스워드 확인 *</th>
                          <td colSpan="3">
                            <input
                              type="password"
                              className="form-control"
                              name="password_confirm"
                              value={password_confirm}
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <th style={{ width: '20%' }}>패스워드</th>
                        <td colSpan="3">
                          {reset ? (
                            <>
                              <input
                                style={{ maxWidth: '20%' }}
                                type="password"
                                className="form-control"
                                name="password"
                                value={password}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                style={{ marginTop: '8px' }}
                                className="btn btn-warning"
                                onClick={closePasswordReset}
                              >
                                취소
                              </button>
                              <button
                                type="button"
                                style={{ marginLeft: '20px', marginTop: '8px' }}
                                className="btn btn-primary"
                                onClick={handleReset}
                              >
                                변경
                              </button>
                            </>
                          ) : (
                            <button
                              className="btn btn-primary btn-file"
                              onClick={openPasswordReset}
                            >
                              패스워드 변경
                            </button>
                          )}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th style={{ width: '20%' }}>이름</th>
                      <td colSpan="3">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={name}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>직책</th>
                      <td colSpan="3">
                        <input
                          type="text"
                          className="form-control"
                          name="position"
                          value={position}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>연락처</th>
                      <td colSpan="3">
                        <input
                          type="text"
                          className="form-control"
                          name="phone"
                          value={phone}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>활성화 여부</th>
                      <td colSpan="3">
                        <select
                          className="example-radio-group form-control"
                          name="is_active"
                          onChange={handleChange}
                          value={is_active}
                        >
                          <option value={1}>활성화</option>
                          <option value={0}>비활성화</option>
                        </select>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="box-footer">
              <div className="pull-right">
                <button
                  // to="/admin/architects"
                  className="btn btn-default"
                  style={{ marginRight: '5px' }}
                  onClick={() => this.props.history.push('/admin/products')}
                >
                  취소
                </button>
                <button className="btn btn-primary" onClick={handleSave}>
                  {id !== 0 ? '수정' : '등록'}
                </button>
              </div>
              {id !== 0 && (
                <button
                  onClick={() => handleDelete()}
                  type="button"
                  className="btn btn-danger"
                  style={{ marginLeft: '5px' }}
                >
                  삭제
                </button>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(ProductUpdate);
