import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getLang } from 'lib/common';
import './Header.css';
import { connect } from 'react-redux';
import { setLang } from 'data/actions';

class Header extends Component {
  state = {
    menu: {
      main: true,
      about: false,
      product: false,
      inquiry: false,
      contact: false,
    },
    hambuger: false,
    mobile: false,
  };

  componentWillMount() {
    const path = window.location.pathname;

    this.handleMenu(path);

    const lang = getLang() === 'ko' ? 'ko' : 'en';
    this.props.setLang(lang);
  }

  handleLang = (lang) => {
    localStorage['lang'] = lang;
    this.props.setLang(lang);
  };

  handleMenu = (path) => {
    let menu = {
      main: false,
      about: false,
      product: false,
      inquiry: false,
      contact: false,
    };

    if (path.indexOf('/about') > -1) {
      menu['about'] = true;
    } else if (path.indexOf('/business/inquiry') > -1) {
      menu['inquiry'] = true;
    } else if (path.indexOf('/business') > -1 || path.indexOf('/business/product') > -1) {
      menu['product'] = true;
    } else if (path.indexOf('/contact') > -1) {
      menu['contact'] = true;
    } else {
      menu['main'] = true;
    }

    this.setState({
      ...this.state,
      menu,
    });
  };

  handlePage = (e, page) => {
    e.stopPropagation();
    this.props.history.push(page);
    this.handleMenu(page);
  };

  handleClick = (e, name) => {
    this.setState({
      ...this.state,
      [name]: true,
    });
  };

  handleClose = (e, name) => {
    this.setState({
      ...this.state,
      [name]: false,
    });
  };

  render() {
    const { hambuger, menu, mobile } = this.state;
    const { handleClick, handlePage, handleClose, handleLang } = this;
    const { page, lang } = this.props;

    return (
      <>
        <header className="nd-header">
          <div className="nd-header-container">
            <div className="nd-header-box">
              <div className="nd-header-logo pointer" onClick={(e) => handlePage(e, '/')}>
                <img className="nd-header-main-logo" src="/images/main_logo.png" alt="logo" />
              </div>
              <div className="nd-header-menus">
                <p
                  className={'nd-header-menu-text pointer ' + (menu.about ? 'active' : '')}
                  onClick={(e) => handlePage(e, '/about')}
                >
                  ABOUT
                </p>
                <p
                  className={
                    'nd-header-menu-text pointer ' + (menu.product || menu.inquiry ? 'active' : '')
                  }
                  onClick={(e) => handlePage(e, '/business')}
                >
                  BUSINESS
                  {!hambuger && (
                    <ul className="nd-business-header-menu">
                      <li
                        className={
                          'nd-business-header-menu-item pointer ' + (menu.product ? 'active' : '')
                        }
                        onClick={(e) => handlePage(e, '/business/product')}
                      >
                        {lang === 'ko' ? '제품소개' : 'PRODUCT'}
                      </li>
                      <li
                        className={
                          'nd-business-header-menu-item pointer ' + (menu.inquiry ? 'active' : '')
                        }
                        onClick={(e) => handlePage(e, '/business/inquiry')}
                      >
                        {lang === 'ko' ? '제품 및 개발 문의' : 'INQUIRY'}
                      </li>
                    </ul>
                  )}
                </p>
                <p
                  className={'nd-header-menu-text pointer last ' + (menu.contact ? 'active' : '')}
                  onClick={(e) => handlePage(e, '/contact')}
                >
                  CONTACT
                </p>
                <div className="nd-header-language-box">
                  <p
                    className={'nd-header-language pointer ' + (lang === 'ko' ? 'active' : '')}
                    onClick={(e) => handleLang('ko')}
                  >
                    KR
                  </p>
                  <p
                    className={'nd-header-language pointer ' + (lang === 'en' ? 'active' : '')}
                    onClick={(e) => handleLang('en')}
                  >
                    EN
                  </p>
                </div>
                <div className="nd-header-hambuger">
                  <img
                    className="nd-header-main-hambuger pc-only pointer"
                    src="/images/hambuger.svg"
                    alt="삼단바"
                    onClick={(e) => handleClick(e, 'hambuger')}
                  />
                  <img
                    className="nd-header-main-hambuger mobile-only pointer"
                    src="/images/hambuger.svg"
                    alt="삼단바"
                    onClick={(e) => handleClick(e, 'mobile')}
                  />
                </div>
              </div>
            </div>
          </div>
        </header>

        <div
          className={
            'nd-business-header ' + (!hambuger && (menu.product || menu.inquiry) ? 'active' : '')
          }
        >
          <div className="nd-business-header-container">
            <div className="nd-business-header-box">
              {/* <ul className="nd-business-header-menu">
                <li
                  className={
                    'nd-business-header-menu-item pointer ' + (menu.product ? 'active' : '')
                  }
                  onClick={(e) => handlePage(e, '/business/product')}
                >
                  {lang === 'ko' ? '제품소개' : 'PRODUCT'}
                </li>
                <li
                  className={
                    'nd-business-header-menu-item pointer ' + (menu.inquiry ? 'active' : '')
                  }
                  onClick={(e) => handlePage(e, '/business/inquiry')}
                >
                  {lang === 'ko' ? '제품 및 개발 문의' : 'INQUIRY'}
                </li>
              </ul> */}
            </div>
          </div>
        </div>

        <div className={'nd-header-hambuger-menu-container ' + page + (hambuger ? ' active' : '')}>
          <div className="nd-header-hambuger-menu-box">
            <img
              className="nd-header-hambuger-menu-close pointer"
              src="/images/x.png"
              alt=""
              onClick={(e) => handleClose(e, 'hambuger')}
            />
            <div className="nd-header-hambuger-mobile-menu-box">
              <div className="nd-header-hambuger-mobile-menu-container">
                <div className="nd-header-hambuger-mobile-menu-language-container">
                  <div className="nd-header-hambuger-mobile-menu-language-box">
                    <p
                      className={
                        'nd-header-hambuger-mobile-menu-kr-language pointer ' +
                        (lang === 'ko' ? 'active' : '')
                      }
                      onClick={(e) => handleLang('ko')}
                    >
                      KR
                    </p>
                  </div>
                  <div>
                    <p
                      className={
                        'nd-header-hambuger-mobile-menu-en-language pointer ' +
                        (lang === 'en' ? 'active' : '')
                      }
                      onClick={(e) => handleLang('en')}
                    >
                      EN
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="nd-header-hambuger-menu">
              <div className="nd-header-hambuger-menu-title-box">
                <h2
                  className="nd-header-hambuger-menu-title pointer"
                  onClick={(e) => handlePage(e, '/about')}
                >
                  ABOUT
                </h2>
              </div>

              <div className="nd-header-hambuger-menu-title-box">
                <h2
                  className="nd-header-hambuger-menu-title pointer"
                  onClick={(e) => handlePage(e, '/business')}
                >
                  BUSINESS
                </h2>
                <div className="nd-header-hambuger-menu-text-box">
                  <p
                    className="nd-header-hambuger-menu-text pointer"
                    onClick={(e) => handlePage(e, '/business/product')}
                  >
                    {lang === 'ko' ? '제품소개' : 'PRODUCT'}
                  </p>
                  <p
                    className="nd-header-hambuger-menu-text pointer"
                    onClick={(e) => handlePage(e, '/business/inquiry')}
                  >
                    {lang === 'ko' ? '제품 및 개발 문의' : 'INQUIRY'}
                  </p>
                </div>
              </div>
              <div className="nd-header-hambuger-menu-title-box">
                <h2
                  className="nd-header-hambuger-menu-title pointer"
                  onClick={(e) => handlePage(e, '/contact')}
                >
                  CONTACT
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className={'nd-header-hambuger-mobile-menu-inner ' + (mobile ? 'active' : '')}>
          <div className="nd-header-hambuger-menu-box">
            <div className="nd-header-hambuger-mobile-menu-box">
              <div className="nd-header-hambuger-mobile-menu-container">
                <div className="nd-header-hambuger-mobile-menu-language-container">
                  <div className="nd-header-hambuger-mobile-menu-language-box">
                    <p
                      className={
                        'nd-header-hambuger-mobile-menu-kr-language pointer ' +
                        (lang === 'ko' ? 'active' : '')
                      }
                      onClick={(e) => handleLang('ko')}
                    >
                      KR
                    </p>
                  </div>
                  <div>
                    <p
                      className={
                        'nd-header-hambuger-mobile-menu-en-language pointer ' +
                        (lang === 'en' ? 'active' : '')
                      }
                      onClick={(e) => handleLang('en')}
                    >
                      EN
                    </p>
                  </div>
                </div>
                <img
                  className="nd-header-hambuger-menu-moblie-close pointer"
                  src="/images/mobile-x.png"
                  alt=""
                  onClick={(e) => handleClose(e, 'mobile')}
                />
              </div>
            </div>
            <div className="nd-header-hambuger-menu">
              <div className="nd-header-hambuger-menu-title-box">
                <h2
                  className="nd-header-hambuger-menu-title pointer"
                  onClick={(e) => handlePage(e, '/about')}
                >
                  ABOUT
                </h2>
              </div>

              <div className="nd-header-hambuger-menu-title-box">
                <h2
                  className="nd-header-hambuger-menu-title pointer"
                  onClick={(e) => handlePage(e, '/business')}
                >
                  BUSINESS
                </h2>
              </div>
              <div className="nd-header-hambuger-menu-text-box">
                <p
                  className="nd-header-hambuger-mobile-menu-text pointer"
                  onClick={(e) => handlePage(e, '/business/product')}
                >
                  {lang === 'ko' ? '제품소개' : 'PRODUCT'}
                </p>
                <p
                  className="nd-header-hambuger-mobile-menu-text pointer"
                  onClick={(e) => handlePage(e, '/business/inquiry')}
                >
                  {lang === 'ko' ? '제품 및 개발 문의' : 'INQUIRY'}
                </p>
              </div>
              <div className="nd-header-hambuger-menu-title-box">
                <h2
                  className="nd-header-hambuger-menu-title pointer"
                  onClick={(e) => handlePage(e, '/contact')}
                >
                  CONTACT
                </h2>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.config.lang,
  }),
  { setLang },
)(withRouter(Header));
