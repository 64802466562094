import React, { Component } from 'react';
import AuthContainer from 'containers/AuthContainer';
import PageUpdate from 'components/admin/pages/PageUpdate';
import { getPageList, createPage, updatePage } from 'api/page';
import { uploadPageImage } from 'api/upload';
import { withRouter } from 'react-router-dom';

class PageUpdateContainer extends Component {
  state = {
    id: 0,
    title: '',
    en_title: '',
    image: '',
    type: 'about',
    isLoading: {
      image: false,
    },
    pages: {},
    // type enum :  about, business_introduce, business_inquiry, contact, privacy, email
  };

  componentDidMount() {
    this.handlePageList();
  }

  handlePageList = async (t) => {
    const result = await getPageList();
    if (result && result.status === 200) {
      const typeList = [
        'about',
        'business_introduce',
        'business_inquiry',
        'contact',
        'privacy',
        'email',
      ];

      let pages = {};

      typeList.map((el) => {
        const r = result.data.some((item) => {
          if (el === item.type) {
            pages[el] = item;
          }

          return el === item.type;
        });

        if (!r) {
          pages[el] = {
            id: 0,
            title: '',
            en_title: '',
            image: '',
            type: el,
            isLoading: {
              image: false,
            },
          };
        }
      });

      const currentType = t ? t : 'about';

      this.setState({
        ...this.state,
        id: pages[currentType].id || 0,
        title: pages[currentType].title || '',
        en_title: pages[currentType].en_title || '',
        image: pages[currentType].image || '',
        type: currentType,
        pages,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  handleImage = async (e, name) => {
    this.setState({
      ...this.state,
      isLoading: {
        ...this.state.isLoading,
        [name]: true,
      },
    });

    const result = await uploadPageImage(e.target.files[0]);
    if (result && result.status === 200) {
      this.setState({
        ...this.state,
        [name]: result.data.key,
        isLoading: {
          ...this.state.isLoading,
          [name]: false,
        },
      });
    } else {
      this.setState({
        ...this.state,
        isLoading: {
          ...this.state.isLoading,
          [name]: false,
        },
      });
    }
  };

  handleSave = async () => {
    const { id, title, en_title, image, type } = this.state;
    // 수정
    if (!title.trim()) return alert('Ko 타이틀을 입력해주세요:)');
    if (!en_title.trim()) return alert('English 타이틀을 입력해주세요:)');
    if (!image) return alert('이미지를 선택해주세요:)');

    if (this.state.id) {
      const result = await updatePage(id, { title, en_title, image, type });
      if (result && result.status === 200) {
        alert('수정 성공:)');
        this.handlePageList(type);
        // this.props.history.push('/admin/pages');
      }
    } else {
      const result = await createPage({ title, en_title, image, type });
      if (result && result.status === 200) {
        alert('등록 성공:)');
        this.handlePageList(type);
        // this.props.history.push('/admin/pages');
      }
    }
  };

  resetImage = () => {
    this.setState({
      ...this.state,
      image: '',
    });
  };

  handleType = (type) => {
    const { pages } = this.state;

    this.setState({
      ...this.state,
      id: pages[type].id || 0,
      title: pages[type].title,
      en_title: !!pages[type].en_title ? pages[type].en_title : '',
      image: pages[type].image,
      type,
      isLoading: {
        image: false,
      },
    });
  };

  render() {
    const { handleChange, handleImage, handleType, resetImage, handleSave } = this;

    return (
      <PageUpdate
        {...this.state}
        handleChange={handleChange}
        handleImage={handleImage}
        handleType={handleType}
        resetImage={resetImage}
        handleSave={handleSave}
      />
    );
  }
}

export default AuthContainer()(withRouter(PageUpdateContainer));
