import React, { Component } from 'react';
import Map from 'components/ui/Map';
import { withRouter } from 'react-router-dom';
import './Contact.css';
import Fade from 'react-reveal/Fade';
import { connect } from 'react-redux';
import { setLang } from 'data/actions';

class Contact extends Component {
  render() {
    const { contact, lang } = this.props;

    return (
      <>
        <div className="nd-contact-direction-container">
          <div className="nd-contact-direction-title-box">
            <Fade bottom>
              <h2 className="nd-contact-direction-title">
                {lang === 'ko' ? '오시는길' : 'Way to come'}
              </h2>
            </Fade>
          </div>
          <div className="nd-contact-direction-map-box">
            <Fade bottom>
              <Map />
              <div className="nd-contact-direction-map-explanation-box">
                <div className="nd-contact-map-explanation-box">
                  <div className="nd-contact-direction-map-explanation-title-box">
                    <h2 className="nd-contact-direction-map-explanation-title">
                      {lang === 'ko' ? contact.company : contact.en_company}
                    </h2>
                  </div>
                  <div className="nd-contact-direction-map-explanation-text-box">
                    <p className="nd-contact-direction-map-explanation-text">
                      {lang === 'ko' ? contact.address : contact.en_address}
                    </p>
                    <p className="nd-contact-direction-map-explanation-text">
                      {contact.contact_number}
                    </p>
                  </div>
                  <div className="nd-contact-direction-map-explanation-detail-box">
                    {/* <p className="nd-contact-direction-map-explanation-detail">
                    view detail{' '}
                    <img
                      className="nd-contact-direction-map-explanation-detail-img"
                      src="./Images/contact-detail.svg"
                      alt=""
                    />
                  </p> */}
                  </div>
                </div>
              </div>
            </Fade>

            <Fade bottom>
              <div
                className="nd-contact-map-inquiry-box pointer"
                onClick={() => this.props.history.push('/business/inquiry')}
              >
                <div className="nd-contact-map-inquiry-title-box">
                  <h2 className="nd-contact-map-inquiry-title">
                    {lang === 'ko' ? '제품문의' : 'INQUIRY'}
                  </h2>
                </div>
                <div className="nd-contact-map-inquiry-text-box">
                  <p className="nd-contact-map-inquiry-text">
                    {lang === 'ko'
                      ? 'KCMC 제품 관련 문의를 상담해보세요.'
                      : 'Please consult with KCMC product related inquiries.'}
                  </p>
                  <img
                    className="nd-contact-map-inquiry-text-arrow"
                    src="./Images/contact-map-arrow.svg"
                    alt=""
                  />
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.config.lang,
  }),
  { setLang },
)(withRouter(Contact));
