import React, { Component } from 'react';
import AuthError from 'components/admin/AuthError';
import './Login.css';

class Login extends Component {
  render() {
    const { email, password, handleChange, handleLogin } = this.props;

    return (
      <div className="login-box" style={{ width: '500px', paddingTop: '160px' }}>
        <div className="login-logo">
          <b> KCMC 관리자 페이지</b>
        </div>
        <form name="form" onSubmit={(e) => e.preventDefault()}>
          <div className="login-box-body">
            <p className={'login-box-msg '}>관리자 계정을 입력해주세요</p>
            <div className="form-group has-feedback">
              <input
                type="text"
                className="form-control login_id"
                placeholder="EMAIL"
                name="email"
                value={email}
                onChange={handleChange}
              />
              <span className="glyphicon glyphicon-user form-control-feedback"></span>
            </div>
            <div className="form-group has-feedback">
              <input
                type="password"
                className="form-control login_pw"
                placeholder="PASSWORD"
                name="password"
                value={password}
                onChange={handleChange}
              />
              <span className="glyphicon glyphicon-lock form-control-feedback"></span>
            </div>
            <div className="row">
              <div className="col-xs-3"></div>
              <p className="pull-left login-desc">
                *로그인 관련 문제가 있을 경우 관리자에게 문의주세요.
              </p>
              <br />
              <br />
              <div className="col-xs-8"></div>
              <div className="col-xs-4">
                <button className="btn btn-primary btn-block btn-flat" onClick={handleLogin}>
                  로그인
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default Login;
