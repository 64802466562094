import React, { Component } from 'react';
import ArchitectList from 'components/admin/architect/ArchitectList';

class ArchitectListContainer extends Component {
  render() {
    return <ArchitectList />;
  }
}

export default ArchitectListContainer;
