import React, { Component } from 'react';
import AuthContainer from 'containers/AuthContainer';
import ProductUpdate from 'components/admin/products/ProductUpdate';
import { productTypes, contents } from 'lib/product';
import { getProduct, createProduct, updateProduct, deleteProduct } from 'api/product';
import { withRouter } from 'react-router-dom';

class ProductUpdateContainer extends Component {
  state = {
    id: 0,
    type: 'monomers',
    contentType: 'monomers',
    column1: '',
    column2: '',
    column3: '',
    column4: '',
    column5: '',
    column6: '',
    column7: '',
    column8: '',
    column9: '',
    column10: '',
    column11: '',
    column12: '',
    product_order: 0,
    is_active: 0,
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    const surfactants_monopol = ['npe', 'ope', 'nfe', 'lae'];
    const surfactants_agent = ['emulsifying', 'defoaming', 'anti', 'polishing'];

    if (id) {
      const result = await getProduct(id);
      if (result && result.status === 200) {
        this.setState({
          ...this.state,
          id: id || 0,
          type: result.data.type || 'monomers',
          contentType:
            surfactants_monopol.indexOf(result.data.type) > -1
              ? 'surfactants_monopol'
              : surfactants_agent.indexOf(result.data.type) > -1
              ? 'surfactants_agent'
              : result.data.type,
          column1: result.data.column1 || '',
          column2: result.data.column2 || '',
          column3: result.data.column3 || '',
          column4: result.data.column4 || '',
          column5: result.data.column5 || '',
          column6: result.data.column6 || '',
          column7: result.data.column7 || '',
          column8: result.data.column8 || '',
          column9: result.data.column9 || '',
          column10: result.data.column10 || '',
          column11: result.data.column11 || '',
          column12: result.data.column12 || '',
          product_order: result.data.product_order || 0,
          is_active: result.data.is_active || 0,
        });
      }
    }
  }

  // getProduct

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  handleSave = async () => {
    const {
      id,
      type,
      column1,
      column2,
      column3,
      column4,
      column5,
      column6,
      column7,
      column8,
      column9,
      column10,
      column11,
      column12,
      product_order,
      is_active,
    } = this.state;
    // 수정

    if (this.state.id) {
      const result = await updateProduct(id, {
        type,
        column1,
        column2,
        column3,
        column4,
        column5,
        column6,
        column7,
        column8,
        column9,
        column10,
        column11,
        column12,
        product_order,
        is_active,
      });
      if (result && result.status === 200) {
        alert('수정 성공:)');
        this.props.history.push('/admin/products');
      }
    } else {
      const result = await createProduct({
        type,
        column1,
        column2,
        column3,
        column4,
        column5,
        column6,
        column7,
        column8,
        column9,
        column10,
        column11,
        column12,
        product_order,
        is_active,
      });
      if (result && result.status === 200) {
        alert('등록 성공:)');
        this.props.history.push('/admin/products');
      }
    }
  };

  handleType = (e) => {
    const type = e.target.value;
    const surfactants_monopol = ['npe', 'ope', 'nfe', 'lae'];
    const surfactants_agent = ['emulsifying', 'defoaming', 'anti', 'polishing'];

    this.setState({
      ...this.state,
      type: type || 'monomers',
      contentType:
        surfactants_monopol.indexOf(type) > -1
          ? 'surfactants_monopol'
          : surfactants_agent.indexOf(type) > -1
          ? 'surfactants_agent'
          : type,
      column1: '',
      column2: '',
      column3: '',
      column4: '',
      column5: '',
      column6: '',
      column7: '',
      column8: '',
      column9: '',
      column10: '',
      column11: '',
      column12: '',
      product_order: 0,
      is_active: 0,
    });
  };

  handleDelete = async () => {
    const { id } = this.state;
    const result = await deleteProduct(id);
    if (result && result.status === 200) {
      alert('삭제 성공:)');
      this.props.history.push('/admin/products');
    }
  };

  render() {
    return (
      <ProductUpdate {...this.state} {...this} productTypes={productTypes} contents={contents} />
    );
  }
}

export default AuthContainer()(withRouter(ProductUpdateContainer));
