import React, { Component } from 'react';
import AuthContainer from 'containers/AuthContainer';
import PopupUpdate from 'components/admin/popups/PopupUpdate';
import { withRouter } from 'react-router-dom';
import { uploadPopupImage } from 'api/upload';
import { getPopup, createPopup, updatePopup } from 'api/popup';

class PopupUpdateContainer extends Component {
  state = {
    id: 0,
    description: '',
    pc_image: '',
    mo_image: '',
    en_pc_image: '',
    en_mo_image: '',
    is_active: 0,
    isLoading: {
      pc_image: false,
      mo_image: false,
      en_pc_image: false,
      en_mo_image: false,
    },
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  async componentDidMount() {
    // this.getArchitect(id);
    this.handleGetPopup();
  }

  handleGetPopup = async () => {
    const result = await getPopup();
    if (result && result.status === 200) {
      if (result.data.length > 0) {
        this.setState({
          ...this.state,
          id: result.data[0].id || 0,
          description: result.data[0].description || '',
          pc_image: result.data[0].pc_image || '',
          mo_image: result.data[0].mo_image || '',
          en_pc_image: result.data[0].en_pc_image || '',
          en_mo_image: result.data[0].en_mo_image || '',
          is_active: result.data[0].is_active || 0,
        });
      }
    } else {
      this.props.history.push('/admin/popups');
    }
  };

  // e.target.files[0]

  handlePopupImage = async (e, name) => {
    this.setState({
      ...this.state,
      isLoading: {
        ...this.state.isLoading,
        [name]: true,
      },
    });

    const result = await uploadPopupImage(e.target.files[0]);
    if (result && result.status === 200) {
      this.setState({
        ...this.state,
        [name]: result.data.key,
        isLoading: {
          ...this.state.isLoading,
          [name]: false,
        },
      });
    } else {
      this.setState({
        ...this.state,
        isLoading: {
          ...this.state.isLoading,
          [name]: false,
        },
      });
    }
  };

  handleSave = async () => {
    const { id, description, pc_image, mo_image, en_pc_image, en_mo_image, is_active } = this.state;
    // 수정

    if (this.state.id) {
      const result = await updatePopup(id, {
        description,
        pc_image,
        mo_image,
        en_pc_image,
        en_mo_image,
        is_active,
      });
      if (result && result.status === 200) {
        alert('수정 성공:)');
        this.handleGetPopup();
      }
    } else {
      const result = await createPopup({
        description,
        pc_image,
        mo_image,
        en_pc_image,
        en_mo_image,
        is_active,
      });
      if (result && result.status === 200) {
        alert('등록 성공:)');
        this.handleGetPopup();
      }
    }
  };

  resetImage = (name) => {
    this.setState({
      ...this.state,
      [name]: '',
    });
  };

  render() {
    const { handlePopupImage, resetImage, handleSave, handleChange } = this;

    return (
      <PopupUpdate
        {...this.state}
        handlePopupImage={handlePopupImage}
        resetImage={resetImage}
        handleSave={handleSave}
        handleChange={handleChange}
      />
    );
  }
}

export default AuthContainer()(withRouter(PopupUpdateContainer));
