import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './SlideUpdate.css';

class SlideUpdate extends Component {
  render() {
    const {
      slides,
      isLoading,
      handleChange,
      handleImage,
      resetImage,
      handleSave,
      handleAdd,
      handleRemove,
      id,
    } = this.props;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>메인 슬라이드 관리</h1>
        </section>
        <section className="content">
          <div className="box box-primary">
            <div className="box-body">
              <div
                className="form-group col-lg-12 col-md-12"
                style={{
                  border: '1px solid #808080',
                  marginTop: '20px',
                  padding: '20px',
                  borderRadius: '4px',
                }}
              >
                <div className="box-body">
                  {slides &&
                    slides.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="form-group col-lg-12 col-md-12"
                          style={{
                            border: '1px solid #808080',
                            marginTop: '20px',
                            padding: '20px',
                            borderRadius: '4px',
                          }}
                        >
                          <table className="table table- light">
                            <thead>
                              <tr>
                                <th colSpan="4">
                                  슬라이드 {i + 1}
                                  <button
                                    type="button"
                                    className="btn btn-warning pull-right"
                                    onClick={(e) => handleRemove(i)}
                                  >
                                    항목 제거
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary pull-right"
                                    style={{ marginRight: '4px' }}
                                    onClick={(e) => handleAdd(i)}
                                  >
                                    항목 추가
                                  </button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th style={{ width: '20%' }}>Ko 슬라이드 PC 타이틀 *</th>
                                <td colSpan="3">
                                  <textarea
                                    rows="2"
                                    style={{ width: '80%', resize: 'none' }}
                                    name="pc_title"
                                    value={item.pc_title}
                                    onChange={(e) => handleChange(e, i)}
                                  ></textarea>
                                </td>
                              </tr>
                              <tr>
                                <th style={{ width: '20%' }}>Ko 슬라이드 PC 글 *</th>
                                <td colSpan="3">
                                  <textarea
                                    rows="5"
                                    style={{ width: '80%', resize: 'none' }}
                                    name="pc_description"
                                    value={item.pc_description}
                                    onChange={(e) => handleChange(e, i)}
                                  ></textarea>
                                </td>
                              </tr>
                              <tr>
                                <th style={{ width: '20%' }}>Ko 슬라이드 Mobile 타이틀 *</th>
                                <td colSpan="3">
                                  <textarea
                                    rows="2"
                                    style={{ width: '80%', resize: 'none' }}
                                    name="mo_title"
                                    value={item.mo_title}
                                    onChange={(e) => handleChange(e, i)}
                                  ></textarea>
                                </td>
                              </tr>
                              <tr>
                                <th style={{ width: '20%' }}>Ko 슬라이드 Mobile 글 *</th>
                                <td colSpan="3">
                                  <textarea
                                    rows="5"
                                    style={{ width: '80%', resize: 'none' }}
                                    name="mo_description"
                                    value={item.mo_description}
                                    onChange={(e) => handleChange(e, i)}
                                  ></textarea>
                                </td>
                              </tr>
                              <tr>
                                <th style={{ width: '20%' }}>English 슬라이드 PC 타이틀 *</th>
                                <td colSpan="3">
                                  <textarea
                                    rows="2"
                                    style={{ width: '80%', resize: 'none' }}
                                    name="en_pc_title"
                                    value={item.en_pc_title}
                                    onChange={(e) => handleChange(e, i)}
                                  ></textarea>
                                </td>
                              </tr>
                              <tr>
                                <th style={{ width: '20%' }}>English 슬라이드 PC 글 *</th>
                                <td colSpan="3">
                                  <textarea
                                    rows="5"
                                    style={{ width: '80%', resize: 'none' }}
                                    name="en_pc_description"
                                    value={item.en_pc_description}
                                    onChange={(e) => handleChange(e, i)}
                                  ></textarea>
                                </td>
                              </tr>
                              <tr>
                                <th style={{ width: '20%' }}>English 슬라이드 Mobile 타이틀 *</th>
                                <td colSpan="3">
                                  <textarea
                                    rows="2"
                                    style={{ width: '80%', resize: 'none' }}
                                    name="en_mo_title"
                                    value={item.en_mo_title}
                                    onChange={(e) => handleChange(e, i)}
                                  ></textarea>
                                </td>
                              </tr>
                              <tr>
                                <th style={{ width: '20%' }}>English 슬라이드 Mobile 글 *</th>
                                <td colSpan="3">
                                  <textarea
                                    rows="5"
                                    style={{ width: '80%', resize: 'none' }}
                                    name="en_mo_description"
                                    value={item.en_mo_description}
                                    onChange={(e) => handleChange(e, i)}
                                  ></textarea>
                                </td>
                              </tr>
                              <tr>
                                <th style={{ width: '20%' }}>슬라이드 이미지 *</th>
                                <td colSpan="3">
                                  {!isLoading.slide[i] && !!item.image && (
                                    <div className="flex" style={{ flexDirection: 'column' }}>
                                      <>
                                        <button
                                          type="button"
                                          className="btn btn-warning"
                                          onClick={(e) => resetImage(i)}
                                        >
                                          제거
                                        </button>
                                        <br />
                                        <img
                                          src={process.env.REACT_APP_S3 + item.image}
                                          style={{ maxWidth: '20%', marginTop: '20px' }}
                                          alt={item.description}
                                        />
                                      </>
                                    </div>
                                  )}
                                  {isLoading.slide[i] && <div className="loader"></div>}
                                  {!isLoading.slide[i] && !item.image && (
                                    <label className="btn btn-primary btn-file">
                                      파일 선택{' '}
                                      <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        name="image"
                                        onChange={(e) => handleImage(e, i)}
                                      />
                                    </label>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th style={{ width: '20%' }}>슬라이드 순서</th>
                                <td colSpan="3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="slide_order"
                                    value={item.slide_order}
                                    onChange={(e) => handleChange(e, i)}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th style={{ width: '20%' }}>활성화 여부</th>
                                <td colSpan="3">
                                  <select
                                    className="example-radio-group form-control"
                                    name="is_active"
                                    onChange={(e) => handleChange(e, i)}
                                    value={item.is_active}
                                  >
                                    <option value={1}>활성화</option>
                                    <option value={0}>비활성화</option>
                                  </select>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className="box-footer">
              <div className="pull-right">
                {/* <button
                  // to="/admin/architects"
                  className="btn btn-default"
                  style={{ marginRight: '5px' }}
                  onClick={() => this.props.history.push('/admin/slides')}
                >
                  취소
                </button> */}
                <button className="btn btn-primary" onClick={handleSave}>
                  저장
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(SlideUpdate);
