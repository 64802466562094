export const productTypes = [
  'monomers',
  'special_monomers',
  'photo_initilator',
  'npe',
  'ope',
  'nfe',
  'lae',
  'emulsifying',
  'defoaming',
  'anti',
  'polishing',
  'plasticizer',
  'coating_resins',
  'epoxy_resins',
  'uv_curing_resins',
];

export const contents = {
  monomers: ['No', 'ITEM', 'CHEMICAL NAME', 'CAS No.', 'PACKAGE', 'ORIGIN', 'SOURCE'],
  special_monomers: ['No', 'ITEM', 'CHEMICAL NAME', 'CAS No.'],
  photo_initilator: [
    'No.',
    'JRcure NO.',
    'General NO.',
    'CHEMICAL NAME',
    'CAS No.',
    'APPEARANCE',
    'MELTING POINT [℃]',
    'ABSORPTION PEAK [nm]	',
    'PACKAGE',
  ],
  surfactants_monopol: [
    'No.',
    'Product name',
    'EO mol',
    'Appearance(at 25℃)',
    'HLB',
    'Cloud point(℃)',
    'pH',
    'Density(g/cm²)',
    'Application ',
  ],
  surfactants_agent: [
    'No.',
    'Product name',
    'Components',
    'Appearance(at 25℃)',
    'Ionic',
    'pH',
    'Active content',
    'Application',
    'Packing Unit',
  ],
  plasticizer: ['No.', 'ITEM', 'FEATURE', 'APPLICATION', 'PACKAGE', 'ORIGIN', 'SOURCE'],
  coating_resins: [
    'No.',
    'GRADE',
    'Tg',
    'Mw',
    'AV',
    'Methyl Acetate',
    'Ethyl Acetate',
    'Toulen',
    'Xylene',
    'Acetone',
    'MEK',
  ],
  epoxy_resins: [
    'No.',
    'NAME',
    'APPEARANCE',
    'EEW',
    'Hy-CL(ppm)',
    'T-CL(ppm)',
    'Softening point(℃)',
    'Viscosity(cps at 25℃)',
    'Features',
    'Application',
  ],
  uv_curing_resins: ['No.', 'PRODUCT', 'FEATURES', 'APPLICATION'],
};
