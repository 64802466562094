import React from 'react';
import App from 'components/App';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

const customHistory = createBrowserHistory();

const Root = () => {
  return (
    <Router history={customHistory}>
      <App />
    </Router>
  );
};

export default Root;
