import React from 'react';
import AdminLayout from 'components/admin/layout/AdminLayout';
import SlideUpdateContainer from 'containers/admin/SlideUpdateContainer';

const SlideUpdate = () => {
  return (
    <AdminLayout>
      <SlideUpdateContainer />
    </AdminLayout>
  );
};

export default SlideUpdate;
