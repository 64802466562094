import axios from 'axios';

export const login = async (auth) => {
  try {
    const result = await axios.post(`${process.env.REACT_APP_API}/login/`, {
      ...auth,
    });

    return result;
  } catch (e) {
    if (
      e &&
      e.response &&
      e.response.data &&
      e.response.data.msg &&
      e.response.data.msg === 'Email or password is incorrect!'
    ) {
      alert('정보가 일치하지 않습니다!');
    }
  }
};

export const signup = async (data) => {
  try {
    const result = await axios.post(`${process.env.REACT_APP_API}/user/`, {
      ...data,
    });

    return result;
  } catch (e) {}
};

export const emailVerify = async (email, code) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/user/email-verify/?email=${email}`,
      {
        code,
      },
    );

    return result;
  } catch (e) {}
};

export const resetPassword = async (email) => {
  try {
    const result = await axios.post(`${process.env.REACT_APP_API}/user/find-password/`, {
      email,
    });

    return result;
  } catch (e) {
    if (e.response.data.error === '이메일 인증이 필요합니다.') {
      alert('이메일 인증이 필요합니다.');
    }
  }
};

export const changePassword = async (password) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/user/change-password/`,
      {
        ...password,
      },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
    );

    return result;
  } catch (e) {}
};
