import React from 'react';
import AdminLayout from 'components/admin/layout/AdminLayout';
import ManagerListContainer from 'containers/admin/ManagerListContainer';

const ManagerList = () => {
  return (
    <AdminLayout>
      <ManagerListContainer />
    </AdminLayout>
  );
};

export default ManagerList;
