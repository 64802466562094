import React, { Component } from 'react';
import moment from 'moment';
import './ManagerList.css';
import { withRouter } from 'react-router-dom';
import Pagination from 'components/ui/Pagination';

class ManagerList extends Component {
  handleAdd = (id) => {
    this.props.history.push('/admin/managers/update');
  };

  handleShow = () => {
    const { managers, count } = this.props;

    let components = [];

    managers &&
      managers.map((el, i) => {
        components.push(
          <tr
            key={el.id}
            className="pointer"
            onClick={(e) => this.props.history.push(`/admin/managers/update/${el.id}`)}
          >
            <td className="text-center">{el.id}</td>
            <td style={{ width: '200px' }} className="text-center">
              {el.email}
            </td>
            <td style={{ width: '200px' }} className="text-center">
              {el.name || '-'}
            </td>
            <td style={{ width: '200px' }} className="text-center">
              {el.position || '-'}
            </td>
            <td style={{ width: '200px' }} className="text-center">
              {el.phone || '-'}
            </td>
            <td style={{ width: '200px' }} className="text-center">
              {el.is_active ? '활성화' : '비활성화'}
            </td>
            <td style={{ width: '200px' }} className="text-center">
              {moment(el.created_at).format('YYYY-MM-DD HH:mm:ss')}
            </td>
            <td style={{ width: '200px' }} className="text-center">
              {moment(el.created_at).format('YYYY-MM-DD HH:mm:ss') ===
              moment(el.last_login).format('YYYY-MM-DD HH:mm:ss')
                ? '-'
                : moment(el.last_login).format('YYYY-MM-DD HH:mm:ss')}
            </td>
          </tr>,
        );
      });

    if (managers && managers.length === 0) {
      components.push(
        <tr key={'no'}>
          <td colSpan="9" className="text-center no-data">
            등록된 관리자가 없습니다.
          </td>
        </tr>,
      );
    }

    return components;
  };

  render() {
    const { handleAdd, handleShow } = this;
    const { pagination, count, handlePagination } = this.props;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>관리자 관리</h1>
        </section>
        <section className="content">
          <div className="box box-primary">
            <div className="box-header">
              <h3 className="box-title">총 {count} 건</h3>
            </div>

            <div className="box-body table-responsive">
              <table className="table table-hover table-bordered ">
                <thead>
                  <tr>
                    <th className="text-center">NO.</th>
                    <th className="text-center">아이디</th>
                    <th className="text-center">이름</th>
                    <th className="text-center">직책</th>
                    <th className="text-center">연락처</th>
                    <th className="text-center">활성화 여부</th>
                    <th className="text-center">생성일</th>
                    <th className="text-center">마지막 접속일</th>
                  </tr>
                </thead>
                <tbody>{handleShow()}</tbody>
              </table>
              {count !== 0 && (
                <Pagination pagination={pagination} handlePagination={handlePagination} />
              )}
            </div>

            <div className="box-footer clearfix text-center" style={{ borderTop: '0px' }}>
              <div className="pull-right">
                <span
                  onClick={handleAdd}
                  className="btn btn-bitbucket pull-right"
                  style={{ marginRight: '5px' }}
                >
                  등록
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(ManagerList);
