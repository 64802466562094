import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import './AdminLnb.css';
import check from 'lib/check';

class AdminLnb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
    };
  }

  componentDidMount() {
    const path = this.props.history.location.pathname;
    this.handleMenu(path);
  }

  handleMenu = (path) => {
    let menu = {
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
    };

    switch (path) {
      case '/admin/managers':
        menu['menu1'] = true;
        break;
      case '/admin/slides':
        menu['menu2'] = true;
        break;
      case '/admin/popups':
        menu['menu3'] = true;
        break;
      case '/admin/pages':
        menu['menu4'] = true;
        break;
      case '/admin/products':
        menu['menu5'] = true;
        break;
      case '/admin/inquiries':
        menu['menu6'] = true;
        break;
      case '/admin/infos':
        menu['menu7'] = true;
        break;
      default:
        break;
    }

    this.setState({
      ...menu,
    });
  };

  handleClick = (link) => {
    // check.token(this.props.history);
    this.props.history.push(link);
  };

  render() {
    const { menu1, menu2, menu3, menu4, menu5, menu6, menu7 } = this.state;
    return (
      <aside className="main-sidebar" style={{ backgroundColor: '#121d30' }}>
        <section className="sidebar">
          <ul className="sidebar-menu">
            <li
              className={menu1 ? 'active' : ''}
              onClick={() => {
                this.handleClick('/admin/managers');
                this.handleMenu('/admin/managers');
              }}
            >
              <Link to="/admin/managers">
                <i className="fa fa-user"></i> <span>관리자 관리</span>
              </Link>
            </li>
            <li
              className={menu2 ? 'active' : ''}
              onClick={() => {
                this.handleClick('/admin/slides');
                this.handleMenu('/admin/slides');
              }}
            >
              <Link to="/admin/slides">
                <i className="fa fa-picture-o"></i> <span>슬라이드 관리</span>
              </Link>
            </li>
            <li
              className={menu3 ? 'active' : ''}
              onClick={() => {
                this.handleClick('/admin/popups');
                this.handleMenu('/admin/popups');
              }}
            >
              <Link to="/admin/popups">
                <i className="fa fa-window-maximize"></i> <span>팝업 관리</span>
              </Link>
            </li>
            <li
              className={menu4 ? 'active' : ''}
              onClick={() => {
                this.handleClick('/admin/pages');
                this.handleMenu('/admin/pages');
              }}
            >
              <Link to="/admin/pages">
                <i className="fa fa-television"></i> <span>페이지 이미지 관리</span>
              </Link>
            </li>
            <li
              className={menu5 ? 'active' : ''}
              onClick={() => {
                this.handleClick('/admin/products');
                this.handleMenu('/admin/products');
              }}
            >
              <Link to="/admin/products">
                <i className="fa fa-product-hunt"></i> <span>프러덕트 관리</span>
              </Link>
            </li>
            <li
              className={menu6 ? 'active' : ''}
              onClick={() => {
                this.handleClick('/admin/inquiries');
                this.handleMenu('/admin/inquiries');
              }}
            >
              <Link to="/admin/inquiries">
                <i className="fa fa-comment"></i> <span>고객 문의</span>
              </Link>
            </li>
            <li
              className={menu7 ? 'active' : ''}
              onClick={() => {
                this.handleClick('/admin/infos');
                this.handleMenu('/admin/infos');
              }}
            >
              <Link to="/admin/infos">
                <i className="fa fa-info"></i> <span>정보 관리</span>
              </Link>
            </li>
          </ul>
        </section>
      </aside>
    );
  }
}

export default withRouter(AdminLnb);
