import React, { Component } from 'react';
import EmailContainer from 'containers/EmailContainer';

class Email extends Component {
  render() {
    return <EmailContainer />;
  }
}

export default Email;
