import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import './InquiryUpdate.css';

class InquiryUpdate extends Component {
  render() {
    const {
      id,
      name,
      company,
      position,
      email,
      phone,
      content,
      created_at,
      is_checked,
      handleSave,
      handleChange,
      handleDelete,
    } = this.props;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>고객문의</h1>
        </section>
        <section className="content">
          <div className="box box-primary">
            <div className="box-body">
              <div
                className="form-group col-lg-12 col-md-12"
                style={{
                  border: '1px solid #808080',
                  marginTop: '20px',
                  padding: '20px',
                  borderRadius: '4px',
                }}
              >
                <table className="table table- light">
                  <thead>
                    <tr>
                      <th colSpan="4">문의 정보</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th style={{ width: '20%' }}>이름</th>
                      <td colSpan="3">
                        <input
                          readOnly
                          type="text"
                          className="form-control"
                          name="name"
                          value={name || ''}
                          // onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>회사</th>
                      <td colSpan="3">
                        <input
                          readOnly
                          type="text"
                          className="form-control"
                          name="company"
                          value={company || ''}
                          // onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>직책</th>
                      <td colSpan="3">
                        <input
                          readOnly
                          type="text"
                          className="form-control"
                          name="position"
                          value={position || ''}
                          // onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>이메일</th>
                      <td colSpan="3">
                        <input
                          readOnly
                          type="text"
                          className="form-control"
                          name="email"
                          value={email || ''}
                          // onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>연락처</th>
                      <td colSpan="3">
                        <input
                          readOnly
                          type="text"
                          className="form-control"
                          name="phone"
                          value={phone || ''}
                          // onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>문의내용</th>
                      <td colSpan="3">
                        <textarea
                          readOnly
                          rows="5"
                          style={{ width: '80%', resize: 'none' }}
                          name="content"
                          value={content || ''}
                          // onChange={handleChange}
                        ></textarea>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>보낸 일시</th>
                      <td colSpan="3">
                        <input
                          readOnly
                          type="text"
                          className="form-control"
                          name="created_at"
                          value={moment(created_at).format('YYYY-MM-DD HH:mm:ss') || ''}
                          // onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>관리자 확인여부</th>
                      <td colSpan="3">
                        <select
                          className="example-radio-group form-control"
                          name="is_checked"
                          onChange={handleChange}
                          value={is_checked || 0}
                        >
                          <option value={1}>확인</option>
                          <option value={0}>미확인</option>
                        </select>
                      </td>
                    </tr>
                    {/* <tr>
                        <th style={{ width: '20%' }}>
                          상세 이미지
                          <br />
                          <small>(928 x 856)</small>
                        </th>
                        <td colSpan="3">
                          <img style={{ maxWidth: '20%', float: 'left', marginRight: '20px' }} />
                          <label className="btn btn-primary btn-file">
                            파일 선택{' '}
                            <input
                              style={{ display: 'none' }}
                              type="file"
                              id="pic-upload"
                              className="upload-hidden"
                              name="pcImage"
                            />
                          </label>
                          <br />
                          <br />
                          <button type="button" className="btn btn-warning">
                            제거
                          </button>
                        </td>
                      </tr> */}
                    {/* <tr>
                        <th style={{ width: '20%' }}>대체 텍스트*</th>
                        <td colSpan="3">
                          <input type="text" style={{ width: '80%' }} name="alt" />
                        </td>
                      </tr> */}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="box-footer">
              <div className="pull-right">
                <button
                  // to="/admin/notices"
                  className="btn btn-default"
                  style={{ marginRight: '5px' }}
                  onClick={() => this.props.history.push('/admin/inquiries')}
                >
                  취소
                </button>
                <button className="btn btn-primary" onClick={handleSave}>
                  수정
                </button>
              </div>
              {id !== 0 && (
                <button
                  onClick={() => handleDelete(id)}
                  type="button"
                  className="btn btn-danger"
                  style={{ marginLeft: '5px' }}
                >
                  삭제
                </button>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(InquiryUpdate);
