import React from 'react';
import AdminLayout from 'components/admin/layout/AdminLayout';
import ManagerUpdateContainer from 'containers/admin/ManagerUpdateContainer';

const ManagerUpdate = () => {
  return (
    <AdminLayout>
      <ManagerUpdateContainer />
    </AdminLayout>
  );
};

export default ManagerUpdate;
