import React, { Component } from 'react';
import AdminHeaderContainer from 'containers/admin/AdminHeaderContainer';
import AdminLnbContainer from 'containers/admin/AdminLnbContainer';
import AdminFooterContainer from 'containers/admin/AdminFooterContainer';
import './AdminLayout.css';

class AdminLayout extends Component {
  render() {
    const { children } = this.props;
    return (
      <div className="skin-black">
        <AdminHeaderContainer />
        <AdminLnbContainer />
        <main>{children}</main>
        <AdminFooterContainer />
      </div>
    );
  }
}

export default AdminLayout;
