import React, { Component } from 'react';
import AuthContainer from 'containers/AuthContainer';
import ManagerUpdate from 'components/admin/manager/ManagerUpdate';
import { withRouter } from 'react-router-dom';
import { getManager, createManager, updateManager, deleteManager, resetManager } from 'api/manager';
import { passRule } from 'lib/common';

class ManagerUpdateContainer extends Component {
  state = {
    id: 0,
    email: '',
    password: '',
    confirm_passwrod: '',
    name: '',
    phone: '',
    company: '',
    department: '',
    role: 'Manager',
    is_active: 0,
    reset: false,
  };

  async componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      // this.getArchitect(id);
      const result = await getManager(id);
      if (result && result.status === 200) {
        this.setState({
          ...this.state,
          id,
          email: result.data.email,
          name: result.data.name || '',
          position: result.data.position || '',
          phone: result.data.phone || '',
          is_active: result.data.is_active || 0,
        });
      } else {
        this.props.history.push('/admin/managers');
      }
    }
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.name === 'is_active' ? Number(e.target.value) : e.target.value,
    });
  };

  handleSave = async () => {
    const { id, email, password, password_confirm, name, position, phone, is_active } = this.state;
    // 수정

    if (this.state.id) {
      const result = await updateManager(id, { name, position, phone, is_active });
      if (result && result.status === 200) {
        alert('수정 성공:)');
        this.props.history.push('/admin/managers');
      }
    } else {
      if (!email.trim()) return alert('이메일을 입력해주세요:)');
      if (!password) return alert('패스워드를 입력해주세요:)');
      if (!password_confirm) return alert('패스워드 확인을 입력해주세요:)');
      if (!passRule.test(password))
        return alert('패스워드 형식(영문, 숫자 이용 10자 이상)에 맞춰 작성 해주세요:)');
      if (password.length < 10) return alert('패스워드를 10자리 이상으로 입력해주세요:)');
      if (password !== password_confirm) return alert('입력하신 패스워드가 다릅니다:)');

      const result = await createManager({ id, email, password, name, position, phone, is_active });
      if (result && result.status === 201) {
        alert('등록 성공:)');
        this.props.history.push('/admin/managers');
      }
    }
  };

  handleDelete = async () => {
    const { id } = this.state;
    const result = await deleteManager(id);
    if (result && result.status === 200) {
      alert('삭제 성공:)');
      this.props.history.push('/admin/managers');
    }
  };

  handleReset = async () => {
    const { id, password } = this.state;
    if (!password) return alert('패스워드를 입력해주세요:)');
    if (!passRule.test(password))
      return alert('패스워드 형식(영문, 숫자 이용 10자 이상)에 맞춰 작성 해주세요:)');

    const result = await resetManager(id, password);
    if (result && result.status === 200) {
      alert('비밀번호 변경 성공:)');
      this.setState({
        ...this.state,
        password: '',
        reset: false,
      });
    }
  };

  openPasswordReset = () => {
    this.setState({
      ...this.state,
      reset: true,
    });
  };

  closePasswordReset = () => {
    this.setState({
      ...this.state,
      reset: false,
    });
  };

  render() {
    const {
      handleChange,
      handleSave,
      handleDelete,
      handleReset,
      openPasswordReset,
      closePasswordReset,
    } = this;

    return (
      <ManagerUpdate
        {...this.state}
        handleChange={handleChange}
        handleSave={handleSave}
        handleDelete={handleDelete}
        handleReset={handleReset}
        openPasswordReset={openPasswordReset}
        closePasswordReset={closePasswordReset}
      />
    );
  }
}

export default AuthContainer()(withRouter(ManagerUpdateContainer));
