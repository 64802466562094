import React, { PureComponent } from 'react';
import SlideArrow from 'components/ui/SlideArrow';
import Fade from 'react-reveal/Fade';
import { connect } from 'react-redux';
import { setLang } from 'data/actions';

class SliderItem extends PureComponent {
  render() {
    const { data, handlePrevious, handleNext, lang } = this.props;
    return (
      <div
        className="nd-main-bg "
        style={{ backgroundImage: `url(${process.env.REACT_APP_S3}${data.image})` }}
      >
        <div className="nd-main-container">
          <div className="nd-main-box">
            <div className="nd-main-top-box">
              <Fade bottom>
                <p className="nd-main-top-text pc-only">
                  {lang === 'ko' ? data.pc_title : data.en_pc_title}
                </p>
                <p className="nd-main-top-text mo-only">
                  {lang === 'ko' ? data.mo_title : data.en_mo_title}
                </p>
              </Fade>
            </div>
            <div className="nd-main-bottom-box">
              <Fade bottom>
                <h2 className="nd-main-bottom-text pc-only">
                  {lang === 'ko' ? data.pc_description : data.en_pc_description}
                </h2>
                <h2 className="nd-main-bottom-text mo-only">
                  {lang === 'ko' ? data.mo_description : data.en_mo_description}
                </h2>
              </Fade>
            </div>
          </div>
          <SlideArrow className="pc-only" direction="left" handleSlider={handlePrevious} />
          <SlideArrow className="pc-only" direction="right" handleSlider={handleNext} />
        </div>
        <Fade bottom>
          <div className="mouse-down-bg pc-only">
            <span className="mouse-down"></span>
            <span className="mouse-down-desc">SCROLL DOWN</span>
          </div>
        </Fade>
        <SlideArrow className="mo-only" direction="left" handleSlider={handlePrevious} />
        <SlideArrow className="mo-only" direction="right" handleSlider={handleNext} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.config.lang,
  }),
  { setLang },
)(SliderItem);
