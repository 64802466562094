import React, { Component } from 'react';
import PrivacyContainer from 'containers/PrivacyContainer';

class Privacy extends Component {
  render() {
    return <PrivacyContainer />;
  }
}

export default Privacy;
