import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import './Privacy.css';
import { connect } from 'react-redux';
import { setLang } from 'data/actions';

class Privacy extends Component {
  render() {
    const { pc_content, mo_content, en_pc_content, en_mo_content, lang } = this.props;
    return (
      <>
        <Fade bottom>
          <div class="nd-business-content">
            <div class="nd-business-content-container">
              <div class="nd-business-content-title-box">
                <h2 class="nd-business-content-title">
                  {lang === 'ko' ? '개인정보취급방침' : 'Privacy Statement'}
                </h2>
              </div>
              <div class="nd-business-content-text-box">
                <p class="nd-business-content-text pc-only">
                  {lang === 'ko' ? pc_content : en_pc_content}
                </p>
                <p class="nd-business-content-text mo-only">
                  {lang === 'ko' ? mo_content : en_mo_content}
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.config.lang,
  }),
  { setLang },
)(Privacy);
