import React from 'react';
import AdminLayout from 'components/admin/layout/AdminLayout';
import ArchitectListContainer from 'containers/admin/ArchitectListContainer';

const ArchitectList = () => {
  return (
    <AdminLayout>
      <ArchitectListContainer />
    </AdminLayout>
  );
};

export default ArchitectList;
