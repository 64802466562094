import React, { Component } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import './InquiryList.css';
import moment from 'moment';
import Pagination from 'components/ui/Pagination';

class InquiryList extends Component {
  showData = () => {
    const { handleClick } = this;
    const { inquiries } = this.props;
    let component = [];
    inquiries &&
      inquiries.map((el, i) => {
        component.push(
          <tr key={el.id} onClick={() => handleClick(el.id)}>
            <th className="text-center">{el.id}</th>
            <th className="text-center">{el.name || ''}</th>
            <th className="text-center">{el.company || ''}</th>
            <th className="text-center">{el.position || ''}</th>
            <th className="text-center">{el.email || ''}</th>
            <th className="text-center">{el.phone || ''}</th>
            <th className="text-center">{el.user_ip || ''}</th>
            <th className="text-center">{moment(el.created_at).format('YYYY-MM-DD HH:mm:ss')}</th>
            <th className="text-center">{el.is_checked == 1 ? 'Y' : 'N'}</th>
            <th className="text-center">{el.admin || '-'}</th>
            <th className="text-center">
              {moment(el.created_at).format('YYYY-MM-DD HH:mm:ss') ===
              moment(el.updated_at).format('YYYY-MM-DD HH:mm:ss')
                ? '-'
                : moment(el.updated_at).format('YYYY-MM-DD HH:mm:ss')}
            </th>
          </tr>,
        );
      });

    if (inquiries && inquiries.length === 0) {
      component.push(
        <tr key={'no'}>
          <td colSpan="11" className="text-center no-data">
            문의가 없습니다.
          </td>
        </tr>,
      );
    }

    return component;
  };

  handleClick = (id) => {
    this.props.history.push(`/admin/inquiries/update/${id}`);
  };

  handleAdd = () => {
    this.props.history.push('/admin/inquiries/update');
  };

  render() {
    const { handleAdd, showData } = this;
    const { pagination, count, handlePagination } = this.props;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>고객 문의</h1>
        </section>
        <section className="content">
          <div className="box box-primary">
            <div className="box-header">
              <h3 className="box-title">총 {count} 건</h3>
            </div>

            <div className="box-body table-responsive">
              <table className="table table-hover table-bordered ">
                <thead>
                  <tr>
                    <th className="text-center">ID</th>
                    <th className="text-center">이름</th>
                    <th className="text-center">회사</th>
                    <th className="text-center">직책</th>
                    <th className="text-center">이메일</th>
                    <th className="text-center">연락처</th>
                    <th className="text-center">유저 아이피</th>
                    <th className="text-center">보낸 일시</th>
                    <th className="text-center">관리자 확인여부</th>
                    <th className="text-center">관리자</th>
                    <th className="text-center">관리자 수정일</th>
                    {/* <th className="text-center">최종 등록자</th> */}
                  </tr>
                </thead>
                <tbody>
                  {showData()}
                  {/* <tr>
                    <td className="text-center">{'ttt'}</td>
                    <td className="text-center">{'ttt'}</td>
                    <td>{'ttt'}</td>
                    <td className="text-center">{'ttt'}</td>
                    <td className="text-center">{'ttt'}</td>
                    <td className="text-center">{'ttt'}</td>
                    <td>{'ttt'}</td>
                  </tr> */}
                </tbody>
              </table>
              {count !== 0 && (
                <Pagination pagination={pagination} handlePagination={handlePagination} />
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(InquiryList);
