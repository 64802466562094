import React from 'react';
import AdminLayout from 'components/admin/layout/AdminLayout';
import PopupUpdateContainer from 'containers/admin/PopupUpdateContainer';

const PopupUpdate = () => {
  return (
    <AdminLayout>
      <PopupUpdateContainer />
    </AdminLayout>
  );
};

export default PopupUpdate;
