import axios from 'axios';
import storage from 'lib/storage';

const token = storage.get('token');

export const getPopup = async () => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_API}/popups/`);

    return result;
  } catch (e) {}
};

export const getPopupActive = async () => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_API}/popups/active`);

    return result;
  } catch (e) {}
};

export const createPopup = async (popup) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/popups`,
      {
        ...popup,
      },
      {
        headers: { Authorization: 'Bearer ' + token },
      },
    );

    return result;
  } catch (e) {}
};

export const updatePopup = async (id, popup) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/popups/${id}`,
      {
        ...popup,
      },
      {
        headers: { Authorization: 'Bearer ' + token },
      },
    );

    return result;
  } catch (e) {}
};
