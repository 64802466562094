import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class PageUpdate extends Component {
  render() {
    const {
      id,
      title,
      en_title,
      image,
      type,
      isLoading,
      handleChange,
      handleImage,
      handleType,
      resetImage,
      handleSave,
    } = this.props;

    const menus = {
      about: 'About',
      business_introduce: 'Business 제품소개',
      business_inquiry: 'Business 제품 및 개발문의',
      contact: 'Contact',
      privacy: '개인정보처리방침',
      email: '이메일수집금지',
    };

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>페이지 이미지 관리</h1>
          <div className="architect-button-list">
            <button
              className={type === 'about' ? 'active' : ''}
              onClick={(e) => handleType('about')}
            >
              About
            </button>
            <button
              className={type === 'business_introduce' ? 'active' : ''}
              onClick={(e) => handleType('business_introduce')}
            >
              Business 제품소개
            </button>
            <button
              className={type === 'business_inquiry' ? 'active' : ''}
              onClick={(e) => handleType('business_inquiry')}
            >
              Business 제품 및 개발문의
            </button>
            <button
              className={type === 'contact' ? 'active' : ''}
              onClick={(e) => handleType('contact')}
            >
              Contact
            </button>
            <button
              className={type === 'privacy' ? 'active' : ''}
              onClick={(e) => handleType('privacy')}
            >
              개인정보처리방침
            </button>
            <button
              className={type === 'email' ? 'active' : ''}
              onClick={(e) => handleType('email')}
            >
              이메일수집금지
            </button>
          </div>
        </section>
        <section className="content">
          <div className="box box-primary">
            <div className="box-body">
              <div
                className="form-group col-lg-12 col-md-12"
                style={{
                  border: '1px solid #808080',
                  marginTop: '20px',
                  padding: '20px',
                  borderRadius: '4px',
                }}
              >
                <table className="table table- light">
                  <thead>
                    <tr>
                      <th colSpan="4">{menus[type] || ''} 페이지 이미지 정보</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th style={{ width: '20%' }}>Ko 타이틀 *</th>
                      <td colSpan="3">
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          value={title}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>English 타이틀 *</th>
                      <td colSpan="3">
                        <input
                          type="text"
                          className="form-control"
                          name="en_title"
                          value={en_title}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>이미지 *</th>
                      <td>
                        {!isLoading.image && !!image && (
                          <div className="flex" style={{ flexDirection: 'column' }}>
                            <>
                              <button
                                type="button"
                                className="btn btn-warning"
                                onClick={(e) => resetImage('image')}
                              >
                                제거
                              </button>
                              <br />
                              <img
                                src={process.env.REACT_APP_S3 + image}
                                style={{ maxWidth: '20%', marginTop: '20px' }}
                                alt={title}
                              />
                            </>
                          </div>
                        )}
                        {isLoading.image && <div className="loader"></div>}
                        {!isLoading.image && !image && (
                          <label className="btn btn-primary btn-file">
                            파일 선택{' '}
                            <input
                              type="file"
                              style={{ display: 'none' }}
                              name="image"
                              onChange={(e) => handleImage(e, 'image')}
                            />
                          </label>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="box-footer">
              <div className="pull-right">
                <button
                  // to="/admin/architects"
                  className="btn btn-default"
                  style={{ marginRight: '5px' }}
                  onClick={() => this.props.history.push('/admin/pages')}
                >
                  취소
                </button>
                <button className="btn btn-primary" onClick={handleSave}>
                  {id === 0 ? '등록' : '수정'}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(PageUpdate);
