import React from 'react';
import AdminLayout from 'components/admin/layout/AdminLayout';
import InquiryListContainer from 'containers/admin/InquiryListContainer';

const InquiryList = () => {
  return (
    <AdminLayout>
      <InquiryListContainer />
    </AdminLayout>
  );
};

export default InquiryList;
