import React, { Component } from 'react';
import AuthContainer from 'containers/AuthContainer';
import InquiryUpdate from 'components/admin/inquiries/InquiryUpdate';
import { withRouter } from 'react-router-dom';
import { getInquiry, createInquiry, updateInquiry, deleteInquiry } from 'api/inquiry';

class InquiryUpdateContainer extends Component {
  state = {
    id: 0,
    name: '',
    company: '',
    position: '',
    email: '',
    phone: '',
    content: '',
    created_at: '',
    is_checked: 0,
  };

  async componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      // this.getArchitect(id);
      const result = await getInquiry(id);
      if (result && result.status === 200) {
        this.setState({
          ...this.state,
          id,
          name: result.data.name || '',
          company: result.data.company || '',
          position: result.data.position || '',
          email: result.data.email,
          phone: result.data.phone || '',
          content: result.data.content || '',
          created_at: result.data.created_at || '',
          is_checked: result.data.is_checked || 0,
        });
      } else {
        this.props.history.push('/admin/managers');
      }
    }
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.name === 'is_active' ? Number(e.target.value) : e.target.value,
    });
  };

  handleSave = async () => {
    const { id, name, company, position, email, phone, content, is_checked } = this.state;
    // 수정

    if (this.state.id) {
      const result = await updateInquiry(id, is_checked);
      if (result && result.status === 200) {
        alert('수정 성공:)');
        this.props.history.push('/admin/inquiries');
      }
    } else {
      const result = await createInquiry({ name, company, position, email, phone, content });
      if (result && result.status === 200) {
        alert('등록 성공:)');
        this.props.history.push('/admin/inquiries');
      }
    }
  };

  handleDelete = async () => {
    const { id } = this.state;
    const result = await deleteInquiry(id);
    if (result && result.status === 200) {
      alert('삭제 성공:)');
      this.props.history.push('/admin/inquiries');
    }
  };

  render() {
    return <InquiryUpdate {...this.state} {...this} />;
  }
}

export default AuthContainer()(withRouter(InquiryUpdateContainer));
