import React, { Component } from 'react';
import AdminLnb from 'components/admin/layout/AdminLnb';

class AdminLnbContainer extends Component {
  render() {
    return <AdminLnb />;
  }
}

export default AdminLnbContainer;
