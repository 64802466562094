import React, { Component } from 'react';
import Map from 'components/ui/Map';

class InfoUpdate extends Component {
  render() {
    const {
      contact,
      agree,
      privacy,
      email,
      type,
      handleChange,
      handleType,
      handleSave,
    } = this.props;

    const pages = {
      contact: 'Contact',
      agree: '제품문의 - 개인정보수집 및 이용동의',
      privacy: '개인정보처리방침',
      email: '이메일무단수집거부',
    };

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>정보 관리</h1>
          <div className="architect-button-list">
            <button
              className={type === 'contact' ? 'active' : ''}
              onClick={(e) => handleType('contact')}
            >
              Contact
            </button>
            <button
              className={type === 'agree' ? 'active' : ''}
              onClick={(e) => handleType('agree')}
            >
              제품문의 - 개인정보수집 및 이용동의
            </button>
            <button
              className={type === 'privacy' ? 'active' : ''}
              onClick={(e) => handleType('privacy')}
            >
              개인정보처리방침
            </button>
            <button
              className={type === 'email' ? 'active' : ''}
              onClick={(e) => handleType('email')}
            >
              이메일무단수집거부
            </button>
          </div>
        </section>
        <section className="content">
          <div className="box box-primary">
            <div className="box-body">
              <div
                className="form-group col-lg-12 col-md-12"
                style={{
                  border: '1px solid #808080',
                  marginTop: '20px',
                  padding: '20px',
                  borderRadius: '4px',
                }}
              >
                <table className="table table- light">
                  <thead>
                    <tr>
                      <th colSpan="4">{pages[type]} 정보</th>
                    </tr>
                  </thead>
                  {type === 'contact' ? (
                    <>
                      <tbody>
                        <tr>
                          <th style={{ width: '20%' }}>Ko 회사명 *</th>
                          <td colSpan="3">
                            <input
                              type="text"
                              className="form-control"
                              name="company"
                              value={contact.company}
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: '20%' }}>Ko 주소 *</th>
                          <td colSpan="3">
                            <input
                              type="text"
                              className="form-control"
                              name="address"
                              value={contact.address}
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: '20%' }}>English 회사명 *</th>
                          <td colSpan="3">
                            <input
                              type="text"
                              className="form-control"
                              name="en_company"
                              value={contact.en_company}
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: '20%' }}>English 주소 *</th>
                          <td colSpan="3">
                            <input
                              type="text"
                              className="form-control"
                              name="en_address"
                              value={contact.en_address}
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: '20%' }}>지도 *</th>
                          <td colSpan="3">
                            <input
                              type="text"
                              className="form-control"
                              name="map"
                              value={contact.map}
                              onChange={handleChange}
                            />
                            <br />
                            {contact.map && <Map map={contact.map} />}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: '20%' }}>연락처 *</th>
                          <td colSpan="3">
                            <input
                              type="text"
                              className="form-control"
                              name="contact_number"
                              value={contact.contact_number}
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: '20%' }}>이메일 *</th>
                          <td colSpan="3">
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              value={contact.email}
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </>
                  ) : (
                    <tbody>
                      <tr>
                        <th style={{ width: '20%' }}>Ko PC 내용 *</th>
                        <td colSpan="3">
                          <textarea
                            rows="20"
                            style={{ width: '80%', resize: 'none' }}
                            name="pc_content"
                            value={
                              (type === 'agree'
                                ? agree.pc_content
                                : type === 'privacy'
                                ? privacy.pc_content
                                : email.pc_content) || ''
                            }
                            onChange={handleChange}
                          ></textarea>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: '20%' }}>Ko MOBILE 내용 *</th>
                        <td colSpan="3">
                          <textarea
                            rows="20"
                            style={{ width: '80%', resize: 'none' }}
                            name="mo_content"
                            value={
                              (type === 'agree'
                                ? agree.mo_content
                                : type === 'privacy'
                                ? privacy.mo_content
                                : email.mo_content) || ''
                            }
                            onChange={handleChange}
                          ></textarea>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: '20%' }}>English PC 내용 *</th>
                        <td colSpan="3">
                          <textarea
                            rows="20"
                            style={{ width: '80%', resize: 'none' }}
                            name="en_pc_content"
                            value={
                              (type === 'agree'
                                ? agree.en_pc_content
                                : type === 'privacy'
                                ? privacy.en_pc_content
                                : email.en_pc_content) || ''
                            }
                            onChange={handleChange}
                          ></textarea>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: '20%' }}>English MOBILE 내용 *</th>
                        <td colSpan="3">
                          <textarea
                            rows="20"
                            style={{ width: '80%', resize: 'none' }}
                            name="en_mo_content"
                            value={
                              (type === 'agree'
                                ? agree.en_mo_content
                                : type === 'privacy'
                                ? privacy.en_mo_content
                                : email.en_mo_content) || ''
                            }
                            onChange={handleChange}
                          ></textarea>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>

            <div className="box-footer">
              <div className="pull-right">
                <button className="btn btn-primary" onClick={handleSave}>
                  {this.props[type].id === 0 ? '등록' : '수정'}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default InfoUpdate;
