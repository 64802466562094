import React, { PureComponent } from 'react';
import './SlideArrow.css';

class SlideArrow extends PureComponent {
  render() {
    const { className, direction, handleSlider } = this.props;

    return (
      <button
        {...this.props}
        className={'slide-arrow ' + direction + ' ' + className}
        onClick={handleSlider}
      >
        <img src={`/images/arrow-${direction}.svg`} alt="" onClick={handleSlider && handleSlider} />
      </button>
    );
  }
}

export default SlideArrow;
