import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import AdminHeader from 'components/admin/layout/AdminHeader';
import storage from 'lib/storage';

class AdminHeaderContainer extends Component {
  handleLogout = async () => {
    storage.remove('loggedInfo');
    return <Redirect to="/admin" />;
  };

  render() {
    return <AdminHeader />;
  }
}

export default AdminHeaderContainer;
