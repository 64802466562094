export const passRule = /(?=.*\d)(?=.*[a-z]).{10,}$/;

export const setCookie = (c_name, value, exdays) => {
  var exdate = new Date();

  exdate.setDate(exdate.getDate() + exdays);
  // array 0 = PC 이미지 & array 1 = MOBILE 이미지
  var dummy = value.pc_image + '||' + value.mo_image;

  // var c_value=escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());
  var c_value = escape(dummy) + (exdays == null ? '' : '; expires=' + exdate.toUTCString());

  document.cookie = c_name + '=' + c_value;
};

export const getCookie = (c_name) => {
  let i,
    x,
    y,
    ARRcookies = document.cookie.split(';');

  for (i = 0; i < ARRcookies.length; i++) {
    x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='));

    y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1);

    x = x.replace(/^\s+|\s+$/g, '');
    if (x == c_name) {
      return unescape(y).split('||');
    }
  }
};

export const getLang = () => {
  const lang = !!localStorage['lang']
    ? localStorage['lang']
    : navigator.language || navigator.userLanguage;

  return lang;
};
