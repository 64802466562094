import React, { Component } from 'react';
import AboutContainer from 'containers/AboutContainer';

class About extends Component {
  render() {
    return <AboutContainer />;
  }
}

export default About;
