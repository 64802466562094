import React, { Component } from 'react';
import ContactContainer from 'containers/ContactContainer';

class Contact extends Component {
  render() {
    return <ContactContainer />;
  }
}

export default Contact;
