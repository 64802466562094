import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import './About.css';
import { connect } from 'react-redux';
import { setLang } from 'data/actions';

class About extends Component {
  render() {
    const { lang } = this.props;

    return (
      <>
        <div className="nd-about-content">
          <div className="nd-about-content-container">
            <div className="nd-about-content-box">
              <div className="nd-about-content-title-box">
                <Fade bottom>
                  <h2 className="nd-about-content-title">Evolving chemical company</h2>
                </Fade>
              </div>
              <div className="nd-about-content-sub-title-box">
                <Fade bottom>
                  <p className="nd-about-content-sub-title">
                    {lang === 'ko'
                      ? '끊임없이 진화하는 화학유통기업'
                      : 'Chemical distribution company constantly evolving'}
                  </p>
                </Fade>
              </div>
              <div className="nd-about-content-text-box">
                <Fade bottom>
                  <p className="nd-about-content-text">
                    {lang === 'ko'
                      ? '우리는 빠르게 변화하는 CHEMICAL INDUSTRY 속에서 '
                      : 'We are constantly evolving '}
                    <br className="nd-mobile-br" />
                    {lang === 'ko'
                      ? '끊임없이 진화합니다.'
                      : 'in the rapidly changing CHEMICAL INDUSTRY.'}
                    <br />
                    {lang === 'ko'
                      ? '고객이 필요로 하는 화학제품에 대한 불편함을 인지하고 '
                      : 'Recognizing the inconvenience of the chemical products that customers need, '}
                    <br />
                    {lang === 'ko'
                      ? '이를 해결하는 것이 저희의 목표입니다. '
                      : 'It is our goal to address this. '}
                    <br className="nd-mobile-br" />
                    {lang === 'ko'
                      ? '이를 위해 저희 구성원은 다양한 시도를 하며, '
                      : 'To this end, our members make various attempts, '}
                    <br />
                    {lang === 'ko'
                      ? '그 과정에서 발생한 실패의 가치를 소중히 여기고, '
                      : 'Cherishing the value of failures in the process '}
                    <br className="nd-mobile-br" />
                    {lang === 'ko' ? '함께 성장하는 것, ' : 'and growing together, '}
                    <br className="nd-pc-br" />
                    {lang === 'ko'
                      ? '이것이 케이씨모노머가 끊임없이 '
                      : 'This is the mindset that '}
                    <br className="nd-mobile-br" />
                    {lang === 'ko'
                      ? '진화하고자 하는 마음가짐입니다. '
                      : 'KC Monomer is constantly evolving. '}
                  </p>
                </Fade>
              </div>
              <div className="nd-about-content-bg">
                <Fade bottom>
                  <img className="nd-about-content-bg-img" src="/images/about-content.png" alt="" />
                </Fade>
              </div>
              <div className="nd-about-content-title-box">
                <Fade bottom>
                  <h2 className="nd-about-content-title">Chemical needs solution</h2>
                </Fade>
              </div>
              <div className="nd-about-content-text-box">
                <Fade bottom>
                  <p className="nd-about-content-text">
                    {lang === 'ko'
                      ? '우리는 고객들의 불편함을 인식하고, 그에 대한 솔루션을 제안합니다. '
                      : 'We recognize the inconvenience of our customers and propose solutions to them. '}
                    <br />
                    {lang === 'ko'
                      ? '고객들의 더 나은 가치창출을 위해 더 효율적이고 '
                      : 'In order to respond more efficiently and stably '}
                    <br className="nd-mobile-br" />
                    {lang === 'ko'
                      ? '안정적이게 대응할 수 있도록 '
                      : 'for the creation of better value for customers. '}
                    <br />
                    {lang === 'ko'
                      ? '끊임없이 고민합니다. 우리는 고객과 함께 발전하고 있습니다. '
                      : 'I constantly think that We are developing together with our customers. '}
                  </p>
                </Fade>
              </div>
            </div>
            <div className="nd-about-we-box">
              <div className="nd-about-we-title-box">
                <Fade bottom>
                  <h2 className="nd-about-we-title">
                    {lang === 'ko' ? '우리가 추구하는 3가지 ' : '3 things we pursue '}
                  </h2>
                </Fade>
              </div>
              <div className="nd-about-we-item">
                <Fade bottom>
                  <img className="nd-about-we-item-bg" src="/images/about-cycle.png" alt="" />
                </Fade>
              </div>
            </div>
            <div className="nd-about-content-box">
              <div className="nd-about-content-title-box">
                <Fade bottom>
                  <h2 className="nd-about-content-title">Evolving chemical company</h2>
                </Fade>
              </div>
            </div>
            <div className="nd-about-vlaue">
              <div className="nd-about-vlaue-box">
                <Fade bottom>
                  <div className="nd-about-vlaue-title-box">
                    <h2 className="nd-about-vlaue-title">
                      {lang === 'ko' ? '고객과 같이 ' : 'With customers '}
                    </h2>
                  </div>
                  <div className="nd-about-value-text-box">
                    <p className="nd-about-value-text">
                      {lang === 'ko'
                        ? '우리의 고객들에게 제품을 공급하는 뿐만 아니라, '
                        : 'We are contemplating how our customers will develop '}
                      <br />
                      {lang === 'ko'
                        ? '고객이 우리를 통해 어떻게 더 나은 방향으로 '
                        : 'in a better direction through us. '}
                      <br className="nd-mobile-br" />
                      {lang === 'ko'
                        ? '발전할지 고민하고 있습니다. '
                        : 'We want to create value that '}
                      <br />
                      {lang === 'ko'
                        ? '고객이 있어야 우리도 있듯이 함께 성장할 수 있는 가치를 '
                        : 'We want to create value '}
                      <br className="nd-mobile-br" />{' '}
                      {lang === 'ko' ? '만들고자' : 'can grow together as we do '}
                      <br className="nd-pc-br" />
                      {lang === 'ko'
                        ? '노력하겠습니다.'
                        : "only with customers. We'll try my best."}
                    </p>
                  </div>
                </Fade>
              </div>
              <div className="nd-about-vlaue-box">
                <Fade bottom>
                  <div className="nd-about-vlaue-title-box">
                    <h2 className="nd-about-vlaue-title">
                      {lang === 'ko' ? '직원과 같이' : 'With employees'}
                    </h2>
                  </div>
                  <div className="nd-about-value-text-box">
                    <p className="nd-about-value-text">
                      {lang === 'ko'
                        ? '회사는 이익을 위한 집단이지만, 개개인이 모여 만듭니다. '
                        : 'A company is a group for profit, but it is made up of individuals. '}
                      <br />
                      {lang === 'ko'
                        ? '사람은 회사의 가장 중요한 자산이며, 개인의 성장은 곧 '
                        : "People are the company's most important asset, and personal growth is "}
                      <br className="nd-mobile-br" />
                      {lang === 'ko' ? '회사의 성장입니다. ' : "the company's growth. "}
                      <br className="nd-pc-br" />
                      {lang === 'ko'
                        ? '개인의 삶의 만족도를 높일 수 있도록, '
                        : 'In order to increase personal life satisfaction, the company '}
                      <br className="nd-mobile-br" />
                      {lang === 'ko' ? '회사는 파트너로서 개인과의 ' : ''}
                      <br className="nd-pc-br" />
                      {lang === 'ko'
                        ? '동반 성장을 위해 노력하겠습니다. '
                        : 'We will strive for shared growth. '}
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.config.lang,
  }),
  { setLang },
)(About);
