import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import './ArchitectList.css';
import storage from 'lib/storage';
import moment from 'moment';
import check from 'lib/check';

class ArchitecList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: {
        all: true,
        'normal-1': false,
        'normal-2': false,
        'normal-3': false,
        'normal-4': false,
        'normal-5': false,
        'special-1': false,
        'special-2': false,
        'special-3': false,
        'subject-1': false,
        'subject-2': false,
        'subject-3': false,
        'subject-4': false,
      },
    };
  }

  handleCategory = (category) => {
    let init = {
      all: false,
      'normal-1': false,
      'normal-2': false,
      'normal-3': false,
      'normal-4': false,
      'normal-5': false,
      'special-1': false,
      'special-2': false,
      'special-3': false,
      'subject-1': false,
      'subject-2': false,
      'subject-3': false,
      'subject-4': false,
    };

    init[category] = true;
    this.setState({
      ...this.state,
      category: init,
    });
  };

  componentWillMount() {
    // check.token(this.props.history);
  }

  componentDidMount() {
    const { ArchitectActions, architects } = this.props;
    // ArchitectActions.getArchitects();
  }

  onSearch = () => {};

  showData = () => {
    const { handleClick } = this;
    const { architects } = this.props;
    const { category } = this.state;
    // /normal/1     => 대한민국 건축대전 국제일반공모전
    // /normal/2     => 올해의 건축가 100인 국제전
    // /normal/3     => 한국건축가협회 건축상
    // /normal/4     => 한국건축가협회 특별상
    // /normal/5     => 한국건축가협회 KIA Gold Medal

    // /special/1     => 대한민국공간문화대상
    // /special/2     => 젊은건축가상
    // /special/3     => 지역건축가전

    // /subject/1.      => 건축주: People
    // /subject/2.     => 건축주: Livability
    // /subject/3.     => 건축주: Boundary
    // /subject/4      => 경남의 전통건축
    const categories = {
      'normal-1': '일반전 - 대한민국 건축대전 국제일반공모전',
      'normal-2': '일반전 - 올해의 건축가 100인 국제전',
      'normal-3': '일반전 - 한국건축가협회 건축상',
      'normal-4': '일반전 - 한국건축가협회 특별상',
      'normal-5': '일반전 - 한국건축가협회 KIA GOLD MEDAL',
      'special-1': '특별전 - 대한민국공간문화대상',
      'special-2': '특별전 - 젊은건축가상',
      'special-3': '특별전 - 지역건축가전',
      'subject-1': '주제전 - 건축주: People',
      'subject-2': '주제전 - 건축주: Livability',
      'subject-3': '주제전 - 건축주: Boundary',
      'subject-4': '주제전 - 경남의 전통건축',
      special: '특별전 - 재지정필요',
      normal: '일반전 - 재지정필요',
      subject: '주제전 - 재지정필요',
    };
    let component = [];
    if (!!category.all) {
      // architects &&
      //   architects.toJS().map((el, i) => {
      //     component.push(
      //       <tr key={i} onClick={() => handleClick(el.id)}>
      //         <td className="text-center">{el.id}</td>
      //         <td className="text-center">{el.priority || '-'}</td>
      //         <td className="text-center">{categories[el.category] || '카테고리 지정 필요'}</td>
      //         <td className="text-center">{el.title}</td>
      //         <td className="text-center">{el.author}</td>
      //         <td className="text-center">{el.company}</td>
      //         <td className="text-center">{el.evaluation}</td>
      //         <td className="text-center">{el.is_active == 1 ? 'Y' : 'N'}</td>
      //         <td className="text-center">{moment(el.created_at).format('YYYY-MM-DD')}</td>
      //         <td className="text-center">{moment(el.updated_at).format('YYYY-MM-DD')}</td>
      //       </tr>,
      //     );
      //   });
    } else {
      // architects &&
      //   architects.toJS().map((el, i) => {
      //     if (category[el.category]) {
      //       component.push(
      //         <tr key={i} onClick={() => handleClick(el.id)}>
      //           <td className="text-center">{el.id}</td>
      //           <td className="text-center">{el.priority || '-'}</td>
      //           <td className="text-center">{categories[el.category] || '카테고리 지정 필요'}</td>
      //           <td className="text-center">{el.title}</td>
      //           <td className="text-center">{el.author}</td>
      //           <td className="text-center">{el.company}</td>
      //           <td className="text-center">{el.evaluation}</td>
      //           <td className="text-center">{el.is_active == 1 ? 'Y' : 'N'}</td>
      //           <td className="text-center">{moment(el.created_at).format('YYYY-MM-DD')}</td>
      //           <td className="text-center">{moment(el.updated_at).format('YYYY-MM-DD')}</td>
      //         </tr>,
      //       );
      //     }
      //   });
    }

    return component;
  };

  handleClick = (id) => {
    this.props.history.push(`/admin/architects/update/${id}`);
  };

  handleAdd = () => {
    this.props.history.push('/admin/architects/update');
  };

  render() {
    const { handleAdd, showData, handleCategory } = this;
    const { category } = this.state;
    const { architects } = this.props;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>작품 관리</h1>
        </section>
        <section className="content">
          {/* <div className="box box-default search_container">
            <div className="box-header with-border">
              <div className="pull-left ">
                <button
                  className="btn btn-primary btn-sm pull-left"
                  data-widget="collapse"
                  style={{ marginRight: '5px' }}
                >
                  <i className="fa fa-minus search_toggle"></i>
                </button>
                <h3 className="box-title" style={{ paddingTop: '5px' }}>
                  검색
                </h3>
              </div>
            </div>
            <form className="form-horizontal" onSubmit={onSearch}>
              <div className="box-body">
                <div className="form-group">
                  <label
                    for="bsRangeValue"
                    className="col-md-2 control-label"
                    style={{ textAlign: 'center' }}
                  >
                    기간
                  </label>
                  <div className="col-md-3 form-control-static">
                    <div className="input-group" style={{ width: '80%' }}>
                      <div className="input-group-addon" (click)="drp.toggle()">
                                    <i className="fa fa-calendar"></i>
                                </div>
                                <input className="form-control" readonly #drp="bsDaterangepicker" bsDaterangepicker [(ngModel)]="bsRangeValue" name="bsRangeValue" placeholder="미입력시 전체기간" />
                                <div className="input-group-btn">
                                    <button type="button" className="btn btn-danger" (click)="bsRangeValue = []"><i className="fa fa-close"></i></button>
                                </div>
                    </div>
                  </div>
                  <label
                    for="display"
                    className="col-md-2 control-label"
                    style={{ textAlign: 'center' }}
                  >
                    노출여부
                  </label>
                  <div className="col-md-2 form-control-static">
                    <select className="form-control" name="display" style={{ width: '80%' }}>
                      <option value="-1">전체</option>
                      <option value="1">노출</option>
                      <option value="0">미노출</option>
                    </select>
                  </div>
                </div>

                <div className="form-group">
                  <label
                    for="authority"
                    className="col-md-2 control-label"
                    style={{ textAlign: 'center' }}
                  >
                    카테고리
                  </label>
                  <div className="col-md-3 form-control-static">
                    <select className="form-control" name="type" style={{ width: '50%' }}>
                      <option value="dd">dd</option>
                    </select>
                  </div>

                  <label
                    for="keyword"
                    className="col-md-2 control-label"
                    style={{ textAlign: 'center' }}
                  >
                    검색어
                  </label>
                  <div className="col-md-2 form-control-static">
                    <select className="form-control" name="keywordType" style={{ width: '80%' }}>
                      <option value="key">key</option>
                    </select>
                  </div>
                  <div className="col-md-3 form-control-static">
                    <input
                      className="form-control"
                      type="text"
                      id="keyword"
                      name="keyword"
                      placeholder="검색어를 입력하세요."
                    />
                  </div>
                </div>
              </div>
              <div className="box-footer text-center">
                <button type="submit" className="btn btn-primary">
                  검색 <i className="fa fa-arrow-circle-right"></i>
                </button>
              </div>
            </form>
          </div> */}

          <div className="box box-primary">
            <div className="box-header">
              <h3 className="box-title">
                총{' '}
                {
                  // architects.toJS().filter((el) => {
                  //   if (category.all) {
                  //     return true;
                  //   } else {
                  //     return category[el.category];
                  //   }
                  // }).length
                }{' '}
                건
              </h3>
            </div>

            <div className="architect-button-list">
              <button
                className={category.all ? 'active' : ''}
                onClick={() => handleCategory('all')}
              >
                전체
              </button>
              <button
                className={category['normal-1'] ? 'active' : ''}
                onClick={() => handleCategory('normal-1')}
              >
                일반전 - 대한민국 건축대전 국제일반공모전
              </button>
              <button
                className={category['normal-2'] ? 'active' : ''}
                onClick={() => handleCategory('normal-2')}
              >
                일반전 - 올해의 건축가 100인 국제전
              </button>
              <button
                className={category['normal-3'] ? 'active' : ''}
                onClick={() => handleCategory('normal-3')}
              >
                일반전 - 한국건축가협회 건축상
              </button>
              <button
                className={category['normal-4'] ? 'active' : ''}
                onClick={() => handleCategory('normal-4')}
              >
                일반전 - 한국건축가협회 특별상
              </button>
              <button
                className={category['normal-5'] ? 'active' : ''}
                onClick={() => handleCategory('normal-5')}
              >
                일반전 - 한국건축가협회 KIA GOLD MEDAL
              </button>
              <button
                className={category['special-1'] ? 'active' : ''}
                onClick={() => handleCategory('special-1')}
              >
                특별전 - 대한민국공간문화대상
              </button>
              <button
                className={category['special-2'] ? 'active' : ''}
                onClick={() => handleCategory('special-2')}
              >
                특별전 - 젊은건축가상
              </button>
              <button
                className={category['special-3'] ? 'active' : ''}
                onClick={() => handleCategory('special-3')}
              >
                특별전 - 지역건축가전
              </button>
              <button
                className={category['subject-1'] ? 'active' : ''}
                onClick={() => handleCategory('subject-1')}
              >
                주제전 - 건축주: People
              </button>
              <button
                className={category['subject-2'] ? 'active' : ''}
                onClick={() => handleCategory('subject-2')}
              >
                주제전 - 건축주: Livability
              </button>
              <button
                className={category['subject-3'] ? 'active' : ''}
                onClick={() => handleCategory('subject-3')}
              >
                주제전 - 건축주: Boundary
              </button>
              <button
                className={category['subject-4'] ? 'active' : ''}
                onClick={() => handleCategory('subject-4')}
              >
                주제전 - 경남의 전통건축
              </button>
            </div>

            <div className="box-body table-responsive">
              <table className="table table-hover table-bordered ">
                <thead>
                  <tr>
                    <th className="text-center">NO.</th>
                    <th className="text-center" style={{ width: '5%' }}>
                      우선순위
                    </th>
                    <th className="text-center" style={{ width: '10%' }}>
                      카테고리
                    </th>
                    <th className="text-center">제목</th>
                    {/* <th className="text-center">최초 등록자</th> */}
                    <th className="text-center">저자</th>
                    <th className="text-center">회사</th>
                    <th className="text-center">좋아요</th>
                    <th className="text-center">노출 여부</th>
                    <th className="text-center">등록일</th>
                    <th className="text-center">수정일</th>
                    {/* <th className="text-center">최종 등록자</th> */}
                  </tr>
                </thead>
                <tbody>
                  {showData()}
                  {/* <tr>
                    <td className="text-center">{'ttt'}</td>
                    <td className="text-center">{'ttt'}</td>
                    <td>{'ttt'}</td>
                    <td className="text-center">{'ttt'}</td>
                    <td className="text-center">{'ttt'}</td>
                    <td className="text-center">{'ttt'}</td>
                    <td>{'ttt'}</td>
                  </tr> */}

                  {/* {architects.toJS().length === 0 && (
                    <tr>
                      <td colSpan="9" className="text-center no-data">
                        검색결과가 없습니다.
                      </td>
                    </tr>
                  )} */}
                </tbody>
              </table>
            </div>

            <div className="box-footer clearfix text-center" style={{ borderTop: '0px' }}>
              <div className="pull-right">
                <span
                  onClick={handleAdd}
                  className="btn btn-bitbucket pull-right"
                  style={{ marginRight: '5px' }}
                >
                  등록
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ArchitecList;
