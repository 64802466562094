import React, { PureComponent } from 'react';
import Fade from 'react-reveal/Fade';
import './Cover.css';
import { connect } from 'react-redux';
import { setLang } from 'data/actions';

class Cover extends PureComponent {
  render() {
    const { background, title, en_title, lang } = this.props;

    return (
      <div className="cover-bg" style={{ backgroundImage: `url(${background})` }}>
        <div className="cover-bg-container">
          <div className="cover-bg-title-box">
            <Fade bottom>
              <h2 className="cover-bg-title">{lang === 'ko' ? title : en_title}</h2>
            </Fade>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.config.lang,
  }),
  { setLang },
)(Cover);
