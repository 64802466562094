import React, { Component } from 'react';

class AdminFooter extends Component {
  render() {
    return (
      <footer className="main-footer">
        <div className="pull-right hidden-xs">
          <b>KCMC</b> 관리자
        </div>
        <strong>
          Copyright © <span className="ft-company-color">KCMC</span>.
        </strong>{' '}
        All rights reserved.
      </footer>
    );
  }
}

export default AdminFooter;
