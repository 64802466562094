import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import storage from 'lib/storage';

class AdminHeader extends Component {
  toggleMenu = () => {
    // if ($(window).width() > 768 - 1) {
    //   if ($('body').hasClass('sidebar-collapse')) {
    //     $('body').removeClass('sidebar-collapse').trigger('expanded.pushMenu');
    //   } else {
    //     $('body').addClass('sidebar-collapse').trigger('collapsed.pushMenu');
    //   }
    // } else {
    //   if ($('body').hasClass('sidebar-open')) {
    //     $('body')
    //       .removeClass('sidebar-open')
    //       .removeClass('sidebar-collapse')
    //       .trigger('collapsed.pushMenu');
    //   } else {
    //     $('body').addClass('sidebar-open').trigger('expanded.pushMenu');
    //   }
    // }
  };

  signout = () => {
    storage.remove('token');
    this.props.history.push('/admin');
  };

  goPage = () => {
    // this.router.navigate([`/admin/update/${this.username}`], { queryParams: { 'isMine': true } });
  };

  render() {
    const { toggleMenu, goPage, signout } = this;
    const { aName, username } = this.props;

    return (
      <header className="main-header">
        <Link to="/admin" className="logo">
          <span className="logo-mini">
            <img style={{ height: '40px' }} src="/images/admin/logo.svg" alt="KCMC" />
          </span>
          <span className="logo-lg">
            <img style={{ height: '40px' }} src="/images/admin/logo.svg" alt="KCMC" />
          </span>
        </Link>
        <nav className="navbar navbar-static-top" role="navigation">
          <span
            style={{ position: 'absolute', top: '15px', right: '20px', cursor: 'pointer' }}
            onClick={signout}
          >
            로그아웃<i className="fa fa-sign-out" style={{ marginLeft: '8px' }}></i>
          </span>
          {/* <a href="#" className="sidebar-toggle" role="button" onClick={toggleMenu}>
            <span className="sr-only">Toggle navigation</span>
          </a> */}
          {/* <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              <li>
                <a href="#" onClick={goPage}>
                  <i className="fa fa-user-o" aria-hidden="true"></i>&nbsp;
                  <span className="hidden-xs">
                    {aName || '관리자'}({username || 'kia.co.kr'})
                  </span>
                </a>
              </li>

              <li className="dropdown tasks-menu">
                <a onClick={signout}>
                  <i className="fa fa-sign-out"></i>
                </a>
              </li>
            </ul>
          </div> */}
        </nav>
      </header>
    );
  }
}

export default withRouter(AdminHeader);
