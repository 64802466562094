import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './ManagerUpdate.css';

class ManagerUpdate extends Component {
  render() {
    const {
      reset,
      email,
      password,
      confirm_password,
      name,
      phone,
      company,
      department,
      role,
      is_active,
      handleChange,
      handleSave,
      id,
      handleReset,
      handleDelete,
      openPasswordReset,
      closePasswordReset,
    } = this.props;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>관리자 관리</h1>
        </section>
        <section className="content">
          <div className="box box-primary">
            <div className="box-body">
              <div
                className="form-group col-lg-12 col-md-12"
                style={{
                  border: '1px solid #808080',
                  marginTop: '20px',
                  padding: '20px',
                  borderRadius: '4px',
                }}
              >
                <table className="table table- light">
                  <thead>
                    <tr>
                      <th colSpan="4">관리자 정보</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th style={{ width: '20%' }}>아이디 *</th>
                      <td colSpan="3">
                        {id === 0 ? (
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            value={email}
                            onChange={handleChange}
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            value={email}
                            readOnly
                          />
                        )}
                      </td>
                    </tr>
                    {id === 0 ? (
                      <>
                        <tr>
                          <th style={{ width: '20%' }}>패스워드 *</th>
                          <td colSpan="3">
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              value={password}
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: '20%' }}>패스워드 확인 *</th>
                          <td colSpan="3">
                            <input
                              type="password"
                              className="form-control"
                              name="confirm_password"
                              value={confirm_password}
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <th style={{ width: '20%' }}>패스워드</th>
                        <td colSpan="3">
                          {reset ? (
                            <>
                              <input
                                style={{ maxWidth: '20%' }}
                                type="password"
                                className="form-control"
                                name="password"
                                value={password}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                style={{ marginTop: '8px' }}
                                className="btn btn-warning"
                                onClick={closePasswordReset}
                              >
                                취소
                              </button>
                              <button
                                type="button"
                                style={{ marginLeft: '20px', marginTop: '8px' }}
                                className="btn btn-primary"
                                onClick={handleReset}
                              >
                                변경
                              </button>
                            </>
                          ) : (
                            <button
                              className="btn btn-primary btn-file"
                              onClick={openPasswordReset}
                            >
                              패스워드 변경
                            </button>
                          )}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th style={{ width: '20%' }}>이름 *</th>
                      <td colSpan="3">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={name}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>연락처 *</th>
                      <td colSpan="3">
                        <input
                          type="text"
                          className="form-control"
                          name="phone"
                          value={phone}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>회사 *</th>
                      <td colSpan="3">
                        <input
                          type="text"
                          className="form-control"
                          name="company"
                          value={company}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>직책 *</th>
                      <td colSpan="3">
                        <input
                          type="text"
                          className="form-control"
                          name="department"
                          value={department}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>권한 *</th>
                      <td colSpan="3">
                        <select
                          className="example-radio-group form-control"
                          name="role"
                          onChange={handleChange}
                          value={role}
                        >
                          <option value={'Administrator'}>최고 관리자</option>
                          <option value={'Manager'}>일반 관리자</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>활성화 여부</th>
                      <td colSpan="3">
                        <select
                          className="example-radio-group form-control"
                          name="is_active"
                          onChange={handleChange}
                          value={is_active}
                        >
                          <option value={1}>활성화</option>
                          <option value={0}>비활성화</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="box-footer">
              <div className="pull-right">
                <button
                  // to="/admin/architects"
                  className="btn btn-default"
                  style={{ marginRight: '5px' }}
                  onClick={() => this.props.history.push('/admin/managers')}
                >
                  취소
                </button>
                <button className="btn btn-primary" onClick={handleSave}>
                  {id !== 0 ? '수정' : '등록'}
                </button>
              </div>
              {/* {id !== 0 && ( */}
              <button
                onClick={() => handleDelete()}
                type="button"
                className="btn btn-danger"
                style={{ marginLeft: '5px' }}
              >
                삭제
              </button>
              {/* )} */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(ManagerUpdate);
