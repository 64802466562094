import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import './Email.css';
import { connect } from 'react-redux';
import { setLang } from 'data/actions';

class Email extends Component {
  render() {
    const { pc_content, mo_content, en_pc_content, en_mo_content, lang } = this.props;
    return (
      <>
        <Fade bottom>
          <div className="nd-email">
            <div className="nd-email-container">
              <div className="nd-email-title-box">
                <h2 className="nd-email-title">
                  {lang === 'ko'
                    ? '이메일무단수집거부'
                    : 'Rejection of unauthorized e-mail collection'}
                </h2>
              </div>
              <div className="nd-email-box">
                <img className="nd-email-img" src="/images/email.png" alt="" />
                <div className="nd-email-text-box">
                  <p className="nd-email-text pc-only">
                    {lang === 'ko' ? pc_content : en_pc_content}
                  </p>
                  <p className="nd-email-text mo-only">
                    {lang === 'ko' ? mo_content : en_mo_content}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.config.lang,
  }),
  { setLang },
)(Email);
