import React, { Component } from 'react';
import AuthContainer from 'containers/AuthContainer';
import ManagerList from 'components/admin/manager/ManagerList';
import { getManagerList } from 'api/manager';

class ManagerListContainer extends Component {
  state = {
    managers: {
      0: [],
    },
    count: 0,
    pagination: {
      perPage: 5,
      showData: 10,
      currentPage: 1,
      lastPage: 0,
    },
  };

  componentDidMount() {
    setTimeout(() => {
      this.handleManagerList();
    }, 500);
  }

  handleManagerList = async () => {
    const result = await getManagerList();
    if (result && result.status === 200) {
      let managerList = {};
      let list = [];
      result.data.map((el, i) => {
        list.push(el);

        if (i !== 0 && (i + 1) % this.state.pagination.showData === 0) {
          let index = (i + 1) / this.state.pagination.showData;
          managerList[index - 1] = list;
          list = [];
        }

        if (result.data.length < this.state.pagination.showData && result.data.length - 1 === i) {
          managerList[0] = list;
          list = [];
        }

        if (result.data.length > this.state.pagination.showData && result.data.length - 1 === i) {
          managerList[Math.ceil(result.data.length / this.state.pagination.showData) - 1] = list;
          list = [];
        }
      });

      if (result.data.length === 0) {
        managerList[0] = [];
      }

      this.setState({
        ...this.state,
        count: result.data.length,
        managers: managerList,
        pagination: {
          ...this.state.pagination,
          lastPage: Math.ceil(result.data.length / this.state.pagination.showData),
        },
      });
    }
  };

  handlePagination = (currentPage) => {
    this.setState({
      ...this.state,
      pagination: {
        ...this.state.pagination,
        currentPage,
      },
    });
  };

  render() {
    const { managers, pagination, count } = this.state;
    const { handlePagination } = this;

    return (
      <ManagerList
        managers={managers[pagination.currentPage - 1]}
        count={count}
        pagination={pagination}
        handlePagination={handlePagination}
      />
    );
  }
}

export default ManagerListContainer;
