import React, { Component } from 'react';
import moment from 'moment';
import './ProductList.css';
import { withRouter } from 'react-router-dom';
import Pagination from 'components/ui/Pagination';
import Iframe from 'react-iframe';

class ProductList extends Component {
  handleAdd = (id) => {
    this.props.history.push('/admin/products/update');
  };

  handleShow = () => {
    const { products, contents, contentType } = this.props;

    let components = [];

    products &&
      products.map((el, i) => {
        components.push(
          <tr
            key={el.id}
            className="pointer"
            onClick={(e) => this.props.history.push(`/admin/products/update/${el.id}`)}
          >
            <td className="text-center">{el.id}</td>
            {contents &&
              contentType &&
              contents[contentType].map((item, i) => {
                return (
                  <td key={i} style={{ width: '200px' }} className="text-center">
                    {el['column' + (i + 1)]}
                  </td>
                );
              })}
            <td style={{ width: '200px' }} className="text-center">
              {el.is_active ? '활성화' : '비활성화'}
            </td>
            <td style={{ width: '200px' }} className="text-center">
              {moment(el.created_at).format('YYYY-MM-DD HH:mm:ss')}
            </td>
            <td style={{ width: '200px' }} className="text-center">
              {moment(el.created_at).format('YYYY-MM-DD HH:mm:ss') ===
              moment(el.updated_at).format('YYYY-MM-DD HH:mm:ss')
                ? '-'
                : moment(el.updated_at).format('YYYY-MM-DD HH:mm:ss')}
            </td>
          </tr>,
        );
      });

    if (products && products.length === 0) {
      components.push(
        <tr key={'no'}>
          <td colSpan="20" className="text-center no-data">
            등록된 제품이 없습니다.
          </td>
        </tr>,
      );
    }

    return components;
  };

  showTableTh = () => {
    const { contents, contentType } = this.props;

    let components = [];

    contents &&
      contentType &&
      contents[contentType].map((el, i) => {
        components.push(
          <th className="text-center" key={i}>
            {el}
          </th>,
        );
      });

    return components;
  };

  render() {
    const { handleAdd, handleShow, showTableTh } = this;
    const {
      type,
      contentType,
      pagination,
      count,
      handleType,
      handlePagination,
      uv_curing_resins,
      handleSave,
    } = this.props;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>프러덕트 관리</h1>
          <div className="architect-button-list">
            <button
              className={type === 'monomers' ? 'active' : ''}
              onClick={(e) => handleType('monomers')}
            >
              {'MONOMERS & OTHERS'}
            </button>
            <button
              className={type === 'special_monomers' ? 'active' : ''}
              onClick={(e) => handleType('special_monomers')}
            >
              SPECIAL MONOMERS
            </button>
            <button
              className={type === 'photo_initilator' ? 'active' : ''}
              onClick={(e) => handleType('photo_initilator')}
            >
              Photo Initiator
            </button>
            <button
              className={type === 'npe' ? 'active' : ''}
              onClick={(e) => handleType('npe', 'surfactants_monopol')}
            >
              MONOPOL NPE SERIES
            </button>
            <button
              className={type === 'ope' ? 'active' : ''}
              onClick={(e) => handleType('ope', 'surfactants_monopol')}
            >
              MONOPOL OPE SERIES
            </button>
            <button
              className={type === 'nfe' ? 'active' : ''}
              onClick={(e) => handleType('nfe', 'surfactants_monopol')}
            >
              MONOPOL NFE SERIES
            </button>
            <button
              className={type === 'lae' ? 'active' : ''}
              onClick={(e) => handleType('lae', 'surfactants_monopol')}
            >
              MONOPOL LAE SERIES
            </button>
            <button
              className={type === 'emulsifying' ? 'active' : ''}
              onClick={(e) => handleType('emulsifying', 'surfactants_agent')}
            >
              EMULSIFYING AGENT
            </button>
            <button
              className={type === 'defoaming' ? 'active' : ''}
              onClick={(e) => handleType('defoaming', 'surfactants_agent')}
            >
              DEFOAMING AGENT
            </button>
            <button
              className={type === 'anti' ? 'active' : ''}
              onClick={(e) => handleType('anti', 'surfactants_agent')}
            >
              ANTI-CAKING AGENT
            </button>
            <button
              className={type === 'polishing' ? 'active' : ''}
              onClick={(e) => handleType('polishing', 'surfactants_agent')}
            >
              POLISHING AGENT
            </button>
            <button
              className={type === 'plasticizer' ? 'active' : ''}
              onClick={(e) => handleType('plasticizer')}
            >
              PLASTICIZER
            </button>
            <button
              className={type === 'coating_resins' ? 'active' : ''}
              onClick={(e) => handleType('coating_resins')}
            >
              COATING RESINS
            </button>
            <button
              className={type === 'epoxy_resins' ? 'active' : ''}
              onClick={(e) => handleType('epoxy_resins')}
            >
              EPOXY RESINS
            </button>
            <button
              className={type === 'uv_curing_resins' ? 'active' : ''}
              onClick={(e) => handleType('uv_curing_resins')}
            >
              UV CURING RESIN
            </button>
          </div>
        </section>
        <section className="content">
          {type !== 'uv_curing_resins' ? (
            <div className="box box-primary">
              <div className="box-header">
                <h3 className="box-title">총 {count} 건</h3>
              </div>

              <div className="box-body table-responsive">
                <table className="table table-hover table-bordered ">
                  <thead>
                    <tr>
                      <th className="text-center">아이디</th>
                      {showTableTh()}
                      <th className="text-center">활성화 여부</th>
                      <th className="text-center">생성일시</th>
                      <th className="text-center">수정일시</th>
                    </tr>
                  </thead>
                  <tbody>{handleShow()}</tbody>
                </table>
                {count !== 0 && (
                  <Pagination pagination={pagination} handlePagination={handlePagination} />
                )}
              </div>

              <div className="box-footer clearfix text-center" style={{ borderTop: '0px' }}>
                <div className="pull-right">
                  <span
                    onClick={handleAdd}
                    className="btn btn-bitbucket pull-right"
                    style={{ marginRight: '5px' }}
                  >
                    등록
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="box box-primary">
              <div className="box-body">
                <div
                  className="form-group col-lg-12 col-md-12"
                  style={{
                    border: '1px solid #808080',
                    marginTop: '20px',
                    padding: '20px',
                    borderRadius: '4px',
                  }}
                >
                  <table className="table table- light">
                    <thead>
                      <tr>
                        <th colSpan="4">UV CURING RESIN 페이지</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style={{ width: '20%' }}>Ko PC 콘텐츠 *</th>
                        <td colSpan="3">
                          <Iframe
                            id="product-editor-1"
                            url={'/Editor/editor.html?id=product-editor-1'}
                            className="upload-editor"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: '20%' }}>Ko Mobile 콘텐츠 *</th>
                        <td colSpan="3">
                          <Iframe
                            id="product-editor-2"
                            url={'/Editor/editor.html?id=product-editor-2'}
                            className="upload-editor"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: '20%' }}>English PC 콘텐츠 *</th>
                        <td colSpan="3">
                          <Iframe
                            id="product-editor-3"
                            url={'/Editor/editor.html?id=product-editor-3'}
                            className="upload-editor"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: '20%' }}>English Mobile 콘텐츠 *</th>
                        <td colSpan="3">
                          <Iframe
                            id="product-editor-4"
                            url={'/Editor/editor.html?id=product-editor-4'}
                            className="upload-editor"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="box-footer">
                <div className="pull-right">
                  <button
                    // to="/admin/architects"
                    className="btn btn-default"
                    style={{ marginRight: '5px' }}
                    onClick={() => this.props.history.push('/admin/pages')}
                  >
                    취소
                  </button>
                  <button className="btn btn-primary" onClick={handleSave}>
                    {uv_curing_resins.id ? '수정' : '등록'}
                  </button>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    );
  }
}

export default withRouter(ProductList);
