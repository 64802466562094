import { combineReducers } from 'redux';
import { SET_LANG } from './actionTypes';

const INITIAL_STATE = {
  lang: 'ko',
};

const langReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LANG:
      return {
        lang: action.lang,
      };
    default:
      return state;
  }
};

export default combineReducers({
  config: langReducer,
});
