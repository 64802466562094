import React, { Component } from 'react';
import Map from 'components/ui/Map';
import Fade from 'react-reveal/Fade';
import { withRouter } from 'react-router-dom';
import './Main.css';
import { connect } from 'react-redux';
import { setLang } from 'data/actions';

class Main extends Component {
  render() {
    const { contact, lang } = this.props;

    return (
      <>
        {/* <div className="nd-main-bg">
          <div className="nd-main-container">
            <div className="nd-main-box">
              <div className="nd-main-top-box">
                <Fade bottom>
                  <p className="nd-main-top-text">
                    CHEMICAL
                    <br className="nd-mobile-br" />
                    NEEDS SOLUTION
                  </p>
                </Fade>
              </div>
              <div className="nd-main-bottom-box">
                <Fade bottom>
                  <h2 className="nd-main-bottom-text">
                    고객이 화학제품을 불편없이
                    <br className="nd-pc-mobile-br" />
                    사용하게 하는 것이
                    <br className="nd-mobile-br" /> 우리의 역할 입니다.
                  </h2>
                </Fade>
              </div>
            </div>
          </div>
          <div className="mouse-down-bg">
            <Fade bottom>
              <span className="mouse-down"></span>
              <span className="mouse-down-desc">SCROLL DOWN</span>
            </Fade>
          </div>
        </div> */}

        <div className="nd-content">
          <div className="nd-content-cotainer">
            <div className="nd-content-box">
              <div className="nd-content-title-box">
                <Fade bottom>
                  <h2 className="nd-content-title">ABOUT KC MONOMER</h2>
                </Fade>
              </div>
              <div className="nd-content-text-box">
                <Fade bottom>
                  <p className="nd-content-text">
                    {lang === 'ko'
                      ? '우리는 고객에게 화학제품을 안정적이며 '
                      : 'We supply our customers with chemical products'}
                    <br className="nd-mobile-br" />
                    {lang === 'ko'
                      ? '경쟁력 있는 가격으로 공급하고 있습니다.'
                      : ' at stable and competitive prices.'}
                  </p>
                </Fade>
                <Fade bottom>
                  <p className="nd-content-text">
                    {lang === 'ko'
                      ? '또한 고객이 필요로 하는 CHEMICAL 제품을 '
                      : 'In addition, we are sourcing and developing '}
                    <br className="nd-mobile-br" />
                    {lang === 'ko'
                      ? '소싱 및 개발하고 있습니다.'
                      : 'chemical products that customers need.'}
                  </p>
                </Fade>
              </div>
            </div>
            <div className="nd-content-company-box">
              <div className="nd-content-top-company-box">
                <div className="nd-content-top-left-company-box">
                  <Fade bottom>
                    <img className="nd-content-company-img" src="/images/main_company.svg" alt="" />
                    <div className="nd-content-top-left-company-title-box">
                      <h2 className="nd-content-top-left-company-title">
                        {lang === 'ko' ? '회사명' : 'COMPANY'}
                      </h2>
                    </div>
                  </Fade>
                </div>
                <div className="nd-content-top-right-company-box">
                  <Fade bottom>
                    <img
                      className="nd-content-top-right-company-logo"
                      src="/images/main_logo.svg"
                      alt=""
                    />
                    <div className="nd-content-top-right-company-title-box">
                      <h2 className="nd-content-top-right-company-title">
                        {lang === 'ko' ? '(주)케이씨모노머' : 'KC Monomer'}
                      </h2>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="nd-content-bottom-company-box">
                <div className="nd-content-bottom-left-company-box">
                  <Fade bottom>
                    <img className="nd-content-company-img" src="/images/main_yaer.svg" alt="" />
                    <div className="nd-content-bottom-left-company-title-box">
                      <h2 className="nd-content-bottom-left-company-title">
                        {lang === 'ko' ? '설립연도' : 'Establishment'}
                      </h2>
                    </div>
                  </Fade>
                </div>
                <div className="nd-content-bottom-right-company-title-box">
                  <Fade bottom>
                    <h2 className="nd-content-bottom-right-company-title">
                      {lang === 'ko' ? '2003년 2월' : 'February 2003'}
                    </h2>
                  </Fade>
                </div>
              </div>
            </div>
            <div className="nd-content-company-logo-box">
              <Fade bottom>
                <img className="nd-content-company-logo-img" src="/images/lg_chem.svg" alt="" />
                <img className="nd-content-company-logo-img" src="/images/lg_mma.svg" alt="" />
                <img className="nd-content-company-logo-img" src="/images/tree.svg" alt="" />
                <img className="nd-content-company-logo-img" src="/images/mgc.svg" alt="" />
                <img className="nd-content-company-logo-img" src="/images/evonik.svg" alt="" />
                <img
                  className="nd-content-company-logo-img"
                  src="/images/nof_corporation.svg"
                  alt=""
                />
              </Fade>
            </div>
          </div>

          <div className="nd-content-inner">
            <div className="nd-content-inner-title-box">
              <Fade bottom>
                <h2 className="nd-content-inner-title">KC’S BUSINESS</h2>
              </Fade>
            </div>
            <div className="nd-content-item-box">
              <Fade bottom>
                <div className="nd-content-first-item">
                  <div
                    className="nd-content-first-item-box"
                    onClick={() => this.props.history.push('/business/product')}
                  >
                    <h2 className="nd-content-item-box-title">
                      Raw material for
                      <br />
                      chemicals
                    </h2>
                  </div>
                  <div>
                    <p className="nd-content-item-box-text">(monomer, plasticizer, solvents)</p>
                  </div>
                </div>
                <div
                  className="nd-content-middle-item"
                  onClick={() => this.props.history.push('/business/product?resin=true')}
                >
                  <div className="nd-content-middle-item-box">
                    <h2 className="nd-content-item-box-title">RESIN</h2>
                  </div>
                  <div>
                    <p className="nd-content-item-box-text">(Acrylic Resin, Epoxy resin)</p>
                  </div>
                </div>
                <div
                  className="nd-content-last-item"
                  onClick={() => this.props.history.push('/business/inquiry')}
                >
                  <div className="nd-content-last-item-box">
                    <h2 className="nd-content-item-box-title">Developing products</h2>
                  </div>
                </div>
              </Fade>
            </div>
          </div>

          <div className="nd-content-inner">
            <div className="nd-content-inner-title-box">
              <Fade bottom>
                <h2 className="nd-content-inner-title">CONTACT</h2>
              </Fade>
            </div>
            <Fade bottom>
              <div className="nd-map-box">
                <Map map={contact.map} />
                {/* <div className="nd-main-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.503919366966!2d126.92676501575197!3d37.51961687980665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f1589fd3069%3A0x418450b2feac4a9d!2z64-Z67aB67mM65SpIOq0gOumrOyCrOustOyGjA!5e0!3m2!1sko!2skr!4v1613655414918!5m2!1sko!2skr"
                  // width="600"
                  // height="450"
                  frameborder="0"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </div> */}
                {/* <img className="nd-main-map" src="/images/contact-map.png" alt="" /> */}
                <div className="nd-main-map-box">
                  <div>
                    <div className="nd-main-map-title-box">
                      <h2 className="nd-main-map-title">
                        {lang === 'ko' ? contact.company : contact.en_company}
                      </h2>
                    </div>
                    <div className="nd-nd-main-map-text-box">
                      <p className="nd-main-map-text address">
                        {lang === 'ko' ? contact.address : contact.en_address}
                        {/* 서울 영등포구 국제금융로8길
                        <br className="nd-pc-br" /> 27-9 동북빌딩 601호
                        <br /> (여의도동, 동북빌딩) */}
                      </p>
                    </div>
                    <p className="nd-main-map-text">{contact.contact_number}</p>
                    <div className="nd-main-map-detail-box">
                      <p
                        className="nd-main-map-detail pointer"
                        onClick={() => this.props.history.push('/contact')}
                      >
                        view detail{' '}
                        <img className="nd-main-map-img" src="/images/contact-detail.svg" alt="" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.config.lang,
  }),
  { setLang },
)(withRouter(Main));
