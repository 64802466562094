import React, { Component } from 'react';
import MainStructure from 'components/structure/MainStructure';
import Cover from 'components/ui/Cover';
import About from 'components/About';
import { getPageByType } from 'api/page';

class AboutContainer extends Component {
  state = {
    page: {
      title: '',
      en_title: '',
      image: '',
    },
  };

  componentDidMount() {
    this.handlePage();
  }

  handlePage = async () => {
    const result = await getPageByType('about');
    if (result && result.status === 200) {
      this.setState({
        ...this.state,
        page: result.data,
      });
    }
  };

  render() {
    const { page } = this.state;

    return (
      <MainStructure>
        <Cover
          title={(page && page.title) || 'ABOUT'}
          en_title={(page && page.en_title) || 'ABOUT'}
          background={
            page.image ? `${process.env.REACT_APP_S3}${page.image}` : '/images/about-bg.png'
          }
        />
        <About />
      </MainStructure>
    );
  }
}

export default AboutContainer;
