import React, { Component } from 'react';
import AuthContainer from 'containers/AuthContainer';
import ProductList from 'components/admin/products/ProductList';
import { getProductList, getProductsByType, updateProduct, createProduct } from 'api/product';
import { productTypes, contents } from 'lib/product';
import { uploadInfoImage } from 'api/upload';
import { withRouter } from 'react-router-dom';

class ProductListContainer extends Component {
  state = {
    products: {
      monomers: {
        0: [],
      },
      special_monomers: {
        0: [],
      },
      photo_initilator: {
        0: [],
      },
      npe: {
        0: [],
      },
      ope: {
        0: [],
      },
      nfe: {
        0: [],
      },
      lae: {
        0: [],
      },
      emulsifying: {
        0: [],
      },
      defoaming: {
        0: [],
      },
      anti: {
        0: [],
      },
      polishing: {
        0: [],
      },
      plasticizer: {
        0: [],
      },
      coating_resins: {
        0: [],
      },
      epoxy_resins: {
        0: [],
      },
      uv_curing_resins: {
        0: [],
      },
    },
    counts: {
      monomers: {
        count: 0,
      },
      special_monomers: {
        count: 0,
      },
      photo_initilator: {
        count: 0,
      },
      npe: {
        count: 0,
      },
      ope: {
        count: 0,
      },
      nfe: {
        count: 0,
      },
      lae: {
        count: 0,
      },
      emulsifying: {
        count: 0,
      },
      defoaming: {
        count: 0,
      },
      anti: {
        count: 0,
      },
      polishing: {
        count: 0,
      },
      plasticizer: {
        count: 0,
      },
      coating_resins: {
        count: 0,
      },
      epoxy_resins: {
        count: 0,
      },
      uv_curing_resins: {
        count: 0,
      },
    },
    paginations: {
      monomers: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      special_monomers: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      photo_initilator: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      npe: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      ope: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      nfe: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      lae: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      emulsifying: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      defoaming: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      anti: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      polishing: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      plasticizer: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      coating_resins: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      epoxy_resins: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
      uv_curing_resins: {
        pagination: {
          perPage: 5,
          showData: 10,
          currentPage: 1,
          lastPage: 0,
        },
      },
    },
    uv_curing_resins: {
      id: 0,
      column1: '',
      column2: '',
      column3: '',
      column4: '',
    },
    type: 'monomers',
    contentType: 'monomers',
  };

  componentDidMount() {
    productTypes.map((type) => {
      this.handleProductList(type);
    });
    window.addEventListener('message', this.handleIframeTask);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleIframeTask);
  }

  handleIframeTask = async (e) => {
    var editor = document.getElementById('product-editor-1');
    var editor2 = document.getElementById('product-editor-2');
    var editor3 = document.getElementById('product-editor-3');
    var editor4 = document.getElementById('product-editor-4');

    if (e.data === 'product-editor-1#####contentUpload') {
      let editorIframe = editor.contentDocument || editor.contentWindow.document;
      let contentFile = editorIframe.querySelector(
        '.form-group.note-form-group.note-group-select-from-files input',
      );

      try {
        const result = await uploadInfoImage(contentFile.files[0]);

        editor.contentWindow.postMessage('url: ' + process.env.REACT_APP_S3 + result.data.key, '*');
      } catch (e) {
        editor.contentWindow.postMessage('fail', '*');
        alert('업로드 실패');
      }
    }

    if (e.data === 'product-editor-2#####contentUpload') {
      let editorIframe = editor2.contentDocument || editor2.contentWindow.document;
      let contentFile = editorIframe.querySelector(
        '.form-group.note-form-group.note-group-select-from-files input',
      );

      try {
        const result = await uploadInfoImage(contentFile.files[0]);

        editor2.contentWindow.postMessage(
          'url: ' + process.env.REACT_APP_S3 + result.data.key,
          '*',
        );
      } catch (e) {
        editor2.contentWindow.postMessage('fail', '*');
        alert('업로드 실패');
      }
    }

    if (e.data === 'product-editor-3#####contentUpload') {
      let editorIframe = editor3.contentDocument || editor3.contentWindow.document;
      let contentFile = editorIframe.querySelector(
        '.form-group.note-form-group.note-group-select-from-files input',
      );

      try {
        const result = await uploadInfoImage(contentFile.files[0]);

        editor3.contentWindow.postMessage(
          'url: ' + process.env.REACT_APP_S3 + result.data.key,
          '*',
        );
      } catch (e) {
        editor3.contentWindow.postMessage('fail', '*');
        alert('업로드 실패');
      }
    }

    if (e.data === 'product-editor-4#####contentUpload') {
      let editorIframe = editor4.contentDocument || editor4.contentWindow.document;
      let contentFile = editorIframe.querySelector(
        '.form-group.note-form-group.note-group-select-from-files input',
      );

      try {
        const result = await uploadInfoImage(contentFile.files[0]);

        editor4.contentWindow.postMessage(
          'url: ' + process.env.REACT_APP_S3 + result.data.key,
          '*',
        );
      } catch (e) {
        editor4.contentWindow.postMessage('fail', '*');
        alert('업로드 실패');
      }
    }

    if (e.data === 'product-editor-1#####initFinished') {
      editor.contentWindow.postMessage('edit!13@&42#' + this.state.uv_curing_resins.column1, '*');
    }

    if (e.data === 'product-editor-2#####initFinished') {
      editor2.contentWindow.postMessage('edit!13@&42#' + this.state.uv_curing_resins.column2, '*');
    }

    if (e.data === 'product-editor-3#####initFinished') {
      editor3.contentWindow.postMessage('edit!13@&42#' + this.state.uv_curing_resins.column3, '*');
    }

    if (e.data === 'product-editor-4#####initFinished') {
      editor4.contentWindow.postMessage('edit!13@&42#' + this.state.uv_curing_resins.column4, '*');
    }

    if (e.data === 'product-editor-1#####change') {
      // note-editable
      let editorIframe = editor.contentDocument || editor.contentWindow.document;
      let contentHtml = editorIframe.querySelector('.note-editable');
      this.setState({
        ...this.state,
        uv_curing_resins: {
          ...this.state.uv_curing_resins,
          column1: contentHtml.innerHTML,
        },
      });
    }

    if (e.data === 'product-editor-2#####change') {
      // note-editable
      let editorIframe = editor2.contentDocument || editor2.contentWindow.document;
      let contentHtml = editorIframe.querySelector('.note-editable');
      this.setState({
        ...this.state,
        uv_curing_resins: {
          ...this.state.uv_curing_resins,
          column2: contentHtml.innerHTML,
        },
      });
    }

    if (e.data === 'product-editor-3#####change') {
      // note-editable
      let editorIframe = editor3.contentDocument || editor3.contentWindow.document;
      let contentHtml = editorIframe.querySelector('.note-editable');
      this.setState({
        ...this.state,
        uv_curing_resins: {
          ...this.state.uv_curing_resins,
          column3: contentHtml.innerHTML,
        },
      });
    }

    if (e.data === 'product-editor-4#####change') {
      // note-editable
      let editorIframe = editor4.contentDocument || editor4.contentWindow.document;
      let contentHtml = editorIframe.querySelector('.note-editable');
      this.setState({
        ...this.state,
        uv_curing_resins: {
          ...this.state.uv_curing_resins,
          column4: contentHtml.innerHTML,
        },
      });
    }
  };

  handleProductList = async (type) => {
    const result = await getProductsByType(type);
    if (result && result.status === 200) {
      let productList = {};
      let list = [];

      if (type !== 'uv_curing_resins') {
        result.data.map((el, i) => {
          list.push(el);

          if (i !== 0 && (i + 1) % this.state.paginations[type].pagination.showData === 0) {
            let index = (i + 1) / this.state.paginations[type].pagination.showData;
            productList[index - 1] = list;
            list = [];
          }

          if (
            result.data.length < this.state.paginations[type].pagination.showData &&
            result.data.length - 1 === i
          ) {
            productList[0] = list;
            list = [];
          }

          if (
            result.data.length > this.state.paginations[type].pagination.showData &&
            result.data.length - 1 === i
          ) {
            productList[
              Math.ceil(result.data.length / this.state.paginations[type].pagination.showData) - 1
            ] = list;
            list = [];
          }
        });

        if (result.data.length === 0) {
          productList[0] = [];
        }

        this.setState({
          ...this.state,
          products: {
            ...this.state.products,
            [type]: productList,
          },
          counts: {
            ...this.state.counts,
            [type]: {
              count: result.data.length,
            },
          },
          paginations: {
            ...this.state.paginations,
            [type]: {
              ...this.state.paginations[type],
              pagination: {
                ...this.state.paginations[type].pagination,
                lastPage: Math.ceil(
                  result.data.length / this.state.paginations[type].pagination.showData,
                ),
              },
            },
          },
        });
      } else {
        if (result.data.length > 0) {
          this.setState({
            ...this.state,
            uv_curing_resins: {
              id: result.data[0].id,
              column1: result.data[0].column1,
              column2: result.data[0].column2,
              column3: result.data[0].column3,
              column4: result.data[0].column4,
            },
          });
        }
      }
    }
  };

  handlePagination = (currentPage) => {
    const { type } = this.state;
    this.setState({
      ...this.state,
      paginations: {
        ...this.state.paginations,
        [type]: {
          ...this.state.paginations[type],
          pagination: {
            ...this.state.paginations[type].pagination,
            currentPage,
          },
        },
      },
    });
  };

  handleType = (type, contentType) => {
    this.setState({
      ...this.state,
      type: type,
      contentType: contentType ? contentType : type,
    });
  };

  handleSave = async () => {
    const { type, uv_curing_resins } = this.state;

    var editor = document.getElementById('product-editor-1');
    var editor2 = document.getElementById('product-editor-2');
    var editor3 = document.getElementById('product-editor-3');
    var editor4 = document.getElementById('product-editor-4');

    const editorIframe = editor.contentDocument || editor.contentWindow.document;
    const editorIframe2 = editor2.contentDocument || editor2.contentWindow.document;
    const editorIframe3 = editor3.contentDocument || editor3.contentWindow.document;
    const editorIframe4 = editor4.contentDocument || editor4.contentWindow.document;
    const contentHtml = editorIframe.querySelector('.note-editable');
    const contentHtml2 = editorIframe2.querySelector('.note-editable');
    const contentHtml3 = editorIframe3.querySelector('.note-editable');
    const contentHtml4 = editorIframe4.querySelector('.note-editable');

    if (type === 'uv_curing_resins') {
      if (uv_curing_resins.id) {
        const result = await updateProduct(uv_curing_resins.id, {
          type,
          column1: contentHtml.innerHTML,
          column2: contentHtml2.innerHTML,
          column3: contentHtml3.innerHTML,
          column4: contentHtml4.innerHTML,
        });
        if (result && result.status === 200) {
          alert('수정 성공:)');
          this.handleProductList('uv_curing_resins');
        }
      } else {
        const result = await createProduct({
          type,
          column1: contentHtml.innerHTML,
          column2: contentHtml2.innerHTML,
          column3: contentHtml3.innerHTML,
          column4: contentHtml4.innerHTML,
        });

        if (result && result.status === 200) {
          alert('등록 성공:)');
          this.handleProductList('uv_curing_resins');
        }
      }
    }
  };

  render() {
    const { products, paginations, counts, type, contentType, uv_curing_resins } = this.state;
    const { handlePagination, handleType, handleSave } = this;

    return (
      <ProductList
        contents={contents}
        products={products[type][paginations[type].pagination.currentPage - 1]}
        uv_curing_resins={uv_curing_resins}
        count={counts[type].count}
        pagination={paginations[type].pagination}
        type={type}
        contentType={contentType}
        handleType={handleType}
        handlePagination={handlePagination}
        handleSave={handleSave}
      />
    );
  }
}

export default AuthContainer()(withRouter(ProductListContainer));
