import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import {
  Main,
  About,
  Product,
  Inquiry,
  Privacy,
  Contact,
  Email,
  Login,
  ArchitectList,
  ManagerList,
  ManagerUpdate,
  SlideUpdate,
  PopupUpdate,
  PageUpdate,
  ProductList,
  ProductUpdate,
  InquiryList,
  InquiryUpdate,
  InfoUpdate,
} from 'pages';

class App extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/" exact={true} component={Main} />
          <Route path="/about" exact={true} component={About} />
          <Route path="/business" exact={true} component={Product} />
          <Route path="/business/product" exact={true} component={Product} />
          <Route path="/business/inquiry" exact={true} component={Inquiry} />
          <Route path="/contact" exact={true} component={Contact} />
          <Route path="/email" exact={true} component={Email} />
          <Route path="/privacy" exact={true} component={Privacy} />
          <Route path="/admin" exact={true} component={Login} />
          <Route path="/admin/architects" exact={true} component={ArchitectList} />
          <Route path="/admin/managers" exact={true} component={ManagerList} />
          <Route path="/admin/managers/update" exact={true} component={ManagerUpdate} />
          <Route path="/admin/managers/update/:id" exact={true} component={ManagerUpdate} />
          <Route path="/admin/slides" exact={true} component={SlideUpdate} />
          <Route path="/admin/popups" exact={true} component={PopupUpdate} />
          <Route path="/admin/pages" exact={true} component={PageUpdate} />
          <Route path="/admin/products" exact={true} component={ProductList} />
          <Route path="/admin/products/update" exact={true} component={ProductUpdate} />
          <Route path="/admin/products/update/:id" exact={true} component={ProductUpdate} />
          <Route path="/admin/inquiries" exact={true} component={InquiryList} />
          <Route path="/admin/inquiries/update" exact={true} component={InquiryUpdate} />
          <Route path="/admin/inquiries/update/:id" exact={true} component={InquiryUpdate} />
          <Route path="/admin/infos" exact={true} component={InfoUpdate} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
