import React, { Component } from 'react';
import AuthContainer from 'containers/AuthContainer';
import SlideUpdate from 'components/admin/slides/SlideUpdate';
import { getSlideList, createSlide, updateSlide, deleteSlide } from 'api/slide';
import { uploadSlideImage } from 'api/upload';

class SlideUpdateContainer extends Component {
  state = {
    slides: [],
    originSlides: [],
    isLoading: {
      slide: [false],
    },
    deleteList: [],
  };

  componentDidMount() {
    this.handleSlideList();
  }

  handleSlideList = async () => {
    const result = await getSlideList();
    if (result && result.status === 200) {
      const slide = {
        pc_title: '',
        pc_description: '',
        mo_title: '',
        mo_description: '',
        en_pc_title: '',
        en_pc_description: '',
        en_mo_title: '',
        en_mo_description: '',
        image: '',
        slide_order: 0,
        is_active: 0,
      };

      if (result.data.length > 0) {
        let tempLoading = [];

        result.data.map((el) => {
          tempLoading.push(false);
        });

        this.setState({
          ...this.state,
          slides: result.data,
          isLoading: {
            ...this.state.isLoading,
            slide: tempLoading,
          },
        });
      } else {
        this.setState({
          ...this.state,
          slides: [slide],
          isLoading: {
            ...this.state.isLoading,
            slide: [false],
          },
        });
      }
    }
  };

  handleChange = (e, index) => {
    const { slides } = this.state;
    const tempSlides = [...slides];
    tempSlides[index][e.target.name] =
      e.target.name === 'slide_order' || e.target.name === 'is_active'
        ? Number(e.target.value)
        : e.target.value;

    this.setState({
      ...this.state,
      slides: tempSlides,
    });
  };

  handleImage = async (e, index) => {
    const { slides, isLoading } = this.state;
    let tempSlides = [...slides];
    let tempLoading = [...isLoading.slide];

    tempLoading[index] = true;

    this.setState({
      ...this.state,
      isLoading: {
        ...this.state.isLoading,
        slide: tempLoading,
      },
    });

    const result = await uploadSlideImage(e.target.files[0]);
    tempLoading[index] = false;
    if (result && result.status === 200) {
      tempSlides[index]['image'] = result.data.key;
      this.setState({
        ...this.state,
        slides: tempSlides,
        isLoading: {
          ...this.state.isLoading,
          slide: tempLoading,
        },
      });
    } else {
      this.setState({
        ...this.state,
        isLoading: {
          ...this.state.isLoading,
          slide: tempLoading,
        },
      });
    }
  };

  resetImage = (i) => {
    const { slides } = this.state;
    let tempSlides = [...slides];
    tempSlides[i].image = '';

    this.setState({
      ...this.state,
      slides: tempSlides,
    });
  };

  handleAdd = () => {
    const { slides, isLoading } = this.state;

    if (slides.length > 4) return alert('최대 5개 까지 가능합니다:)');

    const slide = {
      pc_title: '',
      pc_description: '',
      mo_title: '',
      mo_description: '',
      en_pc_title: '',
      en_pc_description: '',
      en_mo_title: '',
      en_mo_description: '',
      image: '',
      slide_order: 0,
      is_active: 0,
    };
    const tempSlides = [...slides, slide];
    const tempLoading = [...isLoading.slide, false];

    this.setState({
      ...this.state,
      slides: tempSlides,
      isLoading: {
        ...this.state.isLoading,
        slide: tempLoading,
      },
    });
  };

  handleSave = async () => {
    const { slides, deleteList } = this.state;

    let last = 0;

    const check = slides.every((el, i) => {
      last = i;
      return (
        el.pc_title.trim() &&
        el.pc_description.trim() &&
        el.mo_title.trim() &&
        el.mo_description.trim() &&
        el.en_pc_title.trim() &&
        el.en_pc_description.trim() &&
        el.en_mo_title.trim() &&
        el.en_mo_description.trim() &&
        el.image
      );
    });

    if (!check) return alert(`${last + 1}번째 슬라이드 필수 값을 먼저 입력해주세요:)`);

    await Promise.all(
      deleteList.map(async (el) => {
        const result = await deleteSlide(el);
      }),
    );

    await Promise.all(
      slides.map(async (el, i) => {
        if (!!el.id && el.id !== 0) {
          const result = await updateSlide(el.id, {
            pc_title: el.pc_title,
            pc_description: el.pc_description,
            mo_title: el.mo_title,
            mo_description: el.mo_description,
            en_pc_title: el.en_pc_title,
            en_pc_description: el.en_pc_description,
            en_mo_title: el.en_mo_title,
            en_mo_description: el.en_mo_description,
            image: el.image,
            slide_order: el.slide_order,
            is_active: el.is_active,
          });
        } else {
          const result = await createSlide({
            pc_title: el.pc_title,
            pc_description: el.pc_description,
            mo_title: el.mo_title,
            mo_description: el.mo_description,
            en_pc_title: el.en_pc_title,
            en_pc_description: el.en_pc_description,
            en_mo_title: el.en_mo_title,
            en_mo_description: el.en_mo_description,
            image: el.image,
            slide_order: el.slide_order,
            is_active: el.is_active,
          });
        }
      }),
    );

    alert('수정 성공:)');

    this.handleSlideList();
  };

  handleRemove = async (i) => {
    const { slides, isLoading, deleteList } = this.state;

    if (1 < slides.length) {
      const tempSlides = [...slides];
      const tempLoading = [...isLoading.slide, false];
      const tempDelete = [...deleteList, tempSlides[i].id];

      tempSlides.splice(i, 1);
      tempLoading.splice(i, 1);
      this.setState({
        ...this.state,
        slides: tempSlides,
        isLoading: {
          ...this.state.isLoading,
          slide: tempLoading,
        },
        deleteList: tempDelete,
      });
    }
  };

  render() {
    const { slides, isLoading } = this.state;
    const { handleChange, handleImage, resetImage, handleSave, handleAdd, handleRemove } = this;

    return (
      <SlideUpdate
        slides={slides}
        isLoading={isLoading}
        handleChange={handleChange}
        handleImage={handleImage}
        resetImage={resetImage}
        handleSave={handleSave}
        handleAdd={handleAdd}
        handleRemove={handleRemove}
      />
    );
  }
}

export default AuthContainer()(SlideUpdateContainer);
