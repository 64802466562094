import React from 'react';
import AdminLayout from 'components/admin/layout/AdminLayout';
import ProductListContainer from 'containers/admin/ProductListContainer';

const ProductList = () => {
  return (
    <AdminLayout>
      <ProductListContainer />
    </AdminLayout>
  );
};

export default ProductList;
