import React, { Component } from 'react';
import Header from 'components/structure/Header';
import Footer from 'components/structure/Footer';

class MainStructure extends Component {
  render() {
    const { children, page } = this.props;
    return (
      <>
        <Header page={page} />
        {children}
        <Footer />
      </>
    );
  }
}

export default MainStructure;
