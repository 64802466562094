import React, { Component } from 'react';
import './Pagination.css';

class Pagination extends Component {
  handlePage = () => {
    const { pagination, handlePagination, type } = this.props;
    let components = [];

    // currentPage: 5
    // lastPage: 11
    // perPage: 5
    // showData: 10

    const per =
      pagination.currentPage % pagination.perPage === 0
        ? pagination.currentPage / pagination.perPage - 1
        : Math.floor(pagination.currentPage / pagination.perPage);
    const last =
      pagination.lastPage >= (per + 1) * pagination.perPage
        ? (per + 1) * pagination.perPage
        : pagination.lastPage;

    for (let i = per * pagination.perPage; i < last; i++) {
      components.push(
        <li
          className={pagination.currentPage === i + 1 ? 'active' : ''}
          key={i + 1}
          onClick={() => handlePagination(i + 1, type)}
        >
          {i + 1}
        </li>,
      );
    }

    return components;
  };

  render() {
    const { handlePage } = this;
    const { className, pagination, handlePagination, type } = this.props;

    return (
      <ul className={'custom-pagination ' + className}>
        <li
          onClick={() => {
            pagination.currentPage !== 1 && handlePagination(1, type);
          }}
        >
          {'<<'}
        </li>
        <li
          onClick={() => {
            pagination.currentPage > 1 && handlePagination(pagination.currentPage - 1, type);
          }}
        >
          {'<'}
        </li>
        {handlePage()}
        <li
          onClick={() => {
            pagination.currentPage < pagination.lastPage &&
              handlePagination(pagination.currentPage + 1, type);
          }}
        >
          {'>'}
        </li>
        <li
          onClick={() => {
            pagination.currentPage !== pagination.lastPage &&
              handlePagination(pagination.lastPage, type);
          }}
        >
          {'>>'}
        </li>
      </ul>
    );
  }
}

export default Pagination;
