import axios from 'axios';
import storage from 'lib/storage';

const token = storage.get('token');

export const getSlideList = async () => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_API}/slides/`, {
      headers: { Authorization: 'Bearer ' + token },
    });

    return result;
  } catch (e) {}
};

export const getSlideListActive = async () => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_API}/slides/active`);

    return result;
  } catch (e) {}
};

export const createSlide = async (slide) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/slides/`,
      {
        ...slide,
      },
      {
        headers: { Authorization: 'Bearer ' + token },
      },
    );

    return result;
  } catch (e) {}
};

export const updateSlide = async (id, slide) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/slides/${id}`,
      {
        ...slide,
      },
      {
        headers: { Authorization: 'Bearer ' + token },
      },
    );

    return result;
  } catch (e) {}
};

export const deleteSlide = async (id) => {
  try {
    const result = await axios.delete(`${process.env.REACT_APP_API}/slides/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    });

    return result;
  } catch (e) {}
};
