import React, { Component } from 'react';
import MainStructure from 'components/structure/MainStructure';
import Cover from 'components/ui/Cover';
import Email from 'components/Email';
import { getStaticByType } from 'api/static';
import { getPageByType } from 'api/page';

class EmailContainer extends Component {
  state = {
    pc_content: '',
    mo_content: '',
    en_pc_content: '',
    en_mo_content: '',
    page: {
      title: '',
      en_title: '',
      image: '',
    },
  };

  componentDidMount() {
    this.handleStaic();
    this.handlePage();
  }

  handlePage = async () => {
    const result = await getPageByType('email');
    if (result && result.status === 200) {
      this.setState({
        ...this.state,
        page: result.data,
      });
    }
  };

  handleStaic = async () => {
    const result = await getStaticByType('email');
    if (result && result.status === 200) {
      if (result.data.length > 0) {
        this.setState({
          ...this.state,
          pc_content: result.data[0].pc_content,
          mo_content: result.data[0].mo_content,
          en_pc_content: result.data[0].en_pc_content,
          en_mo_content: result.data[0].en_mo_content,
        });
      }
    }
  };

  render() {
    const { pc_content, mo_content, en_pc_content, en_mo_content, page } = this.state;
    return (
      <MainStructure>
        <Cover
          title={(page && page.title) || 'EMAIL'}
          en_title={(page && page.en_title) || 'EMAIL'}
          background={
            page.image ? `${process.env.REACT_APP_S3}${page.image}` : '/images/buiness-bg.png'
          }
        />
        <Email
          pc_content={pc_content}
          mo_content={mo_content}
          en_pc_content={en_pc_content}
          en_mo_content={en_mo_content}
        />
      </MainStructure>
    );
  }
}

export default EmailContainer;
