import React, { Component } from 'react';
import InquiryContainer from 'containers/InquiryContainer';

class Inquiry extends Component {
  render() {
    return <InquiryContainer />;
  }
}

export default Inquiry;
