import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import check from 'lib/check';

const AuthContainer = () => (WrappedComponent) => {
  return class extends Component {
    componentDidMount() {
      check.token();
      document.getElementById('kakaoChat').style.display = 'none';
    }

    render() {
      return <WrappedComponent />;
    }
  };
};

export default AuthContainer;
