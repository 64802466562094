import axios from 'axios';
import storage from 'lib/storage';

const token = storage.get('token');

export const getStaticList = async () => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_API}/statics/`);

    return result;
  } catch (e) {}
};

export const getStaticByType = async (type) => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_API}/statics/type/${type}`);

    return result;
  } catch (e) {}
};

export const createStatic = async (s) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/statics`,
      {
        ...s,
      },
      {
        headers: { Authorization: 'Bearer ' + token },
      },
    );

    return result;
  } catch (e) {}
};

export const updateStatic = async (id, s) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/statics/${id}`,
      {
        ...s,
      },
      {
        headers: { Authorization: 'Bearer ' + token },
      },
    );

    return result;
  } catch (e) {}
};
