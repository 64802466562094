import React, { Component } from 'react';
import { setCookie, getCookie } from 'lib/common';
import { connect } from 'react-redux';
import { setLang } from 'data/actions';
import './Popup.css';

class Popup extends Component {
  state = {
    checked: false,
    isOpen: false,
  };

  componentDidMount() {
    this.checkPopup(this.props.data.id);
  }

  componentWillReceiveProps(prev, next) {
    if (prev?.data?.id !== next?.data?.id) {
      this.checkPopup(next?.data?.id);
    }
  }

  checkPopup = (id) => {
    if (!id) return;
    let popupValue = getCookie('popup-0' + id);

    if (popupValue) {
    } else {
      this.setState({
        ...this.state,
        isOpen: true,
      });
    }
  };

  handleChecked = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      checked: !this.state.checked,
    });
  };

  closePopup = (e) => {
    const { data } = this.props;
    const { checked, isOpen } = this.state;

    if (checked) {
      let popup = 'popup-0' + data.id;
      setCookie(popup, data, 1);
    }

    if (e.target.className.indexOf('close') > -1 || e.target.className.indexOf('popup-bg') > -1) {
      this.setState({
        ...this.state,
        isOpen: false,
      });
    }
  };

  render() {
    const { isOpen, checked } = this.state;
    const { data, lang } = this.props;
    const { handleChecked, closePopup } = this;

    return (
      <div className={'popup-bg ' + (isOpen ? 'open' : '')} onClick={closePopup}>
        <div className="popup">
          <div>
            <img
              className="pc-only"
              src={process.env.REACT_APP_S3 + (lang === 'ko' ? data.pc_image : data.en_pc_image)}
              alt=""
            />
            <img
              className="mo-only"
              src={process.env.REACT_APP_S3 + (lang === 'ko' ? data.mo_image : data.en_mo_image)}
              alt=""
            />
          </div>
          <div className="popup-bottom">
            <label className="inp-check" onClick={handleChecked}>
              <input name="checkbox" type="checkbox" />
              <span className={'check_box ' + (checked ? ' active' : '')}></span>
              <p className="check-txt">
                {lang === 'ko' ? '1일간 열지않음' : 'Do not open for 1 day'}
              </p>
            </label>
            <span className="close" onClick={closePopup}>
              {lang === 'ko' ? '닫기' : 'Close'}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.config.lang,
  }),
  { setLang },
)(Popup);
