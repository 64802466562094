import React, { Component } from 'react';
import MainStructure from 'components/structure/MainStructure';
import Main from 'components/Main';
import SliderList from 'components/ui/SliderList';
import Popup from 'components/ui/Popup';
import { getContactList } from 'api/contact';
import { getSlideListActive } from 'api/slide';
import { getPopupActive } from 'api/popup';

class MainContainer extends Component {
  state = {
    contact: {
      company: 'KCMC',
      address: '서울 영등포구 국제금융로8길 27-9 동북빌딩 601호(여의도동, 동북빌딩)',
      en_company: 'KCMC',
      en_address:
        '601, Dongbuk Building, 27-9, Gukjegeumyung-ro 8-gil, Yeongdeungpo-gu, Seoul, Republic of Korea',
      contact_number: '82-2-784-0794~5',
      email: 'KCKIMMC@KCMONOMER.COM',
      map:
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.503919366966!2d126.92676501575197!3d37.51961687980665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f1589fd3069%3A0x418450b2feac4a9d!2z64-Z67aB67mM65SpIOq0gOumrOyCrOustOyGjA!5e0!3m2!1sko!2skr!4v1613655414918!5m2!1sko!2skr',
    },
    slides: [],
    popups: [],
  };

  componentDidMount() {
    this.handleSlideList();
    this.handleContact();
    this.handlePopupList();
  }

  handleContact = async () => {
    const result = await getContactList();
    if (result && result.status === 200) {
      if (result.data && result.data[0]) {
        this.setState({
          ...this.state,
          contact: {
            ...this.state.contact,
            company: result.data[0].company,
            address: result.data[0].address,
            en_company: result.data[0].en_company,
            en_address: result.data[0].en_address,
            contact_number: result.data[0].contact_number,
            email: result.data[0].email,
            map: result.data[0].map,
          },
        });
      }
    }
  };

  handlePopupList = async () => {
    const result = await getPopupActive();
    if (result && result.status === 200) {
      if (result.data) {
        this.setState({
          ...this.state,
          popups: result.data,
        });
      }
    }
  };

  handleSlideList = async () => {
    const result = await getSlideListActive();
    if (result && result.status === 200) {
      if (result.data) {
        this.setState({
          ...this.state,
          slides: result.data,
        });
      }
    }
  };

  render() {
    const { contact, slides, popups } = this.state;

    return (
      <MainStructure page={'main'}>
        <SliderList slides={slides} />
        <Main contact={contact} />
        {popups && popups?.map((el) => <Popup key={el.id} data={el} />)}
      </MainStructure>
    );
  }
}

export default MainContainer;
